import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { changePartnershipPosition } from "../../../clients/services/sams/content/partnership";
import { PartnershipListResponse } from "../../../clients/services/sams/content/partnership/types";
import { setNotification } from "../../../state/reducers/notification";

interface MutationContext {
  previousPartnerships: PartnershipListResponse[];
}

export function useChangePartnershipPosition() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(changePartnershipPosition, {
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries("listPartnerships");

      // Capture the current state before the mutation
      const previousPartnerships =
        queryClient.getQueryData<PartnershipListResponse[]>("listPartnerships");
      if (!previousPartnerships) return { previousPartnerships: [] };

      // Create a map for quick lookup
      const positionMap = new Map(
        newOrder.map((item) => [item.id, item.positionNumber])
      );

      // Optimistically update to the new value by adjusting positionNumbers
      const optimisticPartnerships = previousPartnerships.map(
        (partnership) => ({
          ...partnership,
          positionNumber:
            positionMap.get(partnership.id) ?? partnership.positionNumber
        })
      );

      queryClient.setQueryData("listPartnerships", optimisticPartnerships);

      return { previousPartnerships };
    },
    onSuccess: () => {
      dispatch(
        setNotification({
          message: `Partnerships order has been changed successfully!`,
          severity: "success"
        })
      );
      queryClient.invalidateQueries("listPartnerships");
    },
    onError: (error: AxiosError, _, context: unknown) => {
      const typedContext = context as MutationContext;
      // Rollback to the previous value
      queryClient.setQueryData(
        "listPartnerships",
        typedContext.previousPartnerships
      );
      dispatch(
        setNotification({
          message: `${error.response?.data.errorMessage}`,
          severity: "error"
        })
      );
    }
  });
}
