import { Chip } from "@mui/material";
import {
  GridColDef,
  GridComparatorFn,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridValueGetterParams
} from "@mui/x-data-grid";

import { deletePromotion } from "../../clients/services/sams/promotion";
import {
  OfferStatus,
  Promotion
} from "../../clients/services/sams/promotion/types";
import { SunProduct } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import { useGetLatestHistory, useGetPromotions } from "../Offer/hooks";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

interface OfferStatusCellValue {
  offerStatus: string;
  status: string;
}

const statusOfferSortComparator: GridComparatorFn = (
  baseStatusValue,
  compareToStatusValue,
  baseStatusValueParam,
  compareToStatusValueParam
) =>
  gridStringOrNumberComparator(
    (baseStatusValue as OfferStatusCellValue).offerStatus,
    (compareToStatusValue as OfferStatusCellValue).offerStatus,
    baseStatusValueParam,
    compareToStatusValueParam
  ) ||
  gridStringOrNumberComparator(
    (compareToStatusValue as OfferStatusCellValue).status,
    (baseStatusValue as OfferStatusCellValue).status,
    compareToStatusValueParam,
    baseStatusValueParam
  );

const getSunProductChip = (params: GridRenderCellParams<any, SunProduct>) => {
  switch (params.value) {
    case SunProduct.SUN_SAVERS:
      return (
        <Chip
          color="secondary"
          label="Savers"
          variant="filled"
          data-testid="sunProductTestIDSunSavers"
        />
      );
    case SunProduct.SUN_MOBILE:
      return (
        <Chip
          color="primary"
          label="Mobile"
          variant="filled"
          data-testid="sunProductTestIDSunMobile"
        />
      );
    case SunProduct.MEMBERS_ENCLOSURE:
      return (
        <Chip
          color="info"
          label="Membership"
          variant="filled"
          data-testid="sunProductTestIDMembershipHub"
        />
      );
    case SunProduct.SUN_CLUB:
      return (
        <Chip
          label="Sun Club"
          variant="filled"
          data-testid="sunProductTestIDMembershipHub"
          style={{ backgroundColor: "#F44336", color: "#fff" }}
        />
      );
    default:
      return <></>;
  }
};

const OfferTable = () => {
  const {
    isLoadingPromotions,
    promotionsData,
    promotionsError,
    refetchPromotions
  } = useGetPromotions();
  const { isLoadingHistory, historyData } = useGetLatestHistory();

  const rows: Rows | undefined = promotionsData?.map((offer: Promotion) => {
    return {
      id: offer.code,
      showMarketingPermission: offer.showMarketingPermissions,
      name: offer.name,
      sunProduct: offer.sunProduct || "",
      promotionType: offer.promotionType,
      runningPeriod: offer.redemptionStartDateTime
        ? `${dateTimeFull(offer.redemptionStartDateTime)} - ${dateTimeFull(
            offer.redemptionEndDateTime
          )}`
        : `Redemption End Date: ${dateTimeFull(offer.redemptionEndDateTime)}`,
      redemptionCount: offer.redemptionCount ?? "N/A",
      offerStatus: offer.status,
      user: findLatestHistory(historyData, offer.code)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, offer.code)
        ? dateTimeFull(findLatestHistory(historyData, offer.code)?.createdDate)
        : "Created from service",
      status: offer.publicationStatus
    };
  });

  const tableProperties = {
    itemName: "offer",
    actionLabel: "Offer"
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 170,
      maxWidth: 260,
      flex: 1
    },
    {
      field: "sunProduct",
      headerName: "Used By",
      minWidth: 90,
      maxWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SunProduct>) =>
        getSunProductChip(params)
    },
    {
      field: "promotionType",
      headerName: "Offer Type",
      minWidth: 130,
      maxWidth: 130,
      flex: 1
    },
    {
      field: "runningPeriod",
      headerName: "Running Period",
      minWidth: 340,
      maxWidth: 340,
      flex: 1
    },
    {
      field: "user",
      headerName: "Author",
      minWidth: 90,
      maxWidth: 260,
      flex: 1
    },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      maxWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    },
    {
      field: "offerStatus",
      headerName: "Offer Status",
      valueGetter: (params: GridValueGetterParams) => ({
        offerStatus: params.row.offerStatus,
        status: params.row.status
      }),
      sortComparator: statusOfferSortComparator,
      minWidth: 130,
      maxWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        switch (params.value.offerStatus) {
          case OfferStatus.FUTURE:
            return <Chip color="secondary" label="Future" variant="outlined" />;
          case OfferStatus.ACTIVE:
            return <Chip color="success" label="Active" variant="outlined" />;
          case OfferStatus.EXPIRED:
            return <Chip color="error" label="Expired" variant="outlined" />;
          default:
            return "";
        }
      }
    },
    {
      field: "redemptionCount",
      headerName: "No. Redemptions",
      maxWidth: 90,
      align: "center",
      flex: 1
    }
  ];

  return (
    <TableComponent<Promotion>
      isLoading={isLoadingPromotions || isLoadingHistory}
      error={promotionsError}
      refetch={refetchPromotions}
      rows={rows as Rows}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "offerStatus",
              sort: "asc"
            }
          ]
        }
      }}
      testId="OfferTableTestID"
      createButton={{
        link: "/create-offer",
        text: "Create Offer"
      }}
      tableProperties={tableProperties}
      deleteFunction={deletePromotion}
    />
  );
};

export default OfferTable;
