import styled from "@emotion/styled";
import { MenuItem, TextField, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";

import { ArticleListResponse } from "../../../clients/services/sams/content/article/types";
import { PartnershipListResponse } from "../../../clients/services/sams/content/partnership/types";
import { Promotion } from "../../../clients/services/sams/promotion/types";
import { Status } from "../../../clients/services/sams/types";
import { getCardField } from "../../../constants/slotsGetCardField";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import SelectField from "../../SelectField";
import StatusSelector from "../../StatusSelector";
import { mapSlot, ObjectType, Slot } from "../types";

const CreateSlotContainer = styled.div`
  padding-top: 15px;
`;
const DatesInputFlexer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DateInput = styled(TextField)`
  margin-top: 24px;
  width: 47%;
`;
const CustomFormControl = styled(SelectField)`
  margin-top: 24px;
  width: 100%;
`;

interface FormInterface {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
  promotionData?: Promotion[];
  articleData?: ArticleListResponse[];
  partnershipData?: PartnershipListResponse[];
}

const Form: FC<FormInterface> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  promotionData,
  articleData,
  partnershipData,
  isSubmitting
}) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <CreateSlotContainer data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        <Grid container item xs={8}>
          <form
            id={formId}
            onSubmit={formik.handleSubmit}
            onBlur={formik.handleBlur}
          >
            {formik.submitCount !== 0 && (
              <ErrorMessage formValidationErrors={formik.errors} />
            )}
            <CustomFormControl
              form={formik}
              label={"Card"}
              labelID={"labelCardId"}
              name={"objectId"}
              defaultValue={getCardField(
                formik.values.objectId,
                formik.values.objectType,
                promotionData,
                partnershipData,
                articleData
              )}
            >
              <MenuItem disabled>Promotions</MenuItem>
              {promotionData?.map((promotion) =>
                promotion.publicationStatus === Status.PUBLISHED ? (
                  <MenuItem
                    onClick={() =>
                      formik.setFieldValue("objectType", ObjectType.OFFER)
                    }
                    value={promotion.code}
                    key={`${promotion.code}-${promotion.name}`}
                  >
                    {promotion.name}
                  </MenuItem>
                ) : null
              )}
              <MenuItem disabled>Articles</MenuItem>
              {articleData?.map((article) =>
                article.status === Status.PUBLISHED ? (
                  <MenuItem
                    onClick={() =>
                      formik.setFieldValue("objectType", ObjectType.ARTICLE)
                    }
                    value={article.id}
                    key={`${article.id}-${article.headline}`}
                  >
                    {article.headline}
                  </MenuItem>
                ) : null
              )}
              <MenuItem disabled>Partnerships</MenuItem>
              {partnershipData?.map((partnership) =>
                partnership.status === Status.PUBLISHED ? (
                  <MenuItem
                    value={partnership.id}
                    onClick={() =>
                      formik.setFieldValue("objectType", ObjectType.PARTNERSHIP)
                    }
                    key={`${partnership.id}-${partnership.headline}`}
                  >
                    {partnership.headline}
                  </MenuItem>
                ) : null
              )}
            </CustomFormControl>
            <SelectField
              form={formik}
              label={"Slot"}
              labelID={"labelSlotID"}
              defaultValue={formik.values.slot}
              name={"slot"}
            >
              {Object.values(Slot)
                // filter only the values as Object.values returns also keys
                .filter((s) => typeof s === "number")
                .map((slot) => (
                  <MenuItem
                    key={slot}
                    value={slot}
                    data-testid={`slotValue${slot}TestID`}
                  >
                    {mapSlot.get(slot as Slot)}
                  </MenuItem>
                ))}
            </SelectField>
            <DatesInputFlexer>
              <DateInput
                id="startDate"
                name="startDate"
                label="Start Date"
                variant="standard"
                type="datetime-local"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <DateInput
                id="endDate"
                name="endDate"
                label="End Date"
                variant="standard"
                type="datetime-local"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </DatesInputFlexer>
          </form>
        </Grid>
        <Grid container item xs={4}>
          <StatusSelector
            isSubmitting={isSubmitting}
            form={formik}
            actionLabel={actionLabel}
            testId={actionTestId}
          />
          {values.id ? (
            <HistoryTable postType={"slot"} postId={values.id} />
          ) : null}
        </Grid>
      </Grid>
    </CreateSlotContainer>
  );
};

export default Form;
