import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import {
  ChooseImageRequest,
  DeleteImageRequest,
  UploadImageRequest,
  ImageResponse,
  ImagesPrefix
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const listImages = async (prefix: ImagesPrefix) => {
  const url = `${baseUrl}/gallery/images/${prefix}/`;

  const response: AxiosResponse<string[]> = await client.get(url);
  return response.data;
};

export const chooseImage = async (
  data: ChooseImageRequest
): Promise<ImageResponse> => {
  const url = `${baseUrl}/gallery/choose-image`;

  const response: AxiosResponse<ImageResponse> = await client.post(url, data);
  return response.data;
};

export const uploadImage = async (
  data: UploadImageRequest
): Promise<ImageResponse> => {
  const url = `${baseUrl}/gallery/upload-image`;

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response: AxiosResponse<ImageResponse> = await client.post(
    url,
    formData,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return response.data;
};

export const deleteImage = async (data: DeleteImageRequest) => {
  const url = `${baseUrl}/gallery/delete-image`;

  const response: AxiosResponse<any[]> = await client.post(url, data);
  return response.data;
};
