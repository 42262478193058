import { Article } from "../../clients/services/sams/content/article/types";
import {
  ArticleType,
  Status,
  SunProduct
} from "../../clients/services/sams/types";

const formDataTemplate: Article = {
  image: "",
  sunProduct: SunProduct.SUN_SAVERS,
  shortHeadline: "",
  headline: "",
  sell: "",
  slug: "",
  badgeText: "",
  status: Status.DRAFT,
  publishStartDate: "",
  publishEndDate: "",
  description: "",
  tandcs: "",
  positionNumber: 0,
  articleType: ArticleType.STANDARD,
  favouritesCta: null,
  videoId: ""
};

export default formDataTemplate;
