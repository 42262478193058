import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import { HistoryResponse } from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const getLatestHistory = async (postType: string) => {
  const url = `${baseUrl}/content/history/${postType}`;

  const response: AxiosResponse<HistoryResponse[]> = await client.get(url);
  return response.data;
};

export const getHistoryForPost = async (postType: string, postId: string) => {
  const url = `${baseUrl}/content/history/${postType}/${postId}`;

  const response: AxiosResponse<HistoryResponse[]> = await client.get(url);
  return response.data;
};
