import styled from "@emotion/styled";
import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { FormikValues } from "formik";
import { FC } from "react";

const StyledFormControl = styled(FormControl)`
  margin: 8px 0;
  width: 100%;
`;

interface SelectFieldProps {
  form: FormikValues;
  label: string;
  labelID: string;
  name: string;
  defaultValue?: any;
  children?: any;
  customValueHandler?: any;
}

const SelectField: FC<SelectFieldProps> = ({
  form,
  label,
  labelID,
  name,
  defaultValue,
  children,
  customValueHandler
}) => (
  <StyledFormControl>
    <InputLabel id={labelID} variant="standard">
      {label}
    </InputLabel>
    <Select
      labelId={labelID}
      id={name}
      name={name}
      value={form.values[name]}
      variant="standard"
      onChange={
        customValueHandler
          ? (e) => {
              form.handleChange(e);
              customValueHandler(e.target.value);
            }
          : form.handleChange
      }
      defaultValue={defaultValue}
      error={form.touched[name] && Boolean(form.errors[name])}
    >
      {children}
    </Select>
    {Boolean(form.touched[name]) && Boolean(form.errors[name]) ? (
      <FormHelperText id={`${name}Error`} style={{ marginLeft: 0 }} error>
        {form.errors[name]}
      </FormHelperText>
    ) : null}
  </StyledFormControl>
);

export default SelectField;
