import { Banner } from "../../clients/services/sams/content/banner/types";
import { Status, SunProduct } from "../../clients/services/sams/types";

const formDataTemplate: Banner = {
  title: "",
  text: "",
  readMoreLabel: "",
  dismissLabel: "",
  readMoreUrl: "",
  startDate: "",
  endDate: "",
  page: "",
  status: Status.DRAFT,
  sunProduct: SunProduct.SUN_SAVERS
};

export default formDataTemplate;
