import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { changePromotionPosition } from "../../../clients/services/sams/promotion";
import { Promotion } from "../../../clients/services/sams/promotion/types";
import { setNotification } from "../../../state/reducers/notification";

interface MutationContext {
  previousPromotions: Promotion[];
}

export function useChangePromotionPosition() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(changePromotionPosition, {
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries("getPromotions");

      // Capture the current state before the mutation
      const previousPromotions =
        queryClient.getQueryData<Promotion[]>("getPromotions");
      if (!previousPromotions) return { previousPromotions: [] };

      // Create a map for quick lookup
      const positionMap = new Map(
        newOrder.map((item) => [item.code, item.positionNumber])
      );

      // Optimistically update to the new value by adjusting positionNumbers
      const optimisticPromotions = previousPromotions.map((promotion) => ({
        ...promotion,
        positionNumber:
          positionMap.get(promotion.code) ?? promotion.positionNumber
      }));

      queryClient.setQueryData("getPromotions", optimisticPromotions);

      return { previousPromotions };
    },
    onSuccess: () => {
      dispatch(
        setNotification({
          message: `Promotions order has been changed successfully!`,
          severity: "success"
        })
      );
      queryClient.invalidateQueries("getPromotions");
    },
    onError: (error: AxiosError, _, context: unknown) => {
      const typedContext = context as MutationContext;
      // Rollback to the previous value
      queryClient.setQueryData(
        "getPromotions",
        typedContext.previousPromotions
      );
      dispatch(
        setNotification({
          message: `${error.response?.data.errorMessage}`,
          severity: "error"
        })
      );
    }
  });
}
