import { Status, SunProduct } from "../../clients/services/sams/types";

import { FormDataInterface } from "./types";

const formDataTemplate: FormDataInterface = {
  headline: "",
  partnershipText: "",
  badgeText: "",
  image: "",
  url: "",
  liveDate: "",
  endDate: "",
  status: Status.DRAFT,
  sunProduct: SunProduct.SUN_SAVERS
};

export default formDataTemplate;
