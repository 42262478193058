import styled from "@emotion/styled";
import { TextField, Grid, Typography, Tabs, Tab } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";

import { ImageResponse } from "../../../clients/services/sams/gallery/types";
import generateSlugField from "../../../constants/generateSlugField";
import { imageResizeMessages } from "../../../constants/imageResizeMessages";
import { ErrorMessage } from "../../ErrorMessage";
import ImageSelection from "../../ImageSelection";
import RichContentEditor from "../../RichContentEditor";
import StatusSelector from "../../StatusSelector";
import TabPanel from "../../TabPanel";

const CreateMorePageContainer = styled.div`
  padding-top: 15px;
`;
const CustomInputField = styled(TextField)`
  margin-top: 24px;
`;

interface FormInterface {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const MorePageForm = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting
}: FormInterface) => {
  const [value, setValue] = useState(0);
  const handleTabChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <CreateMorePageContainer data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        <Grid container item xs={8}>
          <form id={formId} onSubmit={formik.handleSubmit}>
            {formik.submitCount !== 0 && (
              <ErrorMessage formValidationErrors={formik.errors} />
            )}
            <ImageSelection
              id="image"
              value={formik.values.image}
              imageResizeMessage={imageResizeMessages.morePageImage}
              error={formik.touched.image && Boolean(formik.errors.image)}
              helperText={formik.touched.image && formik.errors.image}
              btnText={
                formik.values.image.length !== 0
                  ? "Select another image"
                  : "Select an image"
              }
              setFieldValue={formik.setFieldValue}
              setImageFieldsValues={(data: ImageResponse) => {
                if ("image" in data) {
                  formik.setFieldValue("image", data.image);
                }
              }}
              isImageSelectionDisabled={!formik.values.headline}
              isImageSelected={formik.values.image.length !== 0}
              postType="morePage"
              imageToUpload={formik.values.image}
              postHeadline={formik.values.headline}
            />
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="More Page Sections"
            >
              <Tab label="Info" data-testid="infoTab" />
              <Tab label="Copy" data-testid="copyTab" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <CustomInputField
                fullWidth
                id="headline"
                name="headline"
                label="Headline"
                variant="standard"
                value={formik.values.headline}
                onChange={(e) => {
                  formik.setFieldValue("headline", e.target.value);
                  generateSlugField(e.target.value, formik);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.headline && Boolean(formik.errors.headline)
                }
                helperText={formik.touched.headline && formik.errors.headline}
              />
              <CustomInputField
                fullWidth
                id="shortDescription"
                name="shortDescription"
                label="Short Description"
                variant="standard"
                value={formik.values.shortDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.shortDescription &&
                  Boolean(formik.errors.shortDescription)
                }
                helperText={
                  formik.touched.shortDescription &&
                  formik.errors.shortDescription
                }
              />
              <CustomInputField
                fullWidth
                id="pageTitle"
                name="pageTitle"
                label="Page Title"
                variant="standard"
                value={formik.values.pageTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.pageTitle && Boolean(formik.errors.pageTitle)
                }
                helperText={formik.touched.pageTitle && formik.errors.pageTitle}
              />
              <CustomInputField
                fullWidth
                id="slug"
                name="slug"
                label="Slug"
                variant="standard"
                value={formik.values.slug}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.slug && Boolean(formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RichContentEditor
                id="content"
                label="Content"
                value={formik.values.content}
                onChange={formik.setFieldValue}
                error={formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
              />
            </TabPanel>
          </form>
        </Grid>
        <Grid container item xs={4}>
          <StatusSelector
            form={formik}
            actionLabel={actionLabel}
            testId={actionTestId}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </Grid>
    </CreateMorePageContainer>
  );
};

export default MorePageForm;
