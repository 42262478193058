import * as yup from "yup";

import { ProductType } from "../../clients/services/sams/merlin/types";
import { Status } from "../../clients/services/sams/types";

const validationSchema = yup.object({
  xcode: yup.string().required("Offer is required"),
  merlinProductCode: yup.string().required("Merlin Product Code is required"),
  productType: yup
    .mixed()
    .oneOf(Object.values(ProductType))
    .required("Product Type is required"),
  title: yup.string().required("Headline is required"),
  shortTitle: yup.string().when("productType", {
    is: (value: ProductType) => ProductType.UPSELL === value,
    then: (schema) => schema.required("Short headline is required")
  }),
  sell: yup.string().when("productType", {
    is: (value: ProductType) => ProductType.UPSELL === value,
    then: (schema) => schema.required("Sell text is required")
  }),
  slug: yup.string().required("Slug is required"),
  label: yup.string(),
  images: yup.object({
    image: yup.object({
      original: yup.string().required("Image is required")
    }),
    thumbnail_image: yup.object({
      original: yup.string().when("productType", {
        is: (value: ProductType) => ProductType.UPSELL === value,
        then: (schema) => schema.required("Thumbnail image is required")
      })
    })
  }),
  description: yup.string().when("productType", {
    is: (value: ProductType) => ProductType.UPSELL === value,
    then: (schema) => schema.required("Description is required")
  }),
  termsAndConditions: yup.string().when("productType", {
    is: (value: ProductType) => ProductType.UPSELL === value,
    then: (schema) => schema.required("Terms and Conditions are required")
  }),
  extraInformation: yup.string().required("How to Use This Offer is required"),
  bookingFee: yup.number(),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Status is required")
});

export default validationSchema;
