import styled from "@emotion/styled";
import { CircularProgress, MenuItem } from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";

import { getAttractions } from "../../clients/services/sams/merlin";
import { ErrorMessage } from "../ErrorMessage";
import SelectField from "../SelectField";

import ProductsTable from "./ProductsTable";

const formValues = {
  values: {},
  errors: {},
  touched: {}
};

const ProductsContainer = styled.div`
  max-width: 80%;
`;

const ProductFetch = () => {
  const { isLoading, data, error } = useQuery(["getAttractions"], () =>
    getAttractions()
  );
  const [merlinAttractionId, setMerlinAttractionId] = useState("");

  if (isLoading) {
    return <CircularProgress data-testid={"loading"} />;
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  return (
    <ProductsContainer data-testid={"ProductFetchTestID"}>
      <SelectField
        form={formValues}
        label={"Attractions"}
        labelID={"attractionId"}
        name={"attractions"}
        defaultValue={"default"}
      >
        <MenuItem value={"default"} disabled selected>
          Choose Attraction
        </MenuItem>
        {data?.reverse().map((el) => (
          <MenuItem
            key={el.id}
            onClick={() => setMerlinAttractionId(el.merlinAttractionId)}
            value={el.merlinAttractionId}
          >
            {el.description}
          </MenuItem>
        ))}
      </SelectField>
      {merlinAttractionId !== "" ? (
        <ProductsTable merlinAttractionId={merlinAttractionId} />
      ) : null}
    </ProductsContainer>
  );
};

export default ProductFetch;
