export const imageResizeMessages = {
  article:
    "This image will be automatically resized as follows: Main Image: 884 x 589 / Thumbnail Image: 430 x 286",
  partnership: "This image will be automatically resized as follows: 430 x 286",
  offer:
    "This image will be automatically resized as follows: Main Image: 884 x 589 / List Image: 430 x 286",
  product: {
    mainImage:
      "This image will be automatically resized as follows: Main Image: 300 x 200 / List Image: 436 x 245",
    thumbnailImage: "This image will be automatically resized to 102 x 102"
  },
  raffleWinner: {
    mainImage: "This image will be automatically resized to 300 x 200",
    thumbnailImage: "This image will be automatically resized to 102 x 102"
  },
  attractionImage: "This image was uploaded from AWS S3 Console.",
  morePageImage:
    "This image will be automatically resized as follows: 620 x 275"
};
