import styled from "@emotion/styled";
import { FormGroup, FormLabel } from "@mui/material";
import { FormikErrors } from "formik";
import { FC } from "react";
import ReactQuill from "react-quill";

import InfoTooltip from "./InfoTooltip";

interface StyledFormGroupProps {
  disabled?: boolean;
}

const StyledFormGroup = styled(FormGroup)<StyledFormGroupProps>`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  color: ${(props) => props.disabled && "#808080"};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Editor = styled(ReactQuill)`
  height: 320px;
  margin-top: 8px;
  margin-bottom: 32px;
`;

const ErrorMessage = styled.p`
  color: #d32f2f;
`;

const HeadingWithIcon = styled.div`
  display: flex;
`;

const InfoTooltipPosition = styled(InfoTooltip)`
  margin-left: 5px;
`;

interface ContentEditorProps {
  id: string;
  label: string;
  value: string;
  toolTipMessage?: string;
  onChange: (name: string, value: string) => void;
  error?: boolean;
  helperText?:
    | string
    | boolean
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[];
  disabled?: boolean;
}

const ContentEditor: FC<ContentEditorProps> = ({
  id,
  label,
  toolTipMessage = null,
  value,
  onChange,
  error,
  helperText,
  disabled
}) => {
  const colorState = error ? "error" : "primary";
  return (
    <StyledFormGroup disabled={disabled}>
      <HeadingWithIcon>
        <FormLabel
          color={colorState}
          error={error}
          component="legend"
          data-testid={`${id}-label`}
        >
          {label}
        </FormLabel>
        {toolTipMessage && <InfoTooltipPosition message={toolTipMessage} />}
      </HeadingWithIcon>
      <Editor
        id={id}
        theme="snow"
        value={value}
        onChange={(content) => onChange(id, content)}
        data-testid={`content-editor-${id}`}
        readOnly={disabled}
        modules={{
          clipboard: {
            matchVisual: false
          }
        }}
      />
      {error && (
        <ErrorMessage data-testid={`${id}-error`}>{helperText}</ErrorMessage>
      )}
    </StyledFormGroup>
  );
};

export default ContentEditor;
