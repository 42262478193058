import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";

import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import {
  deleteRaffleWinner,
  listRaffleWinners
} from "../../clients/services/sams/content/raffleWinners";
import { RaffleWinnerListResponse } from "../../clients/services/sams/content/raffleWinners/types";
import { PostType } from "../../clients/services/sams/types";
import { dateMonthYear, dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

const RaffleWinnersTable = () => {
  const { isLoading, data, error, refetch } = useQuery(["listWinners"], () =>
    listRaffleWinners()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.RAFFLE_WINNER)
  );

  const rows: Rows | undefined = data?.map(
    (winner: RaffleWinnerListResponse) => ({
      id: winner.id,
      raffleDate: dateMonthYear(winner.raffleDate),
      winnerName: winner.title,
      status: winner.status,
      user: findLatestHistory(historyData, winner.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, winner.id)
        ? dateTimeFull(findLatestHistory(historyData, winner.id)?.createdDate)
        : "Created from service"
    })
  );

  const tableProperties = {
    itemName: "Raffle Winner",
    actionLabel: "Raffle Winner",
    specialUrl: "winner"
  };

  const columns: GridColDef[] = [
    { field: "raffleDate", headerName: "Raffle Date", minWidth: 90, flex: 1 },
    { field: "winnerName", headerName: "Winner Name", minWidth: 170, flex: 1 },
    { field: "user", headerName: "Author", minWidth: 130, flex: 1 },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    }
  ];

  return (
    <TableComponent<RaffleWinnerListResponse>
      isLoading={isLoading || isLoadingHistory}
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="RaffleWinnersTestID"
      createButton={{
        link: "/add-winner",
        text: "Add Winner"
      }}
      tableProperties={tableProperties}
      deleteFunction={deleteRaffleWinner}
    />
  );
};

export default RaffleWinnersTable;
