import { ArticleListResponse } from "../clients/services/sams/content/article/types";
import { PartnershipListResponse } from "../clients/services/sams/content/partnership/types";
import { Promotion } from "../clients/services/sams/promotion/types";

export const getCardField = (
  objectId?: string,
  objectType?: string,
  offerData?: Promotion[],
  partnershipData?: PartnershipListResponse[],
  articleData?: ArticleListResponse[]
) => {
  if (objectType === "article") {
    return articleData?.find(
      (article: ArticleListResponse) => article.id.toString() === objectId
    )?.headline;
  }
  if (objectType === "partnership") {
    return partnershipData?.find(
      (partnership: PartnershipListResponse) =>
        partnership.id.toString() === objectId
    )?.headline;
  }
  return offerData?.find((offer: Promotion) => offer.code === objectId)?.name;
};
