import moment from "moment";

import { NewVersion } from "../../clients/services/sams/content/newVersion/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: NewVersion) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (formData: NewVersion) => {
    return formData;
  };

  public modelToFormData = (modelData: NewVersion) => {
    return {
      ...modelData,
      releaseDate: modelData.releaseDate
        ? moment(modelData.releaseDate).format("YYYY-MM-DD")
        : ""
    };
  };
}

export default Transformer;
