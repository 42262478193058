import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import {
  GetStretchRedemptionUrl,
  StretchRedemptionUrl,
  TestStretchRedemptionUrlProps
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const getStretchRedemptionUrl: GetStretchRedemptionUrl = async ({
  cpn,
  redemptionUrl,
  xcode
}: TestStretchRedemptionUrlProps) => {
  const encodedCPN = encodeURIComponent(cpn);
  const encodedRedemptionUrl = encodeURIComponent(redemptionUrl);
  const encodedXCODE = encodeURIComponent(xcode);

  const url = `${baseUrl}/stretch/getRedemptionUrl?redemptionUrl=${encodedRedemptionUrl}&cpn=${encodedCPN}&xcode=${encodedXCODE}`;

  const response: AxiosResponse<StretchRedemptionUrl> = await client.get(url);
  return response.data;
};
