import { CircularProgress } from "@mui/material";
import { AxiosError } from "axios";

import { ErrorMessage } from "../ErrorMessage";

import OrderingTable from "./OrderingTable";
import {
  useGetArticles,
  useGetPromotions,
  useListProducts,
  useGetPartnerships
} from "./hooks";

const OfferPageOrdering = () => {
  const { isLoadingProducts, productsData, productsError } = useListProducts();
  const { isLoadingPromotion, promotionData, promotionError } =
    useGetPromotions();
  const { isLoadingArticles, articlesData, articlesError } = useGetArticles();
  const { isLoadingPartnerships, partnershipsData, partnershipsError } =
    useGetPartnerships();
  const fetchedData = [
    ...promotionData,
    ...articlesData,
    ...partnershipsData
  ].sort((a, b) => (a.positionNumber > b.positionNumber ? 1 : -1));

  if (promotionError || articlesError || partnershipsError) {
    return <ErrorMessage axiosErrors={[promotionError as AxiosError]} />;
  }
  if (
    isLoadingPromotion ||
    isLoadingProducts ||
    isLoadingArticles ||
    isLoadingPartnerships
  ) {
    return <CircularProgress data-testid={"loading"} />;
  }
  return (
    <div data-testid="OfferPageOrdering">
      {productsError && (
        <ErrorMessage axiosErrors={[productsError as AxiosError]} />
      )}
      <OrderingTable fetchedData={fetchedData} products={productsData} />
    </div>
  );
};

export default OfferPageOrdering;
