import styled from "@emotion/styled";
import { Inbox, AddCircle } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { Link, NavLink } from "react-router-dom";

import { config } from "../../config";
import { useAppSelector } from "../../state/hooks";
import { SamsRoute } from "../../types";

const SideBarLink = styled(NavLink)`
  text-decoration: none;
`;

const CustomListItem = styled(ListItem)`
  color: #bdbdbd;
  &:hover {
    background-color: #123754;
  }
`;

const StyledAddCircle = styled(AddCircle)`
  color: #f5f5f5;
  &:hover {
    color: #bdbdbd;
  }
`;

const StyledSideBar = styled.div`
  min-height: calc(100vh - 64px);
  height: 100%;
  background-color: #0c2133;
  color: #f5f5f5;
  transition: all 0.3s linear;
  &:first-of-type {
    padding-top: 10px;
  }
  & .MuiListItem-root {
    min-width: 0;
    margin: 0 10px;
    max-width: calc(100% - 20px);
    border-radius: 5px;
    color: #fff;
    min-height: 48px;
  }
  & a.active .MuiListItem-root {
    background: #1976d1;
  }
  & .MuiSvgIcon-root {
    height: 0.8em;
  }
  & .MuiListItemText-root > .MuiTypography-root {
    font-size: 0.85rem;
    font-weight: 800;
  }
`;

const ListItemWithLink = ({
  href,
  text,
  icon,
  exact,
  addIconLink,
  testId
}: {
  href: string;
  text: string;
  icon: JSX.Element;
  exact: boolean;
  addIconLink?: string;
  testId: string;
}) => {
  return (
    <SideBarLink to={href} exact={exact} data-testid={testId}>
      <CustomListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {addIconLink && (
          <Link to={addIconLink}>
            <StyledAddCircle />
          </Link>
        )}
      </CustomListItem>
    </SideBarLink>
  );
};

interface SideBarProps {
  routes: SamsRoute[];
  open: boolean;
}

const defaultIcon = <Inbox style={{ color: "#fff" }} />;
const stylesForSidebar = (
  openBoolean: boolean,
  enabledForUse: boolean
): any => {
  const styles: any = {};
  if (openBoolean) {
    styles.width = "240px";
  } else {
    styles.width = "73px";
  }
  if (!enabledForUse) {
    styles.opacity = "0.5";
    styles.pointerEvents = "none";
  }
  return styles;
};

const SideBar = ({ routes, open }: SideBarProps) => {
  // TO DO: change enabledNavigation to enabled when mock mode is done
  const isMockMode = useAppSelector((state) => state.mock.enabledNavigation);
  const { authState } = useOktaAuth();
  const isEnabledForUse =
    config.environment === "dev" ||
    config.environment === "local" ||
    (authState && authState.isAuthenticated) ||
    isMockMode;
  return (
    <StyledSideBar style={stylesForSidebar(open, isEnabledForUse)}>
      {routes.map((route) => (
        <ListItemWithLink
          key={route.path}
          href={route.path}
          text={open ? route.name : ""}
          icon={route.icon || defaultIcon}
          addIconLink={open ? route.addIconLink : undefined}
          exact={route.exact || false}
          testId={`${route.name}SideBarLinkTestID`}
        />
      ))}
    </StyledSideBar>
  );
};

export default SideBar;
