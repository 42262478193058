import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import {
  ArticleResponse,
  Article,
  ArticleListResponse,
  ListArticlesParams,
  ArticlePositionsData
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createArticle = async (article: Article) => {
  const url = `${baseUrl}/content/articles`;

  const response: AxiosResponse<ArticleResponse> = await client.post(
    url,
    article
  );
  return response.data;
};

export const listArticles = async (params?: ListArticlesParams) => {
  const url = `${baseUrl}/content/articles`;

  const response: AxiosResponse<ArticleListResponse[]> = await client.get(url, {
    params
  });
  return response.data;
};

export const deleteArticle = async (id: string) => {
  const url = `${baseUrl}/content/articles/${id}`;

  await client.delete(url);
};

export const getArticle = async (id: string) => {
  const url = `${baseUrl}/content/articles/${id}`;

  const response: AxiosResponse<ArticleListResponse> = await client.get(url);
  return response.data;
};

export const updateArticle = async (article: ArticleListResponse) => {
  const url = `${baseUrl}/content/articles/${article.id}`;

  const response: AxiosResponse<ArticleListResponse> = await client.put(
    url,
    article
  );
  return response.data;
};

export const changeArticlePosition = async (
  articlePositionsData: ArticlePositionsData[]
) => {
  const url = `${baseUrl}/content/articles/update-position`;

  const response: AxiosResponse<ArticlePositionsData[]> = await client.patch(
    url,
    articlePositionsData
  );

  return response.data;
};
