import { AxiosError } from "axios";
import { useDispatch } from "react-redux";

import { setNotification } from "../../../state/reducers/notification";

type UseOfferMutationHandler = () => (responseError: AxiosError) => void;

export const useOfferMutationHandler: UseOfferMutationHandler = () => {
  const dispatch = useDispatch();

  const onError = (responseError: AxiosError) => {
    dispatch(
      setNotification({
        message: [400, 401, 500].find(
          (status) => status === responseError.response?.status
        )
          ? responseError.response!.data.errorMessage
          : responseError.message,
        severity: "error"
      })
    );
  };

  return onError;
};
