import moment from "moment";

import { RaffleWinner } from "../../clients/services/sams/content/raffleWinners/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: RaffleWinner) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (formData: RaffleWinner) => {
    return formData;
  };

  public modelToFormData = (modelData: RaffleWinner) => {
    return {
      ...modelData,
      raffleDate:
        modelData.raffleDate !== ""
          ? moment(modelData.raffleDate).format("YYYY-MM-DD")
          : ""
    };
  };
}

export default Transformer;
