import * as yup from "yup";

import { Status } from "../../clients/services/sams/types";
import { containsWhitespace } from "../Article/validationSchema";

export const validationSchema = yup.object({
  headline: yup.string().required("Headline is required"),
  shortDescription: yup.string().required("Short Description is required"),
  image: yup.string().required("Image is required"),
  pageTitle: yup.string().required("Page Title is required"),
  content: yup.string().required("Content is required"),
  slug: yup
    .string()
    .required("Slug is required")
    .test(
      "This field cannot contain white space",
      "This field cannot contain white space",
      (value) => !containsWhitespace(value as string)
    ),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Status is required")
});
