export enum Slot {
  SLOT_1 = 1,
  SLOT_2 = 2,
  SLOT_3 = 3
}

export const mapSlot = new Map<Slot, string>([
  [Slot.SLOT_1, "Slot 1"],
  [Slot.SLOT_2, "Slot 2"],
  [Slot.SLOT_3, "Slot 3"]
]);

export enum ObjectType {
  OFFER = "OFFER",
  ARTICLE = "ARTICLE",
  PARTNERSHIP = "PARTNERSHIP"
}
