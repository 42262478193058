import { FormikProps } from "formik";

import generateSlugField from "../../../../constants/generateSlugField";
import TabPanel from "../../../TabPanel";
import { FormDataInterface } from "../../types";
import { InputField } from "../commonStyledComponents";

interface Props {
  formik: FormikProps<FormDataInterface>;
  value: number;
  isUpdateOperation: boolean;
}

const InfoPanel: React.FC<Props> = ({ formik, value, isUpdateOperation }) => {
  return (
    <TabPanel value={value} index={0}>
      <InputField
        fullWidth
        id="headline"
        name="headline"
        label="Headline"
        variant="standard"
        value={formik.values.headline}
        onChange={(e) => {
          formik.setFieldValue("headline", e.target.value);
          generateSlugField(e.target.value, formik);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.headline && Boolean(formik.errors.headline)}
        helperText={formik.touched.headline && formik.errors.headline}
      />
      <InputField
        fullWidth
        id="shortHeadline"
        name="shortHeadline"
        label="Short Headline"
        variant="standard"
        value={formik.values.shortHeadline}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.shortHeadline && Boolean(formik.errors.shortHeadline)
        }
        helperText={formik.touched.shortHeadline && formik.errors.shortHeadline}
      />
      <InputField
        fullWidth
        id="sell"
        name="sell"
        label="Sell"
        variant="standard"
        value={formik.values.sell}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.sell && Boolean(formik.errors.sell)}
        helperText={formik.touched.sell && formik.errors.sell}
      />
      <InputField
        fullWidth
        id="slug"
        name="slug"
        label="Slug"
        variant="standard"
        value={formik.values.slug}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.slug && Boolean(formik.errors.slug)}
        helperText={formik.touched.slug && formik.errors.slug}
      />
      <InputField
        fullWidth
        id="code"
        name="code"
        label="XCode"
        variant="standard"
        value={formik.values.code}
        disabled={isUpdateOperation}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={formik.touched.code && formik.errors.code}
      />
    </TabPanel>
  );
};

export default InfoPanel;
