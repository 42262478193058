import styled from "@emotion/styled";
import { Button, FormLabel } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { FormikErrors } from "formik";
import { FC, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import { formatBytes } from "./utils";

const Container = styled.section`
  width: 100%;
  margin: 10px 0;
`;

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${({ error }: ImageUploadProps) =>
    error ? "#d32f2f" : "#eeeeee"};
  border-style: dashed;
  background-color: ${({ error }: ImageUploadProps) =>
    error ? "#fbeaea" : "#fafafa"};
  color: ${({ error }: ImageUploadProps) => (error ? "#de6363" : "#bdbdbd")};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

const ImagePreview = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

const ErrorMessage = styled.p`
  color: #d32f2f;
`;

const ImageSizeMessage = styled.span`
  display: block;
  color: ${blue[700]};
  margin: 5px 0;
  font-weight: 600;
`;

const RejectedFileSizeMessage = styled.p`
  color: ${red[400]};
  margin: 15px 0 5px 0;
  font-weight: 600;
`;

const ImageResizeMessage = styled.p`
  color: #bdbdbd;
  margin: 5px 0;
  font-weight: 600;
`;

interface ImageUploadProps {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (name: string, value: File) => void;
  imageResizeMessage?: string;
  error?: boolean;
  helperText?:
    | string
    | boolean
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[];
  maxFileSize?: number;
}

const ImageUpload: FC<ImageUploadProps> = ({
  id,
  label,
  name,
  value,
  onChange,
  imageResizeMessage,
  error,
  helperText,
  maxFileSize = 921600 // 900 KB
}) => {
  const [imageFile, setImageFile] = useState<string>(value);
  const [imageSize, setImageSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);
  const [rejectedFileSize, setRejectedFileSize] = useState<number | undefined>(
    undefined
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    maxSize: maxFileSize,
    noClick: true,
    onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const acceptedFile = acceptedFiles[0];
      const rejectedFile = rejectedFiles[0];
      setRejectedFileSize(undefined);
      if (rejectedFile && rejectedFile.file.size > maxFileSize) {
        setRejectedFileSize(rejectedFile.file.size);
      }
      const image = new Image();
      image.onload = () => {
        setImageSize({
          width: image.width,
          height: image.height
        });
      };
      image.src = URL.createObjectURL(acceptedFile);
      setImageFile(image.src);
      onChange(name, acceptedFile);
    }
  });

  const isValidUrl = (string: string): boolean => {
    try {
      return Boolean(new URL(string));
    } catch (_) {
      return false;
    }
  };

  const colorState = error ? "error" : "primary";

  return (
    <Container>
      <FormLabel
        color={colorState}
        error={error}
        component="legend"
        data-testid={`${id}-label`}
      >
        {label}
      </FormLabel>
      <DropZone error={error} {...getRootProps()}>
        <input id={id} name={name} {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <ThumbsContainer>
          {isValidUrl(imageFile) && (
            <Thumb>
              <ThumbInner>
                <ImagePreview src={imageFile} data-testid={`${id}-preview`} />
              </ThumbInner>
            </Thumb>
          )}
        </ThumbsContainer>
        <Button
          color={colorState}
          variant="contained"
          onClick={open}
          data-testid={`${id}-button`}
        >
          Upload a {imageFile && "different"} file
        </Button>
        {imageSize && (
          <p>
            Selected image size:
            <ImageSizeMessage>
              {imageSize.width} x {imageSize.height}
            </ImageSizeMessage>
          </p>
        )}
        {rejectedFileSize && (
          <RejectedFileSizeMessage>
            Maximum file size of {formatBytes(maxFileSize)} exceeded. Selected
            file size: {formatBytes(rejectedFileSize)}
          </RejectedFileSizeMessage>
        )}
      </DropZone>

      <ImageResizeMessage>{imageResizeMessage}</ImageResizeMessage>
      {error && (
        <ErrorMessage data-testid={`${id}-error`}>{helperText}</ErrorMessage>
      )}
    </Container>
  );
};

export default ImageUpload;
