import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { FC } from "react";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
`;

interface PanelProps {
  value: number;
  index: number;
  children: React.ReactNode;
}

const TabPanel: FC<PanelProps> = ({ children, value, index }) =>
  value === index ? <Container p={1}>{children}</Container> : null;

export default TabPanel;
