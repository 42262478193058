import { ImageResponse } from "../../../clients/services/sams/gallery/types";

export const setImageFieldsValues = (formik: any) => (data: ImageResponse) => {
  if ("detail_main" in data) {
    formik.setFieldValue("images.image.original", data.original);
    formik.setFieldValue("images.image.detail_main", data.detail_main);
    formik.setFieldValue("images.image.offers_list", data.offers_list);
  }
  if ("offers_thumb" in data) {
    formik.setFieldValue("images.thumbnail_image.original", data.original);
    formik.setFieldValue(
      "images.thumbnail_image.offers_thumb",
      data.offers_thumb
    );
  }
};
