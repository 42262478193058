import styled from "@emotion/styled";
import { blue } from "@mui/material/colors";
import { FC } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import { ReactComponent as InfoIcon } from "../../../icons/info-icon.svg";

interface InfoTooltipProps {
  message: string;
}

const InfoTooltipContainer = styled.div`
  margin: 0 5px;
`;

const InfoTooltip: FC<InfoTooltipProps> = ({ message }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    placement: "right"
  });

  return (
    <InfoTooltipContainer>
      <div ref={setTriggerRef} data-testid="tooltip-info">
        <InfoIcon width={15} height={15} fill={blue[700]} />
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
          data-testid="tooltip-content"
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {message}
        </div>
      )}
    </InfoTooltipContainer>
  );
};

export default InfoTooltip;
