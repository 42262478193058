import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import {
  getMorePage,
  createMorePage,
  updateMorePage,
  CreateMorePageRequestBody
} from "../../clients/services/sams/content/morePage";
import useFormData from "../../hooks/useFormData";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import MorePageForm from "./Form/MorePageForm";
import { formDataTemplate } from "./formDataTemplate";
import { createTransformer } from "./transformer";
import { validationSchema } from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageMorePage = () => {
  const transformer = createTransformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(transformer, getMorePage, createMorePage, updateMorePage);
  const history = useHistory();

  const [onSuccess, onError] = useMutationHandler(
    `More Page ${isUpdateOperation ? "updated" : "created"} successfully!`,
    () => history.push("/more-pages")
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation<any, AxiosError<any>, CreateMorePageRequestBody>(
    mutationFunction,
    {
      onSuccess,
      onError
    }
  );

  const formProperties = isUpdateOperation
    ? {
        title: "Update More Page",
        actionLabel: "Update",
        actionTestId: "updateMorePageTestID",
        formId: "updateMorePage"
      }
    : {
        title: "Create More Page",
        actionLabel: "Create",
        actionTestId: "createMorePageTestID",
        formId: "createMorePage"
      };

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage
          simpleErrors={["Cannot get more page!"]}
          data-testid={"error-message"}
        />
      </StyledError>
    );
  }

  if (isLoading) {
    // Wait for getMorePage before the first Form render
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <MorePageForm
        values={values}
        validationSchema={validationSchema}
        onSubmit={mutate}
        isSubmitting={isSubmitting}
        {...formProperties}
      />
    </>
  );
};

export default ManageMorePage;
