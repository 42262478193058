import { DateTime } from "luxon";

export const formatDate = (date: string) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const offset = new Date().getTimezoneOffset();

  let dateTime = DateTime.fromJSDate(new Date(date)).setZone(timeZone);

  // If the date is in daylight savings time, we need to add the offset to the hours
  if (dateTime.isInDST) {
    dateTime = dateTime.plus({ hours: Math.abs(offset / 60) });
  }

  return dateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss");
};
