import styled from "@emotion/styled";
import { FormGroup, TextField } from "@mui/material";
import { ErrorMessage as ErrorMessageFormik } from "formik";

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Paragraph = styled.p`
  margin-top: 16px;
`;

export const SelectDesriptionParagraph = styled.p`
  margin: 5px auto 5px 10px;
  color: #555;
  font-size: 0.9rem;
`;

export const InputField = styled(TextField)`
  margin-top: 8px;
`;

export const StyledErrorMessage = styled(ErrorMessageFormik)`
  color: #d32f2f;
  font-size: 0.75rem;
  margin-top: -15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;
