import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import {
  createPartnership,
  getPartnership,
  updatePartnership
} from "../../clients/services/sams/content/partnership";
import useFormData from "../../hooks/useFormData";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import formDataTemplate from "./formDataTemplate";
import Transformer from "./transformer";
import { FormDataInterface } from "./types";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManagePartnership = () => {
  const transformer = new Transformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(
      transformer,
      getPartnership,
      createPartnership,
      updatePartnership
    );
  const history = useHistory();

  const [onSuccess, onError] = useMutationHandler(
    `Partnership ${isUpdateOperation ? "updated" : "created"} successfully!`,
    () => history.push("/partnerships")
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation<any, AxiosError<any>, FormDataInterface>(mutationFunction, {
    onSuccess,
    onError
  });

  const submitHandler = async (inputValues: FormDataInterface) => {
    await mutate(transformer.formDataToModel(inputValues));
  };

  const formProperties = isUpdateOperation
    ? {
        title: "Update Partnership",
        actionLabel: "Update",
        actionTestId: "updatePartnershipTestID",
        formId: "updatePartnership"
      }
    : {
        title: "Create Partnership",
        actionLabel: "Create",
        actionTestId: "createPartnershipTestID",
        formId: "createPartnership"
      };

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage simpleErrors={["Cannot get partnership!"]} />
      </StyledError>
    );
  }

  if (isLoading) {
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        {...formProperties}
      />
    </>
  );
};

export default ManagePartnership;
