export const PAGE_SIZE = 20;

export const imageGallery = {
  tooltipMessage: {
    promotion: "Please, add promotion code before selecting an image.",
    partnership: "Please, add a headline before selecting an image.",
    product: "Please, add a title before selecting an image.",
    article: "Please, add a headline before selecting an image.",
    raffleWinner: "Please, add a title before selecting an image.",
    attraction: "",
    morePage: "Please, add a headline before selecting an image."
  }
};

export const promotion = {
  sunProductSelect: {
    description: "Choose where this offer should be shown"
  }
};

export const partnership = {
  sunProductSelect: {
    description: "Choose where this partnership should be shown"
  }
};
