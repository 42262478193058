import * as yup from "yup";

import { Status } from "../../clients/services/sams/types";

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  mainImage: yup.string().required("Image is required"),
  thumbnailImage: yup.string().required("Thumbnail is required"),
  raffleDate: yup.date().required("Raffle Date is required"),
  ticketNumber: yup.string(),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Status is required")
});

export default validationSchema;
