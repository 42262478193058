import {
  OfferRedemptionMechanic,
  PromotionType
} from "../../clients/services/sams/promotion/types";
import { Status, SunProduct } from "../../clients/services/sams/types";

import { FormDataInterface } from "./types";

const formDataTemplate: FormDataInterface = {
  actions: [],
  dnaActions: [],
  allowMultipleRedemption: false,
  code: "",
  codeCollectEndDate: "",
  codeCollectStartDate: "",
  headline: "",
  maximumRedemptions: 1000000,
  name: "",
  offerContent: "",
  optInButtonText: "",
  previewCpns: "",
  promotionType: PromotionType.REGULAR,
  publicationStatus: Status.DRAFT,
  positionNumber: 0,
  redemptionButtonText: "",
  redemptionEndDateTime: "",
  bookingCodeEndDate: "",
  redemptionLinkUrl: "",
  redemptionStartDateTime: "",
  redemptionThreshold: 0,
  resendButtonText: "",
  redemptionMechanic: OfferRedemptionMechanic.EXTERNAL,
  sell: "",
  shortHeadline: "",
  slug: "",
  soldOut: false,
  feeOnRedemption: 0,
  internalReview: false,
  transactionHistoryLabel: "",
  termsAndConditions: "",
  isUpdateOperation: false,
  showMarketingPermissions: false,
  partnerName: "",
  privacyPolicyUrl: "",
  privacyPolicy: "",
  alwaysOn: false,
  image: "",
  imageSmall: "",
  sunProduct: SunProduct.SUN_SAVERS
};

export default formDataTemplate;
