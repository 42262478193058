import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useQuery } from "react-query";

import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import {
  deletePartnership,
  listPartnerships
} from "../../clients/services/sams/content/partnership";
import { PartnershipListResponse } from "../../clients/services/sams/content/partnership/types";
import { PostType, SunProduct } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

const PartnershipTable = () => {
  const { isLoading, data, error, refetch } = useQuery(
    ["listPartnerships"],
    () => listPartnerships()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.PARTNERSHIP)
  );

  const rows: Rows | undefined = data?.map(
    (partnership: PartnershipListResponse) => ({
      id: partnership.id,
      headline: partnership.headline,
      timePeriod: partnership.liveDate
        ? `${dateTimeFull(partnership.liveDate)} - ${dateTimeFull(
            partnership.endDate
          )}`
        : `End Date: ${dateTimeFull(partnership.endDate)}`,
      user: findLatestHistory(historyData, partnership.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, partnership.id)
        ? dateTimeFull(
            findLatestHistory(historyData, partnership.id)?.createdDate
          )
        : "Created from service",
      status: partnership.status,
      sunProduct: partnership.sunProduct
    })
  );

  const tableProperties = {
    itemName: "partnership",
    actionLabel: "Partnership"
  };

  const getSunProductChip = (params: GridRenderCellParams<any, SunProduct>) => {
    switch (params.value) {
      case SunProduct.SUN_SAVERS:
        return (
          <Chip
            color="secondary"
            label="Savers"
            variant="filled"
            data-testid="sunProductTestIDSunSavers"
          />
        );
      case SunProduct.SUN_MOBILE:
        return (
          <Chip
            color="primary"
            label="Mobile"
            variant="filled"
            data-testid="sunProductTestIDSunMobile"
          />
        );
      case SunProduct.MEMBERS_ENCLOSURE:
        return (
          <Chip
            color="info"
            label="Membership"
            variant="filled"
            data-testid="sunProductTestIDMembershipHub"
          />
        );
      case SunProduct.SUN_CLUB:
        return (
          <Chip
            label="Sun Club"
            variant="filled"
            data-testid="sunProductTestIDMembershipHub"
            style={{ backgroundColor: "#F44336", color: "#fff" }}
          />
        );
      default:
        return <></>;
    }
  };

  const columns: GridColDef[] = [
    { field: "headline", headerName: "Headline", minWidth: 170, flex: 1 },
    {
      field: "sunProduct",
      headerName: "Used By",
      minWidth: 90,
      maxWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SunProduct>) =>
        getSunProductChip(params)
    },
    {
      field: "timePeriod",
      headerName: "Time Period",
      minWidth: 340,
      flex: 1
    },
    { field: "user", headerName: "Author", minWidth: 130, flex: 1 },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    }
  ];

  return (
    <TableComponent<PartnershipListResponse>
      isLoading={isLoading || isLoadingHistory}
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="PartnershipTableTestID"
      createButton={{
        link: "/create-partnership",
        text: "Create Partnership"
      }}
      tableProperties={tableProperties}
      deleteFunction={deletePartnership}
    />
  );
};

export default PartnershipTable;
