import styled from "@emotion/styled";
import { Alert, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import { AxiosError } from "axios";
import { FormikErrors } from "formik";
import { FC, useState } from "react";
import { useMutation } from "react-query";

import { chooseImage, uploadImage } from "../../clients/services/sams/gallery";
import { ImageResponse } from "../../clients/services/sams/gallery/types";
import useMutationHandler from "../../hooks/useMutationHandler";
import { imageGallery } from "../../util/constants";
import Modal from "../Modal";
import InfoTooltip from "../RichContentEditor/InfoTooltip";

import ImageGallery from "./ImageGallery";
import ImageUpload from "./ImageUpload";

const Container = styled.section`
  width: 100%;
  margin: 10px 0;
`;

const ButtonSection = styled.div`
  padding: 20px 0;
  border-top: 1px solid #d7d7d7;
  text-align: center;
  background: #fafafa;
  bottom: 0;
  position: absolute;
  width: 100%;
  left: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 81px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const ImageWrapper = styled.div<{
  isImageSelected: boolean;
  isLoadingImage: boolean;
  isImageSelectionDisabled: boolean;
  isProductThumbnail: boolean;
  isRaffleWinnerThumbnail: boolean;
  isAttractionImage: boolean;
}>`
  position: relative;
  width: ${(props) =>
    props.isProductThumbnail ||
    props.isRaffleWinnerThumbnail ||
    props.isAttractionImage
      ? "102px"
      : "430px"};
  height: ${(props) =>
    props.isProductThumbnail ||
    props.isRaffleWinnerThumbnail ||
    props.isAttractionImage
      ? "102px"
      : "286px"};
  margin: 25px 5px 15px 5px;
  border: ${(props) =>
    props.isImageSelected && !props.isLoadingImage
      ? "1px solid white"
      : "2px dashed #cdcdcd"};
  box-shadow: ${(props) =>
    props.isImageSelected && !props.isLoadingImage
      ? "0 0 0 5px #1976d2"
      : "none"};
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) =>
    props.isImageSelectionDisabled ? "default" : "pointer"};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledAlert = styled(Alert)`
  margin: 10px auto auto 0;
`;

type PostType =
  | "promotion"
  | "partnership"
  | "product"
  | "article"
  | "raffleWinner"
  | "attraction"
  | "morePage";
type ImageType = "main" | "thumbnail" | "attraction";

interface ImageSelectionProps {
  id: string;
  value: string;
  setFieldValue: (name: string, value: File | string) => void;
  isImageSelectionDisabled: boolean;
  btnText?: string;
  imageResizeMessage?: string;
  error?: boolean;
  helperText?:
    | string
    | boolean
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[];
  isImageSelected: boolean;
  postType: PostType;
  imageUploadId?: string;
  imageToUpload: any;
  postHeadline: string;
  setImageFieldsValues: (data: ImageResponse) => void;
  imageType?: ImageType;
}

const ImageSelection: FC<ImageSelectionProps> = ({
  id,
  value,
  setFieldValue,
  imageResizeMessage,
  error,
  helperText,
  btnText,
  isImageSelectionDisabled,
  isImageSelected,
  postType,
  imageUploadId = "image",
  postHeadline,
  imageToUpload,
  setImageFieldsValues,
  imageType
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [fileToUpload, setFileToUpload] = useState(false);
  const [showMutationError, setShowMutationError] = useState(false);

  const [onSuccess, onError] = useMutationHandler(
    `Image selected successfully!`
  );

  const {
    mutate: mutateChooseImage,
    isLoading: isLoadingChooseImage,
    error: errorChooseImage
  } = useMutation<any, AxiosError<any>, any>(chooseImage, {
    onSuccess: (data: ImageResponse) => {
      onSuccess();
      setImageFieldsValues(data);
      setShowMutationError(false);
    },
    onError: (err) => {
      setShowMutationError(!!err);
      onError(err);
    }
  });

  const handleOnImageSelect = async (galleryImagePath: string) => {
    await mutateChooseImage({
      galleryImagePath,
      postType,
      postHeadline,
      imageType: imageType || ""
    });
  };

  const showChooseError = showMutationError && !!errorChooseImage;

  const errorMessageChoose =
    errorChooseImage?.response?.data.errorMessage || errorChooseImage?.message;

  const {
    mutate: mutateUploadImage,
    isLoading: isLoadingUploadImage,
    error: errorUploadImage
  } = useMutation<any, AxiosError<any>, any>(uploadImage, {
    onSuccess: (data: ImageResponse) => {
      setImageFieldsValues(data);
      setShowMutationError(false);
    },
    onError: (err) => {
      setShowMutationError(!!err);
      onError(err);
    }
  });

  const handleOnImageUpload = async () => {
    await mutateUploadImage({
      postType,
      postHeadline,
      image: imageToUpload,
      imageType: imageType || ""
    });
  };

  const showUploadError = showMutationError && !!errorUploadImage;

  const errorMessageUpload =
    errorUploadImage?.response?.data.errorMessage || errorUploadImage?.message;

  const isLoadingImage = isLoadingChooseImage || isLoadingUploadImage;

  const clearState = () => {
    setSelectedImage("");
    setFileToUpload(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleSelect = () => {
    if (fileToUpload) {
      handleOnImageUpload();
    } else if (selectedImage.length !== 0) {
      if (postType === "attraction") {
        setImageFieldsValues({ attractionImage: selectedImage });
      } else {
        handleOnImageSelect(selectedImage);
      }
    }
    setOpenModal(false);
  };

  const handleCancel = () => {
    clearState();
    setOpenModal(false);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    clearState();
  };

  const colorState = error ? "error" : "primary";

  return (
    <Container>
      {showUploadError && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {errorMessageUpload}
        </StyledAlert>
      )}
      {showChooseError && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {errorMessageChoose}
        </StyledAlert>
      )}
      <ImageWrapper
        key={"imagePreview"}
        isImageSelected={isImageSelected}
        isLoadingImage={isLoadingImage}
        onClick={() => (isImageSelectionDisabled ? null : handleOpen())}
        isImageSelectionDisabled={isImageSelectionDisabled}
        isProductThumbnail={postType === "product" && imageType === "thumbnail"}
        isRaffleWinnerThumbnail={
          postType === "raffleWinner" && imageType === "thumbnail"
        }
        isAttractionImage={postType === "attraction"}
      >
        {isImageSelected && !isLoadingImage && (
          <Image src={value} alt={`Image`} />
        )}
        {isLoadingImage && <CircularProgress data-testid={"loading"} />}
      </ImageWrapper>
      <ButtonWrapper>
        <Button
          color={colorState}
          variant="contained"
          onClick={handleOpen}
          data-testid={`${id}-button`}
          disabled={isImageSelectionDisabled}
        >
          {btnText}
        </Button>
        {isImageSelectionDisabled && postType && (
          <InfoTooltip message={imageGallery.tooltipMessage[postType]} />
        )}
      </ButtonWrapper>
      <Modal
        title={`Image Selection`}
        testIdPrefix={`download-booking-data-method-${id}`}
        open={openModal}
        handleClose={handleCancel}
        maxWidth={"xl"}
        width={"100%"}
        height={"100%"}
      >
        <StyledTabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          {postType !== "attraction" && <Tab label="Upload New Image" />}
          <Tab data-testid="gallery-tab" label="Select from Gallery" />
        </StyledTabs>
        {selectedTab === 0 && postType !== "attraction" && (
          <ImageUpload
            id={imageUploadId}
            label="Featured Image"
            name={imageUploadId}
            value={value}
            onChange={(name: string, fileValue: File) => {
              setFileToUpload(true);
              setFieldValue(name, fileValue);
            }}
            imageResizeMessage={imageResizeMessage}
            error={error}
            helperText={helperText}
          />
        )}
        {(selectedTab === 1 || postType === "attraction") && (
          <ImageGallery
            imageResizeMessage={imageResizeMessage}
            onImageSelect={(imageUrl) => setSelectedImage(imageUrl)}
            imagesPrefix={
              postType === "attraction" ? "attractions" : "thumbnails"
            }
          />
        )}
        <ButtonSection>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="outlined"
            data-testid={`${id}-cancel-button`}
          >
            Cancel
          </Button>
          <Button
            color={colorState}
            variant="contained"
            onClick={handleSelect}
            data-testid={`${id}-button`}
            disabled={!(fileToUpload || !!selectedImage)}
          >
            {fileToUpload ? "Upload image" : "Use selected image"}
          </Button>
        </ButtonSection>
      </Modal>
    </Container>
  );
};

export default ImageSelection;
