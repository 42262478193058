import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider
} from "@mui/material";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Notification } from "./components/Notification";
import SamsAppBar from "./components/SamsAppBar";
import SideBar from "./components/SideBar";
import useAuth from "./hooks/useAuth";
import useConfig from "./hooks/useConfig";
import Dashboard from "./pages/Dashboard";
import routes, { sideBarRoutes } from "./routes";
import { useAppSelector } from "./state/hooks";

import "./App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2"
    },
    secondary: {
      main: "#db2780"
    },
    info: {
      main: "#4caf50",
      contrastText: "#fff"
    }
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  useConfig();
  const config = useAppSelector((state) => state.config);
  // TO DO: change enabledNavigation to enabled when mock mode is done
  const isMockMode = useAppSelector((state) => state.mock.enabledNavigation);
  const auth = useAuth();

  const [sideBarOpen, setSideBarOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setSideBarOpen(!sideBarOpen);
  };

  if (!config || !auth) {
    return (
      <div className="App">
        <header className="App-header">
          <CircularProgress data-testid={"loading"} />
        </header>
      </div>
    );
  }

  const { oktaAuth, restoreOriginalUri } = auth;
  return (
    <div data-testid="root" className="App">
      <QueryClientProvider client={queryClient}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <ThemeProvider theme={theme}>
            <SamsAppBar handleDrawerToggle={handleDrawerToggle} />
            <Router>
              <Box display={"flex"}>
                <Box>
                  <SideBar routes={sideBarRoutes} open={sideBarOpen} />
                </Box>
                <Box
                  style={{
                    paddingLeft: "32px",
                    overflowX: "hidden",
                    flex: 1
                  }}
                >
                  <Switch>
                    <Route path={"/"} exact={true} children={<Dashboard />} />
                    {config.environment !== "dev" &&
                    config.environment !== "local" &&
                    !isMockMode
                      ? routes.map((route, index) => (
                          <SecureRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            children={<route.main />}
                          />
                        ))
                      : routes.map((route, index) => (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            children={<route.main />}
                          />
                        ))}
                    <Route path={"/login/callback"} component={LoginCallback} />
                  </Switch>
                </Box>
              </Box>
            </Router>
          </ThemeProvider>
        </Security>
      </QueryClientProvider>
      <Notification />
    </div>
  );
};

export default App;
