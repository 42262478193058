import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import {
  RaffleConfig,
  RaffleConfigGetResponse,
  RaffleConfigResponse
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createUpdateRaffleConfig = async (raffleConfig: RaffleConfig) => {
  const url = `${baseUrl}/content/raffle-config`;

  const response: AxiosResponse<RaffleConfigResponse> = await client.post(
    url,
    raffleConfig
  );
  return response.data;
};

export const getRaffleConfig = async () => {
  const url = `${baseUrl}/content/raffle-config`;

  const response: AxiosResponse<RaffleConfigGetResponse[]> = await client.get(
    url
  );
  return response.data;
};
