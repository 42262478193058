import { useQuery } from "react-query";

import { listPartnerships } from "../../../clients/services/sams/content/partnership";
import { Status } from "../../../clients/services/sams/types";

export function useGetPartnerships() {
  const {
    isLoading: isLoadingPartnerships,
    data,
    error: partnershipsError
  } = useQuery(["listPartnerships"], () => listPartnerships());
  const todayDate = new Date();

  const partnershipsData = data
    ?.filter(
      (partnership) =>
        // Exclude draft partnerships and expired
        partnership.status !== Status.DRAFT &&
        new Date(partnership.endDate as string) > todayDate
    )
    .sort((a, b) => (a.positionNumber > b.positionNumber ? 1 : -1));

  return {
    isLoadingPartnerships,
    partnershipsData: partnershipsData ?? [],
    partnershipsError
  };
}
