import moment from "moment";

export const dateOnly = (date: string | Date | undefined) =>
  moment(date).format("YYYY-MM-DD");

export const dateTime = (date: string | Date | undefined) =>
  moment(date).format("YYYY-MM-DD HH:mm");

export const dateTimeZone = (date: string | Date | undefined) =>
  moment(date).format("YYYY-MM-DDTHH:mm");

export const dateTimeFull = (date: string | Date | undefined) =>
  moment(date).format("LLL");

export const dateMonthYear = (date: string | Date | undefined) =>
  moment(date).format("MM-YYYY");

export const dateInPast = (date: string) => {
  const dateToCheck = new Date(date);
  const today = new Date();
  return dateToCheck.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
};
