import moment from "moment";

import { Slot } from "../../clients/services/sams/content/slot/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: Slot) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (formData: Slot) => {
    return {
      ...formData,
      objectId: formData.objectId && formData.objectId.toString(),
      slot: Number(formData.slot),
      startDate:
        formData.startDate !== "" && typeof formData.startDate === "string"
          ? new Date(formData.startDate)
          : "",
      endDate:
        formData.endDate !== "" && typeof formData.endDate === "string"
          ? new Date(formData.endDate)
          : ""
    };
  };

  public modelToFormData = (modelData: Slot) => {
    return {
      ...modelData,
      startDate: moment(modelData.startDate).format("YYYY-MM-DDTHH:mm"),
      endDate: moment(modelData.endDate).format("YYYY-MM-DDTHH:mm")
    };
  };
}

export default Transformer;
