import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import { SlotResponse, Slot, SlotListResponse } from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createSlot = async (slot: Slot) => {
  const url = `${baseUrl}/content/slots`;

  const response: AxiosResponse<SlotResponse> = await client.post(url, slot);
  return response.data;
};

export const listSlots = async () => {
  const url = `${baseUrl}/content/slots`;

  const response: AxiosResponse<SlotListResponse[]> = await client.get(url);
  return response.data;
};

export const deleteSlot = async (id: string) => {
  const url = `${baseUrl}/content/slots/${id}`;

  await client.delete(url);
};

export const getSlot = async (id: string) => {
  const url = `${baseUrl}/content/slots/${id}`;

  const response: AxiosResponse<SlotListResponse> = await client.get(url);
  return response.data;
};

export const updateSlot = async (slot: SlotListResponse) => {
  const url = `${baseUrl}/content/slots/${slot.id}`;

  const response: AxiosResponse<SlotListResponse> = await client.put(url, slot);
  return response.data;
};
