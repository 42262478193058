import styled from "@emotion/styled";
import { MenuItem, TextField, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";

import { SunProduct } from "../../../clients/services/sams/types";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import SelectField from "../../SelectField";
import StatusSelector from "../../StatusSelector";

const CreateBannerContainer = styled.div`
  padding-top: 15px;
`;

const CustomInputField = styled(TextField)`
  margin-top: 24px;
`;

const DatesInputFlexer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const DateInput = styled(TextField)`
  margin-top: 24px;
  width: 47%;
`;

const CustomFormControl = styled(SelectField)`
  margin-top: 24px;
  width: 100%;
`;

interface FormInterface {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form: FC<FormInterface> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting
}) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });
  return (
    <CreateBannerContainer data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        <Grid container item xs={8}>
          <CustomFormControl
            form={formik}
            label={"Sun Product"}
            labelID={"labelProductId"}
            name={"sunProduct"}
            defaultValue={formik.values.sunProduct}
          >
            <MenuItem value={SunProduct.SUN_SAVERS} data-testid="Sun Savers">
              Sun Savers
            </MenuItem>
            <MenuItem
              value={SunProduct.MEMBERS_ENCLOSURE}
              data-testid="Membership Hub"
            >
              Members Enclosure
            </MenuItem>
          </CustomFormControl>
          <form id={formId} onSubmit={formik.handleSubmit}>
            {formik.submitCount !== 0 && (
              <ErrorMessage formValidationErrors={formik.errors} />
            )}
            <CustomInputField
              fullWidth
              id="title"
              name="title"
              variant="standard"
              label="Title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <CustomInputField
              fullWidth
              id="text"
              name="text"
              label="Message"
              variant="standard"
              multiline
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
            <CustomInputField
              fullWidth
              id="readMoreLabel"
              name="readMoreLabel"
              label="Read More Label"
              variant="standard"
              value={formik.values.readMoreLabel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readMoreLabel &&
                Boolean(formik.errors.readMoreLabel)
              }
              helperText={
                formik.touched.readMoreLabel && formik.errors.readMoreLabel
              }
            />
            <CustomInputField
              fullWidth
              id="dismissLabel"
              name="dismissLabel"
              label="Dismiss Label"
              variant="standard"
              value={formik.values.dismissLabel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.dismissLabel &&
                Boolean(formik.errors.dismissLabel)
              }
              helperText={
                formik.touched.dismissLabel && formik.errors.dismissLabel
              }
            />
            <CustomInputField
              fullWidth
              id="readMoreUrl"
              name="readMoreUrl"
              label="Read More URL"
              variant="standard"
              value={formik.values.readMoreUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readMoreUrl && Boolean(formik.errors.readMoreUrl)
              }
              helperText={
                formik.touched.readMoreUrl && formik.errors.readMoreUrl
              }
            />
            <DatesInputFlexer>
              <DateInput
                id="startDate"
                name="startDate"
                label="Start Date"
                variant="standard"
                type="datetime-local"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <DateInput
                id="endDate"
                name="endDate"
                label="End Date"
                variant="standard"
                type="datetime-local"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </DatesInputFlexer>
            <CustomFormControl
              form={formik}
              label={"Page"}
              labelID={"labelPageId"}
              name={"page"}
              defaultValue={formik.values.page}
            >
              {formik.values.sunProduct === SunProduct.SUN_SAVERS && (
                <MenuItem value={"/home"}>Home Page</MenuItem>
              )}
              {formik.values.sunProduct === SunProduct.SUN_SAVERS && (
                <MenuItem value={"/offers"}>Offers Page</MenuItem>
              )}
              <MenuItem value={"all"}>Visible on all Pages</MenuItem>
              {formik.values.sunProduct === SunProduct.SUN_SAVERS && (
                <MenuItem value={"/cashout"}>Cashout Page</MenuItem>
              )}
              {formik.values.sunProduct === SunProduct.SUN_SAVERS && (
                <MenuItem value={"/raffle"}>Raffle Page</MenuItem>
              )}
            </CustomFormControl>
          </form>
        </Grid>
        <Grid container item xs={4}>
          <StatusSelector
            isSubmitting={isSubmitting}
            form={formik}
            actionLabel={actionLabel}
            testId={actionTestId}
          />
          {values.id ? (
            <HistoryTable postType={"banner"} postId={values.id} />
          ) : null}
        </Grid>
      </Grid>
    </CreateBannerContainer>
  );
};

export default Form;
