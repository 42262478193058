import styled from "@emotion/styled";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const StyledDialog = styled(Dialog)<{ width?: string; height?: string }>`
  & .MuiDialogTitle-root {
    background: #1a76d2;
    font-size: 0.9rem;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
  }
  & .MuiDialogContent-root {
    padding-top: 25px;
  }
  & .MuiPaper-elevation {
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
  }
`;

interface Props {
  title: string;
  testIdPrefix?: string;
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  width?: string;
  height?: string;
}

const Modal = ({
  title,
  testIdPrefix = "modal-confirm",
  children,
  open,
  handleClose,
  maxWidth,
  width,
  height
}: Props) => {
  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid={`${testIdPrefix}-dialog`}
        maxWidth={maxWidth}
        width={width}
        height={height}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
      </StyledDialog>
    </>
  );
};

export default Modal;
