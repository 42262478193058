import { GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { listArticles } from "../../clients/services/sams/content/article";
import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import { listPartnerships } from "../../clients/services/sams/content/partnership";
import {
  deleteSlot,
  listSlots
} from "../../clients/services/sams/content/slot";
import { SlotListResponse } from "../../clients/services/sams/content/slot/types";
import { getPromotions } from "../../clients/services/sams/promotion";
import { PostType } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import { getCardField } from "../../constants/slotsGetCardField";
import { ErrorMessage } from "../ErrorMessage";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

const SlotTable = () => {
  const {
    isLoading: isLoadingPromotion,
    data: promotionData,
    error: promotionError
  } = useQuery(["getPromotions"], () => getPromotions());
  const {
    isLoading: isLoadingArticle,
    data: articleData,
    error: articleError
  } = useQuery(["listArticles"], () => listArticles());
  const {
    isLoading: isLoadingPartnership,
    data: partnershipData,
    error: partnershipError
  } = useQuery(["getPartnerships"], () => listPartnerships());
  const { isLoading, data, error, refetch } = useQuery(["listSlots"], () =>
    listSlots()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.SLOT)
  );

  const rows: Rows | undefined = data?.map((slot: SlotListResponse) => {
    return {
      id: slot.id,
      card: getCardField(
        slot.objectId,
        slot.objectType,
        promotionData,
        partnershipData,
        articleData
      ),
      status: slot.status,
      slot: slot.slot,
      user: findLatestHistory(historyData, slot.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, slot.id)
        ? dateTimeFull(findLatestHistory(historyData, slot.id)?.createdDate)
        : "Created from service",
      timePeriod: slot.startDate
        ? `${dateTimeFull(slot.startDate)} - ${dateTimeFull(slot.endDate)}`
        : `Redemption End Date: ${dateTimeFull(slot.endDate)}`
    };
  });
  const tableProperties = {
    itemName: "slot",
    actionLabel: "Slot"
  };

  const columns: GridColDef[] = [
    { field: "card", headerName: "Card", minWidth: 120, flex: 1 },
    { field: "status", headerName: "Status", minWidth: 130, flex: 1 },
    { field: "slot", headerName: "Slot", maxWidth: 90, flex: 1 },
    { field: "user", headerName: "Author", minWidth: 130, flex: 1 },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    },
    { field: "timePeriod", headerName: "Time Period", minWidth: 360, flex: 1 }
  ];

  if (promotionError) {
    return <ErrorMessage axiosErrors={[promotionError as AxiosError]} />;
  }
  if (partnershipError) {
    return <ErrorMessage axiosErrors={[partnershipError as AxiosError]} />;
  }
  if (articleError) {
    return <ErrorMessage axiosErrors={[articleError as AxiosError]} />;
  }

  return (
    <TableComponent<SlotListResponse>
      isLoading={
        isLoading ||
        isLoadingArticle ||
        isLoadingPromotion ||
        isLoadingPartnership ||
        isLoadingHistory
      }
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="SlotTableTestID"
      createButton={{
        link: "/create-slot",
        text: "Create Slot"
      }}
      tableProperties={tableProperties}
      deleteFunction={deleteSlot}
    />
  );
};

export default SlotTable;
