import { useQuery } from "react-query";

import { listProducts } from "../../../clients/services/sams/merlin";

export function useListProducts() {
  const {
    isLoading: isLoadingProducts,
    data: productsData,
    error: productsError
  } = useQuery(["listProducts"], () => listProducts());

  return {
    isLoadingProducts,
    productsData: productsData ?? [],
    productsError
  };
}
