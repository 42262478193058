import * as yup from "yup";

import {
  ArticleType,
  Status,
  SunProduct
} from "../../clients/services/sams/types";

export function containsWhitespace(str: string) {
  return /\s/.test(str);
}

const validationSchema = yup.object({
  headline: yup.string().required("Headline is required"),
  shortHeadline: yup.string().required("Short Headline is required"),
  description: yup.string().required("Description is required"),
  badgeText: yup.string(),
  image: yup.string().required("Image is required"),
  publishStartDate: yup.date().required("Start Date is required"),
  publishEndDate: yup
    .date()
    .min(yup.ref("publishStartDate"), "End date can't be before start date")
    .required("End Date is required"),
  sell: yup.string().required("Sell is required"),
  slug: yup
    .string()
    .required("Slug is required")
    .test(
      "This field cannot contain white space",
      "This field cannot contain white space",
      (value) => !containsWhitespace(value as string)
    ),
  tandcs: yup.string(),
  sunProduct: yup.mixed().oneOf(Object.values(SunProduct)),
  articleType: yup.mixed().oneOf(Object.values(ArticleType)),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Status is required")
});

export default validationSchema;
