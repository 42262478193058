import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { useMutation } from "react-query";

import {
  createUpdateRaffleConfig,
  getRaffleConfig
} from "../../clients/services/sams/content/raffleConfig";
import { RaffleConfig } from "../../clients/services/sams/content/raffleConfig/types";
import useFormData from "../../hooks/useFormData";
import { FormModelTransformer } from "../../hooks/useFormData/types";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageRaffleConfig = () => {
  const [values, mutationFunction, isLoading, isError] = useFormData(
    {
      getFormDataTemplate: () => ({
        howDoesItWork: "",
        howToRedeem: "",
        tandcs: ""
      }),
      modelToFormData: (modelData: RaffleConfig) => modelData
    } as FormModelTransformer,
    getRaffleConfig,
    createUpdateRaffleConfig,
    createUpdateRaffleConfig,
    true
  );

  const [onSuccess, onError] = useMutationHandler(
    `Raffle Configuration updated successfully!`
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation(mutationFunction, {
    onSuccess,
    onError
  });

  const submitHandler = async (inputValues: RaffleConfig) => {
    await mutate(inputValues as any);
  };

  const formProperties = {
    title: "Update Raffle Configuration",
    actionLabel: "Publish",
    formId: "updateRaffleConfig",
    actionTestId: "UpdateRaffleConfigTestId"
  };

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage simpleErrors={["Cannot get raffle configuration!"]} />
      </StyledError>
    );
  }

  if (isLoading) {
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error">
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        {...formProperties}
      />
    </>
  );
};

export default ManageRaffleConfig;
