import { Button, CircularProgress } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const login = async () => {
    setLoading(true);
    return oktaAuth.signInWithRedirect();
  };

  return (
    <Button
      onClick={login}
      disabled={loading}
      color={"inherit"}
      style={{ minWidth: 100 }}
      data-testid="login"
    >
      {loading ? (
        <CircularProgress
          size={24}
          data-testid={"loading"}
          style={{ color: "white" }}
        />
      ) : (
        "Login"
      )}
    </Button>
  );
};

export default Login;
