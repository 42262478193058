import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { FieldArrayRenderProps } from "formik";

import { Variant } from "../../../clients/services/sams/merlin/types";

const CustomInputField = styled(TextField)`
  margin-top: 24px;
`;

interface VariantTableProps {
  arrayHelpers: FieldArrayRenderProps;
}

const VariantTable = ({ arrayHelpers }: VariantTableProps) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Merlin Gross</TableCell>
          <TableCell>Merlin Net</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Max Order Limit</TableCell>
          <TableCell>Minimum Order Limit</TableCell>
          <TableCell>Mandatory Quantity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {arrayHelpers.form.values.variants.map(
          (variant: Variant, index: number) => (
            <TableRow
              key={variant.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell component="th" scope="row">
                <CustomInputField
                  fullWidth
                  id={`variant_merlinVariantId_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.merlinVariantId}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      merlinVariantId: e.target.value
                    })
                  }
                  disabled
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_description_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.description}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      description: e.target.value
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_gross_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.grossAmount}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      grossAmount: e.target.value
                    })
                  }
                  disabled
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_net_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.netAmount}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      netAmount: e.target.value
                    })
                  }
                  disabled
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_price_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.price}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      price: e.target.value
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_maxOrderLimit_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.maxOrderLimit}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      maxOrderLimit: e.target.value
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_minOrderLimit_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.minOrderLimit}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      minOrderLimit: e.target.value
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <CustomInputField
                  fullWidth
                  id={`variant_mandatoryQuantity_${index}`}
                  name={`variants.${index}`}
                  variant="standard"
                  value={variant.mandatoryQuantity}
                  onChange={(e: any) =>
                    arrayHelpers.replace(index, {
                      ...variant,
                      mandatoryQuantity: e.target.value
                    })
                  }
                />
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default VariantTable;
