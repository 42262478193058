import {
  Inbox,
  Article,
  Flag,
  TipsAndUpdates,
  LocalOffer,
  EmojiEvents,
  ShoppingCart,
  GroupAdd,
  LocalActivity,
  FormatLineSpacing
} from "@mui/icons-material";

import ManageArticle from "./components/Article";
import ArticleTable from "./components/ArticleTable";
import Banner from "./components/Banner";
import BannerTable from "./components/BannerTable";
import BookingCodesTable from "./components/BookingCodesTable";
import ManageMorePage from "./components/MorePage/ManageMorePage";
import MorePageTable from "./components/MorePageTable";
import NewVersion from "./components/NewVersion";
import NewVersionTable from "./components/NewVersionTable";
import ManageOffer from "./components/Offer";
import OfferPageOrdering from "./components/OfferPageOrdering";
import OfferTable from "./components/OfferTable";
import ManagePartnership from "./components/Partnership";
import PartnershipTable from "./components/PartnershipTable";
import Product from "./components/Product";
import ProductFetch from "./components/ProductFetch";
import ProductTable from "./components/ProductTable";
import RaffleConfig from "./components/RaffleConfig";
import RaffleWinner from "./components/RaffleWinner";
import RaffleWinnersTable from "./components/RaffleWinnersTable";
import Slot from "./components/Slot";
import SlotTable from "./components/SlotTable";
import Dashboard from "./pages/Dashboard";
import { SamsRoute } from "./types";

export const sideBarRoutes: SamsRoute[] = [
  {
    name: "Dashboard",
    path: "/",
    exact: true,
    main: () => <Dashboard />,
    icon: <Inbox style={{ color: "#fff" }} />
  },
  {
    name: "Banners",
    path: "/banners",
    main: () => <BannerTable />,
    icon: <Flag style={{ color: "#fff" }} />,
    addIconLink: "/create-banner"
  },
  {
    name: "Articles",
    path: "/articles",
    main: () => <ArticleTable />,
    icon: <Article style={{ color: "#fff" }} />,
    addIconLink: "/create-article"
  },
  {
    name: "Offers",
    path: "/offers",
    main: () => <OfferTable />,
    icon: <LocalOffer style={{ color: "#fff" }} />,
    addIconLink: "/create-offer"
  },
  {
    name: "Offers Ordering",
    path: "/offers-position",
    main: () => <OfferPageOrdering />,
    icon: <FormatLineSpacing style={{ color: "#fff" }} />
  },
  {
    name: "Raffle Config",
    path: "/raffle-config",
    main: () => <RaffleConfig />,
    icon: <LocalActivity style={{ color: "#fff" }} />
  },
  {
    name: "Raffle Winners",
    path: "/raffle-winners",
    main: () => <RaffleWinnersTable />,
    icon: <EmojiEvents style={{ color: "#fff" }} />,
    addIconLink: "/add-winner"
  },
  {
    name: "Partnerships",
    path: "/partnerships",
    main: () => <PartnershipTable />,
    icon: <GroupAdd style={{ color: "#fff" }} />,
    addIconLink: "/create-partnership"
  },
  {
    name: "New Versions",
    path: "/new-versions",
    main: () => <NewVersionTable />,
    icon: <TipsAndUpdates style={{ color: "#fff" }} />,
    addIconLink: "/create-new-version"
  },
  {
    name: "Products",
    path: "/products",
    main: () => <ProductTable />,
    icon: <ShoppingCart style={{ color: "#fff" }} />
  },
  {
    name: "Attraction Products",
    path: "/attraction-products",
    main: () => <ProductFetch />,
    icon: <ShoppingCart style={{ color: "#fff" }} />
  },
  {
    name: "Slots",
    path: "/slots",
    main: () => <SlotTable />,
    icon: <Article style={{ color: "#fff" }} />,
    addIconLink: "/create-slot"
  },
  {
    name: "Booking Codes",
    path: "/booking-codes",
    main: () => <BookingCodesTable />,
    icon: <ShoppingCart style={{ color: "#fff" }} />
  },
  {
    name: "More Pages",
    path: "/more-pages",
    main: () => <MorePageTable />,
    icon: <Article style={{ color: "#fff" }} />,
    addIconLink: "/create-more-page"
  }
];

const routes: SamsRoute[] = [
  ...sideBarRoutes,
  {
    name: "Create Banner",
    path: "/create-banner",
    main: () => <Banner />
  },
  {
    name: "Update Banner",
    path: "/update-banner/:id",
    main: () => <Banner />
  },
  {
    name: "Add Winner",
    path: "/add-winner",
    main: () => <RaffleWinner />
  },
  {
    name: "Update Winner",
    path: "/update-winner/:id",
    main: () => <RaffleWinner />
  },
  {
    name: "Create Partnership",
    path: "/create-partnership",
    main: () => <ManagePartnership />
  },
  {
    name: "Update Partnership",
    path: "/update-partnership/:id",
    main: () => <ManagePartnership />
  },
  {
    name: "Create New Version",
    path: "/create-new-version",
    main: () => <NewVersion />
  },
  {
    name: "Update New Version",
    path: "/update-new-version/:id",
    main: () => <NewVersion />
  },
  {
    name: "Create Article",
    path: "/create-article",
    main: () => <ManageArticle />
  },
  {
    name: "Update Article",
    path: "/update-article/:id",
    main: () => <ManageArticle />
  },
  {
    name: "Create Slot",
    path: "/create-slot",
    main: () => <Slot />
  },
  {
    name: "Update Slot",
    path: "/update-slot/:id",
    main: () => <Slot />
  },
  {
    name: "Create Offer",
    path: "/create-offer",
    main: () => <ManageOffer />
  },
  {
    name: "Update Offer",
    path: "/update-offer/:id",
    main: () => <ManageOffer />
  },
  {
    name: "Create Product",
    path: "/create-product",
    main: () => <Product />
  },
  {
    name: "Update Product",
    path: "/update-product/:id",
    main: () => <Product />
  },
  {
    name: "Create More Page",
    path: "/create-more-page",
    main: () => <ManageMorePage />
  },
  {
    name: "Update More Page",
    path: "/update-more-page/:id",
    main: () => <ManageMorePage />
  }
];

export default routes;
