import { Slot } from "../../clients/services/sams/content/slot/types";
import { Status } from "../../clients/services/sams/types";

const formDataTemplate: Slot = {
  objectId: "",
  objectType: "",
  slot: "",
  startDate: "",
  endDate: "",
  status: Status.DRAFT
};

export default formDataTemplate;
