import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MockState {
  enabled: boolean;
  // TO DO: delete enabledNavigation when mock mode is fixed
  enabledNavigation: boolean;
}

const initialState: MockState = {
  enabled: false,
  enabledNavigation: false
};

const mockSlice = createSlice({
  name: "mock",
  initialState,
  reducers: {
    setMockMode: (state, action: PayloadAction<MockState>) => action.payload
  }
});

export const { setMockMode } = mockSlice.actions;

export default mockSlice.reducer;
