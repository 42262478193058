import { useQuery } from "react-query";

import { getLatestHistory } from "../../../clients/services/sams/content/contentHistory";
import { PostType } from "../../../clients/services/sams/types";

export function useGetLatestHistory() {
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.PROMOTION)
  );

  return {
    isLoadingHistory,
    historyData
  };
}
