import {
  OfferRedemptionMechanic,
  PromotionType
} from "../../../../../clients/services/sams/promotion/types";
import { SunProduct } from "../../../../../clients/services/sams/types";
import { FormDataInterface } from "../../../types";

export function useConditions(values: FormDataInterface) {
  const showCodeCollectStartDate =
    values.promotionType !== PromotionType.NONCODE &&
    values.promotionType !== PromotionType.DONATION;

  const showRedemptionStartDateTime =
    values.promotionType === PromotionType.REGULAR ||
    values.promotionType === PromotionType.NONCODE ||
    values.promotionType === PromotionType.DONATION;

  const showBookingCodeEndDate =
    values.promotionType === PromotionType.REGULAR &&
    values.redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE;

  const showRedemptionButtonText =
    values.promotionType === PromotionType.REGULAR ||
    values.promotionType === PromotionType.NONCODE ||
    values.promotionType === PromotionType.WALLET;

  const showResendButtonText =
    values.redemptionMechanic === OfferRedemptionMechanic.INTERNAL &&
    values.promotionType !== PromotionType.NONCODE &&
    values.sunProduct === SunProduct.SUN_SAVERS;

  const showRedemptionMechanic =
    values.promotionType !== PromotionType.NONCODE &&
    values.promotionType !== PromotionType.DONATION &&
    values.sunProduct !== SunProduct.SUN_MOBILE;

  const showMaximumRedemptions =
    values.promotionType !== PromotionType.COMPETITION &&
    values.promotionType !== PromotionType.REGULAR;

  const showFeeOnRedemption =
    values.promotionType === PromotionType.WALLET ||
    values.promotionType === PromotionType.REGULAR;

  const showTransactionHistoryLabel =
    values.promotionType === PromotionType.WALLET ||
    values.promotionType === PromotionType.DONATION ||
    values.promotionType === PromotionType.REGULAR;

  return {
    showCodeCollectStartDate,
    showRedemptionStartDateTime,
    showBookingCodeEndDate,
    showRedemptionButtonText,
    showResendButtonText,
    showRedemptionMechanic,
    showMaximumRedemptions,
    showFeeOnRedemption,
    showTransactionHistoryLabel
  };
}
