import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import {
  PartnershipResponse,
  Partnership,
  PartnershipListResponse,
  ListPartnershipsParams,
  PartnershipPositionsData
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createPartnership = async (partnership: Partnership) => {
  const url = `${baseUrl}/content/partnerships`;

  const response: AxiosResponse<PartnershipResponse> = await client.post(
    url,
    partnership
  );

  return response.data;
};

export const listPartnerships = async (params?: ListPartnershipsParams) => {
  const url = `${baseUrl}/content/partnerships`;

  const response: AxiosResponse<PartnershipListResponse[]> = await client.get(
    url,
    { params }
  );
  return response.data;
};

export const deletePartnership = async (id: string) => {
  const url = `${baseUrl}/content/partnerships/${id}`;

  await client.delete(url);
};

export const getPartnership = async (id: string) => {
  const url = `${baseUrl}/content/partnerships/${id}`;

  const response: AxiosResponse<PartnershipListResponse> = await client.get(
    url
  );
  return response.data;
};

export const updatePartnership = async (
  partnership: PartnershipListResponse
) => {
  const url = `${baseUrl}/content/partnerships/${partnership.id}`;

  const response: AxiosResponse<PartnershipListResponse> = await client.put(
    url,
    partnership
  );

  return response.data;
};

export const changePartnershipPosition = async (
  partnershipPositionsData: PartnershipPositionsData[]
) => {
  const url = `${baseUrl}/content/partnerships/update-position`;

  const response: AxiosResponse<PartnershipPositionsData[]> =
    await client.patch(url, partnershipPositionsData);

  return response.data;
};
