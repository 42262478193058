import * as yup from "yup";

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  features: yup.array().required("Add at least one feature"),
  platform: yup.string().required("Platform is required"),
  version: yup
    .string()
    .matches(/^\d+\.\d+\.\d+$/, "Version must be in the format x.x.x")
    .required("Version is required"),
  releaseDate: yup.date().required("Release Date is required"),
  status: yup.string().required("Status is required")
});

export default validationSchema;
