import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useQuery } from "react-query";

import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import {
  deleteNewVersion,
  listNewVersions
} from "../../clients/services/sams/content/newVersion";
import { NewVersionListResponse } from "../../clients/services/sams/content/newVersion/types";
import { PostType } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import TableComponent from "../TableComponent";

const NewVersionTable = () => {
  const { isLoading, data, error, refetch } = useQuery(
    ["listNewVersions"],
    () => listNewVersions()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.NEW_VERSION)
  );
  const rows: any = data?.map((newVersion: NewVersionListResponse) => {
    return {
      id: newVersion.id,
      title: newVersion.title,
      version: newVersion.version,
      status: newVersion.status,
      releaseDate: moment(newVersion.releaseDate).format("DD.MM.yyyy"),
      user: findLatestHistory(historyData, newVersion.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, newVersion.id)
        ? dateTimeFull(
            findLatestHistory(historyData, newVersion.id)?.createdDate
          )
        : "Created from service"
    };
  });

  const tableProperties = {
    itemName: "newVersion",
    actionLabel: "NewVersion",
    specialUrl: "new-version"
  };

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", minWidth: 170, flex: 1 },
    { field: "version", headerName: "Version", minWidth: 170, flex: 1 },
    {
      field: "releaseDate",
      headerName: "Release Date",
      minWidth: 170,
      flex: 1
    }
  ];

  return (
    <TableComponent<NewVersionListResponse>
      isLoading={isLoading || isLoadingHistory}
      error={error}
      refetch={refetch}
      rows={rows}
      columns={columns}
      testId="NewVersionTableTestID"
      createButton={{
        link: "/create-new-version",
        text: "Create New App Version"
      }}
      tableProperties={tableProperties}
      deleteFunction={deleteNewVersion}
    />
  );
};

export default NewVersionTable;
