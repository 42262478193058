import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import {
  createNewVersion,
  getNewVersion,
  updateNewVersion
} from "../../clients/services/sams/content/newVersion";
import { NewVersion } from "../../clients/services/sams/content/newVersion/types";
import useFormData from "../../hooks/useFormData";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import formDataTemplate from "./formDataTemplate";
import Transformer from "./transformer";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageNewVersion = () => {
  const transformer = new Transformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(transformer, getNewVersion, createNewVersion, updateNewVersion);
  const history = useHistory();

  const [onSuccess, onError] = useMutationHandler(
    `New Version ${isUpdateOperation ? "updated" : "created"} successfully!`,
    () => history.push("/new-versions")
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation<any, AxiosError<any>, NewVersion>(mutationFunction, {
    onSuccess,
    onError
  });

  const submitHandler = async (inputValues: NewVersion) => {
    await mutate(inputValues as any);
  };

  const formProperties = isUpdateOperation
    ? {
        title: "Update New App Version",
        actionLabel: "Update",
        formId: "updateNewVersion",
        actionTestId: "UpdateNewVersionTestId"
      }
    : {
        title: "Create New App Version",
        actionLabel: "Publish",
        formId: "createNewVersion",
        actionTestId: "CreateNewVersionTestId"
      };

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage simpleErrors={["Cannot get new version!"]} />
      </StyledError>
    );
  }

  if (isLoading) {
    return <CircularProgress size={24} data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        {...formProperties}
      />
    </>
  );
};

export default ManageNewVersion;
