import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import { BannerResponse, Banner, BannerListResponse } from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createBanner = async (banner: Banner) => {
  const url = `${baseUrl}/content/banners`;

  const response: AxiosResponse<BannerResponse> = await client.post(
    url,
    banner
  );
  return response.data;
};

export const listBanners = async () => {
  const url = `${baseUrl}/content/banners`;

  const response: AxiosResponse<BannerListResponse[]> = await client.get(url);
  return response.data;
};

export const deleteBanner = async (id: string) => {
  const url = `${baseUrl}/content/banners/${id}`;

  await client.delete(url);
};

export const getBanner = async (id: string) => {
  const url = `${baseUrl}/content/banners/${id}`;

  const response: AxiosResponse<BannerListResponse> = await client.get(url);
  return response.data;
};

export const updateBanner = async (banner: BannerListResponse) => {
  const url = `${baseUrl}/content/banners/${banner.id}`;

  const response: AxiosResponse<BannerListResponse> = await client.put(
    url,
    banner
  );
  return response.data;
};
