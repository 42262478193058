import moment from "moment";

import { Article } from "../../clients/services/sams/content/article/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";
import { formatDate } from "../utils/dateTimezoneFormatter";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: Article) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (formData: Article) => {
    return {
      ...formData,
      publishStartDate: formData.publishStartDate
        ? new Date(formData.publishStartDate).toISOString().slice(0, 16)
        : "",
      publishEndDate: formData.publishEndDate
        ? new Date(formData.publishEndDate).toISOString().slice(0, 16)
        : "",
      favouritesCta:
        formData.articleType === "FAVOURITES" ? formData.favouritesCta : null
    };
  };

  public modelToFormData = (modelData: Article) => {
    return {
      ...modelData,
      publishStartDate:
        modelData.publishStartDate !== "" && modelData.publishStartDate
          ? formatDate(modelData.publishStartDate).slice(0, 16)
          : "",
      publishEndDate:
        modelData.publishEndDate !== "" && modelData.publishEndDate
          ? formatDate(modelData.publishEndDate).slice(0, 16)
          : "",
      favouritesCta:
        modelData.articleType === "FAVOURITES" ? modelData.favouritesCta : null
    };
  };
}

export default Transformer;
