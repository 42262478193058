import { AxiosResponse } from "axios";

import { FormDataInterface } from "../../../../components/Offer/types";
import { config } from "../../../../config";
import staticConfig from "../../../../config/static";
import client from "../../base";

import { parseBookingCodesCsvFile } from "./parseBookingCodesCsvFile";
import {
  Attraction,
  BookingCodeSummary,
  GetPromotionsParams,
  MarketingPermissionData,
  OffersPositionsData,
  Promotion,
  PromotionBookingOfferData,
  PromotionBookingOfferDataResponse,
  PromotionRedemption,
  RegisteredPromotion
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const getRegisteredPromotions = async (cpn: string) => {
  const url = `${baseUrl}/promotion/registered-promotions?cpn=${cpn}`;

  const response: AxiosResponse<RegisteredPromotion[]> = await client.get(url);
  return response.data;
};

export const getPromotions = async (params?: GetPromotionsParams) => {
  const url = `${baseUrl}/promotion/promotions`;

  const response: AxiosResponse<Promotion[]> = await client.get(url, {
    params,
    timeout: config.environment === "local" ? 40000 : 16000
  });
  return response.data;
};

export const getPromotionRedemptions = async (cpn: string) => {
  const url = `${baseUrl}/promotion/promotion-redemptions?cpn=${cpn}`;

  const response: AxiosResponse<PromotionRedemption[]> = await client.get(url);
  return response.data;
};

export const optInPromotion = async (cpn: string, promotionCode: string) => {
  const url = `${baseUrl}/promotion/registered-promotions?cpn=${cpn}`;
  const payload = { promotionCode };

  const response: AxiosResponse<RegisteredPromotion> = await client.post(
    url,
    payload
  );

  return response.data;
};

export const getPromotion = async (promotionCode: string) => {
  const url = `${baseUrl}/promotion/promotions/${promotionCode}`;

  const response: AxiosResponse<RegisteredPromotion> = await client.get(url);

  return response.data;
};

export const createPromotion = async (promotion: FormDataInterface) => {
  const url = `${baseUrl}/promotion/promotions`;

  const response: AxiosResponse<RegisteredPromotion> = await client.post(
    url,
    promotion
  );

  return response.data;
};

export const updatePromotion = async (promotion: Promotion) => {
  const url = `${baseUrl}/promotion/promotions/${promotion.code}`;

  const response: AxiosResponse<Promotion> = await client.put(url, promotion);

  return response.data;
};

export const updatePromotions = async (promotions: Promotion[]) => {
  const url = `${baseUrl}/promotion/promotions`;

  const response: AxiosResponse<Promotion> = await client.put(url, promotions);

  return response.data;
};

export const changePromotionPosition = async (
  offersPositionsData: OffersPositionsData[]
) => {
  const url = `${baseUrl}/promotion/promotions`;

  const response: AxiosResponse<Promotion> = await client.patch(
    url,
    offersPositionsData
  );

  return response.data;
};

export const deletePromotion = async (promotionCode: string) => {
  const url = `${baseUrl}/promotion/promotions/${promotionCode}`;

  await client.delete(url);
};

export const getMarketingPermissionData = async (
  promotionCode: string
): Promise<MarketingPermissionData[]> => {
  const url = `${baseUrl}/promotion/marketing-permission-data/${promotionCode}`;
  const response = await client.get(url, { timeout: 25000 });

  return response.data;
};

export const downloadMarketingPermissionData = async (
  promotionCode: string
) => {
  const marketingData = await getMarketingPermissionData(promotionCode);

  const csvRows: MarketingPermissionData[] = [];
  marketingData.forEach((markPermData: MarketingPermissionData) => {
    const row: any = [];
    row.push(markPermData.firstName);
    row.push(markPermData.lastName);
    row.push(markPermData.email);
    csvRows.push(row.join(","));
  });

  const csvHeader = ["Firstname", "Lastname", "email"];
  const csvData = `${csvHeader.join(",")}\n${csvRows.join("\n")}`;

  const blob = new Blob([csvData], { type: "text/csv" });
  const dataUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUrl;
  downloadLink.download = "output.csv";

  document.body.appendChild(downloadLink);

  downloadLink.click();

  URL.revokeObjectURL(dataUrl);
};

export const shareMarketingDataSpreadsheet = async (
  emails: string[],
  marketingData: any
) => {
  const url = `${baseUrl}/promotion/share-marketing-data-spreadsheet`;
  const response = await client.post(url, { emails, marketingData });

  return response.data;
};

export const getAllBookingCodesWithPromotions = async (): Promise<
  PromotionBookingOfferData[]
> => {
  const url = `${baseUrl}/booking/codes`;
  const response = await client.get(url);

  return response.data;
};

export const uploadBookingCodes = async (
  promotionCode: string,
  bookingCodesCsvFile: File
): Promise<PromotionBookingOfferDataResponse> => {
  const url = `${baseUrl}/booking/codes`;

  const bookingCodes = await parseBookingCodesCsvFile(bookingCodesCsvFile);

  const response = await client.post(
    url,
    {
      promotionCode,
      bookingCodes
    },
    {
      timeout: 0
    }
  );

  return response.data;
};

export const getBookingCodeSummaries = async (
  promotionCode: string
): Promise<{ bookingCodeSummaries: BookingCodeSummary[] }> => {
  const url = `${baseUrl}/booking/codes/get-code-summaries/${promotionCode}`;

  const response = await client.get(url);

  return response.data;
};

export const listAttractions = async (): Promise<Attraction[]> => {
  const url = `${baseUrl}/attractions`;
  const response = await client.get(url);

  return response.data;
};

export const deleteBookingCodes = async (
  promotionCode: string,
  uploadDate: string
) => {
  const url = `${baseUrl}/booking/codes`;
  const response = await client.delete(url, {
    data: { promotionCode, uploadDate },
    timeout: 0
  });

  return response.data;
};
