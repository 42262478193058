import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { getHistoryForPost } from "../../clients/services/sams/content/contentHistory";
import { HistoryResponse } from "../../clients/services/sams/content/contentHistory/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { ErrorMessage } from "../ErrorMessage";

export type Rows = { user: string; createdDate: string }[];

const StyledDataGrid = styled(DataGrid)`
  border: none;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 6%);
  max-width: 100%;
  width: 400px;
`;
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 32px;
  height: 390px;
`;

const HistoryTable = ({
  postType,
  postId
}: {
  postType: string;
  postId: string;
}) => {
  const { isLoading, data, error } = useQuery(["getHistoryForPost"], () =>
    getHistoryForPost(postType, postId)
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  const rows: Rows | undefined = data?.map((history: HistoryResponse) => {
    return {
      id: history.id,
      user: history.user || "Unknown",
      createdDate: dateTimeFull(history.createdDate) || "Created from service"
    };
  });

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "Author",
      minWidth: 90,
      flex: 1
    },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1
    }
  ];

  return (
    <StyledContainer>
      <StyledDataGrid
        hideFooterSelectedRowCount={true}
        autoHeight={true}
        rows={rows?.reverse() || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5
            }
          }
        }}
      />
    </StyledContainer>
  );
};

export default HistoryTable;
