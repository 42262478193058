import {
  getMarketingPermissionData,
  shareMarketingDataSpreadsheet
} from "../../clients/services/sams/promotion";
import { MarketingPermissionData } from "../../clients/services/sams/promotion/types";

export const createAndShareMarketingDataSpreadsheet = async (
  promotionCode: string,
  emails: string[]
) => {
  const marketingData = await getMarketingPermissionData(promotionCode);
  const csvHeader = ["Firstname", "Lastname", "email"];
  const mappedRows = marketingData.map((row: MarketingPermissionData) => [
    row.firstName,
    row.lastName,
    row.email
  ]);
  const mappedMarketingData = [csvHeader, ...mappedRows];

  await shareMarketingDataSpreadsheet(emails, mappedMarketingData);
};
