import styled from "@emotion/styled";
import { Delete } from "@mui/icons-material";
import {
  TextField,
  Grid,
  Typography,
  MenuItem,
  FormLabel,
  FormGroup,
  TextareaAutosize,
  Button,
  Tooltip
} from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { FC } from "react";

import { Feature } from "../../../clients/services/sams/content/newVersion/types";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import SelectField from "../../SelectField";
import StatusSelector from "../../StatusSelector";
import { mapPlatform, Platform } from "../types";

const Container = styled.div`
  padding-top: 15px;
`;

const InputField = styled(TextField)`
  margin-top: 8px;
`;

const FeatureTextField = styled(TextField)`
  margin-bottom: 15px;
`;

const DateInputField = styled(TextField)`
  margin-top: 16px;
  width: 47%;
`;

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const FeatureFormGroup = styled(FormGroup)`
  display: flex;
  margin: 16px 10px 16px 0;
  padding: 0 16px 0 10px;
  width: 100%;
  border-right: 1px solid #e5e4e4;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  margin: 5px 0;
  background: #f5f5f5;
  border: 1px solid #e5e4e4;
`;

const StyledFeatureTextArea = styled(TextareaAutosize)`
  width: 98%;
  min-height: 100px;
  margin: 8px 0;
`;

export const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  min-width: auto;
  span {
    margin: 0 auto;
  }
  border-radius: 50%;
  &:hover {
    background-color: #c4d9f5;
  }
`;

interface FormProps {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form: FC<FormProps> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting
}) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <Container data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <FormikProvider value={formik}>
        <form id={formId} onSubmit={formik.handleSubmit}>
          {formik.submitCount !== 0 && (
            <ErrorMessage formValidationErrors={formik.errors} />
          )}
          <Grid container>
            <Grid container item xs={8}>
              <InputField
                fullWidth
                id="title"
                name="title"
                label="Title"
                variant="standard"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <StyledFormGroup>
                <FormLabel component="legend">Features</FormLabel>
                <FieldArray
                  name="features"
                  render={(arrayHelpers) => (
                    <>
                      {formik.values.features &&
                        formik.values.features.length > 0 &&
                        formik.values.features.map(
                          (feature: Feature, index: number) => (
                            <FeatureItem key={index}>
                              <FeatureFormGroup>
                                <FeatureTextField
                                  fullWidth
                                  id={`feature_title_${index}`}
                                  name={`features.${index}.title`}
                                  label="Title"
                                  variant="standard"
                                  value={feature.title}
                                  onChange={formik.handleChange}
                                />
                                <FormLabel component="legend">
                                  Description
                                </FormLabel>
                                <StyledFeatureTextArea
                                  id={`feature_description_${index}`}
                                  name={`features.${index}.description`}
                                  value={feature.description}
                                  onChange={formik.handleChange}
                                  maxRows={4}
                                />
                              </FeatureFormGroup>
                              <Tooltip title="Delete">
                                <StyledButton
                                  startIcon={<Delete />}
                                  size="medium"
                                  variant="text"
                                  onClick={() => arrayHelpers.remove(index)}
                                  data-testid={`delete-feature-${index}`}
                                ></StyledButton>
                              </Tooltip>
                            </FeatureItem>
                          )
                        )}
                      <Button
                        color="primary"
                        onClick={() => {
                          arrayHelpers.push({
                            id: 0,
                            title: "",
                            description: ""
                          });
                        }}
                      >
                        Add Feature
                      </Button>
                    </>
                  )}
                />
              </StyledFormGroup>
              <SelectField
                form={formik}
                label={"Platform"}
                labelID={"platform"}
                defaultValue={formik.values.platform}
                name={"platform"}
              >
                {Object.values(Platform).map((platform) => (
                  <MenuItem key={platform} value={platform}>
                    {mapPlatform.get(platform)}
                  </MenuItem>
                ))}
              </SelectField>
              <InputField
                fullWidth
                id="version"
                name="version"
                label="Version"
                variant="standard"
                value={formik.values.version}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.version && Boolean(formik.errors.version)}
                helperText={formik.touched.version && formik.errors.version}
              />
              <DateInputField
                id="releaseDate"
                name="releaseDate"
                label="Release Date"
                type="date"
                variant="standard"
                value={formik.values.releaseDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.releaseDate &&
                  Boolean(formik.errors.releaseDate)
                }
                helperText={
                  formik.touched.releaseDate && formik.errors.releaseDate
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid container item xs={4}>
              <StatusSelector
                isSubmitting={isSubmitting}
                form={formik}
                actionLabel={actionLabel}
                testId={actionTestId}
              />
              {values.id ? (
                <HistoryTable postType={"new-version"} postId={values.id} />
              ) : null}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
};

export default Form;
