import { Button, Checkbox, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  createProduct,
  getProductByCodeAndAttraction,
  getProductsByAttraction
} from "../../../clients/services/sams/merlin";
import {
  AdmissionType,
  MerlinProduct,
  ProductType,
  Status,
  Variant
} from "../../../clients/services/sams/merlin/types";
import { setNotification } from "../../../state/reducers/notification";
import { ErrorMessage } from "../../ErrorMessage";
import { generateAttractionProductSlug } from "../../utils/generateAttractionProductSlug";

export const productAdmissionType = new Map<string, AdmissionType>([
  ["timed", AdmissionType.TIMED],
  ["open", AdmissionType.OPEN],
  ["dated", AdmissionType.DATED]
]);

const createProducts = (
  products: MerlinProduct[],
  dispatch: any,
  history: any
) => {
  let errors: any = [];
  products.forEach(async (product: MerlinProduct) => {
    const merlinProduct = await getProductByCodeAndAttraction(
      product.productcode,
      product.attractionid
    );
    const merlinVariants = merlinProduct.productvariants?.map(
      (variant, index) => {
        return {
          description: variant.variantname,
          grossAmount: parseFloat(variant.grossamount) || 0,
          netAmount: parseFloat(variant.netamount) || 0,
          id: index,
          mandatoryQuantity: 0,
          maxOrderLimit: 1,
          merlinVariantId: variant.variantid,
          minOrderLimit: 1,
          price: 0
        };
      }
    );
    const transformedProduct = {
      attraction: {
        merlinAttractionId: product.attractionid,
        description: product.eventdescription,
        id: product.eventid
      },
      merlinAttractionId: product.attractionid,
      merlinProductCode: product.productcode,
      admissionType: productAdmissionType.get(product.admissiontype)!,
      bookingFee: {
        amount: 0
      },
      description: null,
      extraInformation: null,
      images: {
        image: { original: "" },
        thumbnail_image: { original: "" }
      },
      label: "",
      productType: ProductType.OFFER,
      status: Status.DRAFT,
      sell: null,
      shortTitle: null,
      slug: generateAttractionProductSlug(product.productname),
      termsAndConditions: null,
      title: product.eventdescription,
      variants: merlinVariants as Variant[],
      xcode: "",
      eventId: product.eventid,
      published: false
    };

    try {
      await createProduct(transformedProduct);
    } catch (e) {
      errors = [...errors, e];
    }
    if (errors.length === 0) {
      dispatch(
        setNotification({
          message: "Products are successfully created!",
          severity: "success"
        })
      );
      history.push("/products");
    } else {
      dispatch(
        setNotification({
          message: "Could not create products!",
          severity: "error"
        })
      );
    }
  });
};

const ProductsTable = ({
  merlinAttractionId
}: {
  merlinAttractionId: string;
}) => {
  const { data, error, isFetching, refetch } = useQuery(
    ["getProductsByAttraction"],
    () => getProductsByAttraction(merlinAttractionId)
  );
  useEffect(() => {
    refetch();
  }, [merlinAttractionId]);

  const [markedProducts, setMarketProducts] = useState([] as any);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setMarketProducts([]);
  }, []);

  const handleChangeMarkedProducts = (checkboxValue: boolean, product: any) => {
    if (checkboxValue) {
      setMarketProducts([...markedProducts, product]);
    } else {
      setMarketProducts(
        markedProducts.filter(
          (markedProduct: any) =>
            JSON.stringify(markedProduct) !== JSON.stringify(product)
        )
      );
    }
  };

  const rows: any = data?.products.map((product) => ({
    id: product.productcode,
    productName: product.productname,
    description: product.eventdescription,
    product
  }));

  const columns: GridColDef[] = [
    {
      field: "check",
      headerName: "",
      flex: 1,
      maxWidth: 60,
      renderCell: (params: GridRenderCellParams) => (
        <Checkbox
          data-testid={"ProductCheckBoxID"}
          onChange={(e) =>
            handleChangeMarkedProducts(e.target.checked, params.row.product)
          }
        />
      )
    },
    { field: "id", headerName: "Product Code", minWidth: 170, flex: 1 },
    {
      field: "productName",
      headerName: "Product Name",
      minWidth: 170,
      flex: 1
    },
    {
      field: "description",
      headerName: "Event Description",
      minWidth: 340,
      flex: 1
    }
  ];

  if (isFetching) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  return (
    <div data-testid={"ProductTableTestID"}>
      <Button
        data-testid={"CreateSelectedProductsButtonID"}
        color="primary"
        variant="contained"
        onClick={() => createProducts(markedProducts, dispatch, history)}
        style={{ margin: "24px 0px" }}
      >
        Create Selected Products
      </Button>
      <DataGrid
        hideFooterSelectedRowCount={true}
        autoHeight={true}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
      />
    </div>
  );
};

export default ProductsTable;
