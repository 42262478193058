import { CircularProgress } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";

const Dashboard = () => {
  const { authState } = useOktaAuth();

  if (!authState || authState.isPending) {
    return <CircularProgress data-testid={"loading"} />;
  }

  return <h2>Dashboard</h2>;
};

export default Dashboard;
