import { OfferRedemptionMechanic } from "../../../clients/services/sams/promotion/types";

type IsTestUrlButtonDisabled = (
  testType: TestType,
  redemptionMechanic: OfferRedemptionMechanic,
  offerSlug?: string,
  redemptionUrl?: string,
  testCPN?: string,
  testEmail?: string,
  testXCODE?: string
) => boolean;

export type TestType = "GFM" | "STRETCH";

export const isTestUrlButtonDisabled: IsTestUrlButtonDisabled = (
  testType: TestType,
  redemptionMechanic: OfferRedemptionMechanic,
  offerSlug?: string,
  redemptionUrl?: string,
  testCPN?: string,
  testEmail?: string,
  testXCODE?: string
) =>
  // Disable the button if its an INTERNAL promotion and either the redemptionURL, cpn or the email fields are blank.
  (redemptionMechanic === OfferRedemptionMechanic.INTERNAL &&
    (!testCPN || !testEmail || !redemptionUrl)) ||
  (redemptionMechanic === OfferRedemptionMechanic.EXTERNAL &&
  testType === "STRETCH"
    ? // Disable the button if its an STRETCH promotion and either the slug, cpn or the redemptionUrl fields are blank. redemptionUrl should contain @STRETCH_CHECKSUM@ to validate the STRETCH url.
      !offerSlug || !testCPN || !testXCODE || !redemptionUrl
    : // Disable the button if its an GFM promotion and either the slug, cpn, email or the redemptionUrl fields are blank.
      !offerSlug || !testCPN || !testEmail || !redemptionUrl) ||
  redemptionMechanic === OfferRedemptionMechanic.PRODUCT ||
  redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE;
