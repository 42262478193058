import { useQuery } from "react-query";

import { getPromotions } from "../../../clients/services/sams/promotion";

export function useGetPromotions() {
  const {
    isLoading: isLoadingPromotions,
    data: promotionsData,
    error: promotionsError,
    refetch: refetchPromotions
  } = useQuery(["getPromotions"], () => getPromotions());

  return {
    isLoadingPromotions,
    promotionsData,
    promotionsError,
    refetchPromotions
  };
}
