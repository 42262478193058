import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { AxiosError } from "axios";

import { SaversError } from "../../clients/services/sams/error/types";

const StyledAlert = styled(Alert)`
  margin: 10px 0px;
`;
interface Props {
  axiosErrors?: (AxiosError<SaversError> | null)[];
  simpleErrors?: string[];
  formValidationErrors?: any;
}

export const ErrorMessage = ({
  axiosErrors,
  simpleErrors,
  formValidationErrors
}: Props) => (
  <>
    {axiosErrors
      ?.filter((error) => error)
      .map((error, i) => (
        <Alert
          severity="error"
          key={error?.response?.data.errorMessage}
          data-testid={`error-message-${i}`}
          style={{ marginTop: "12px" }}
        >
          {[error?.response?.data.errorCode, error?.response?.data.errorMessage]
            .filter((word) => word)
            .join(": ") || axiosErrors[0]?.message}
        </Alert>
      ))}
    {simpleErrors
      ?.filter((error) => error)
      .map((error, i) => (
        <Alert severity="error" key={error} data-testid={`error-message-${i}`}>
          {error}
        </Alert>
      ))}
    {formValidationErrors &&
      Object.keys(formValidationErrors).map((key: string) => (
        <StyledAlert
          severity="error"
          key={key}
          data-testid={`error-message-${key}`}
        >
          {formValidationErrors[key]}
        </StyledAlert>
      ))}
  </>
);
