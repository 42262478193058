import { Tab, Tabs } from "@mui/material";
import { FormikProps } from "formik";

import { OfferRedemptionMechanic } from "../../../clients/services/sams/promotion/types";
import { FormDataInterface } from "../types";

import AttractionInfoPanel from "./AttractionInfoPanel";
import ContentPanel from "./ContentPanel";
import InfoPanel from "./InfoPanel";
import MarketingPermissionsPanel from "./MarketingPermissionsPanel";
import RedemptionSettingsPanel from "./RedemptionSettingsPanel";
import StatusesPanel from "./StatusesPanel";

interface Props {
  value: number;
  formik: FormikProps<FormDataInterface>;
  isUpdateOperation: boolean;
  testUrlErrorMsg: string;
  isLoadingTestRedemptionEmailMutation: boolean;
  isLoadingGetRedemptionUrlMutation: boolean;
  setTestUrlErrorMsg: (newValue: string) => void;
  testRedemptionEmailMutation: any;
  getGFMRedemptionUrlMutation: any;
  getStretchRedemptionUrlMutation: any;
  setValue: (newValue: number) => void;
}

const TabsMenu: React.FC<Props> = ({
  value,
  formik,
  isUpdateOperation,
  testUrlErrorMsg,
  isLoadingTestRedemptionEmailMutation,
  isLoadingGetRedemptionUrlMutation,
  setTestUrlErrorMsg,
  testRedemptionEmailMutation,
  getGFMRedemptionUrlMutation,
  getStretchRedemptionUrlMutation,
  setValue
}) => {
  const testGFMRedemptionHandler = async (
    redemptionMechanic: OfferRedemptionMechanic,
    cpn: string,
    redemptionUrl: string,
    email: string,
    promotionSlug: string
  ) => {
    setTestUrlErrorMsg("");
    await (redemptionMechanic === OfferRedemptionMechanic.INTERNAL
      ? testRedemptionEmailMutation.mutate({
          cpn,
          redemptionUrl,
          email
        })
      : getGFMRedemptionUrlMutation.mutate({
          cpn,
          redemptionUrl,
          promotionSlug
        }));
  };

  const testStretchRedemptionHandler = async (
    redemptionUrl: string,
    cpn: string,
    xcode: string
  ) => {
    setTestUrlErrorMsg("");
    await getStretchRedemptionUrlMutation.mutate({
      cpn,
      redemptionUrl,
      xcode
    });
  };

  const handleTabChange = (_: any, newValue: number) => {
    setValue(newValue);
  };
  /* eslint-disable no-param-reassign */
  const changeMarketingPermissionFields = (tabIndex?: number) => {
    formik.values.showMarketingPermissions = false;
    formik.values.partnerName = "";
    formik.values.privacyPolicyUrl = "";
    if (tabIndex && tabIndex === 4) {
      return setValue(0);
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="Offer Sections"
      >
        <Tab label="Info" data-testid={"InfoTab"} />
        <Tab label="Redemption Settings" data-testid={"SettingsTab"} />
        <Tab label="Copy" data-testid={"CopyTab"} />
        <Tab label="Offer Statuses" data-testid={"OfferStatusesTab"} />
        {formik.values.promotionType !== "REGULAR" &&
        formik.values.promotionType !== "COMPETITION" ? (
          changeMarketingPermissionFields(value)
        ) : (
          <Tab
            label="Marketing Permissions"
            data-testid={"MarketingPermissionsTab"}
          />
        )}
        {formik.values.redemptionMechanic === "BOOKING_CODE" && (
          <Tab label="Attraction info" data-testid={"AttractionInfoTab"} />
        )}
      </Tabs>
      <InfoPanel
        formik={formik}
        value={value}
        isUpdateOperation={isUpdateOperation}
      />
      <RedemptionSettingsPanel
        formik={formik}
        value={value}
        testUrlErrorMsg={testUrlErrorMsg}
        testGFMRedemptionHandler={testGFMRedemptionHandler}
        testStretchRedemptionHandler={testStretchRedemptionHandler}
        isLoadingTestRedemptionEmailMutation={
          isLoadingTestRedemptionEmailMutation
        }
        isLoadingGetRedemptionUrlMutation={isLoadingGetRedemptionUrlMutation}
        setTestUrlErrorMsg={setTestUrlErrorMsg}
      />
      <ContentPanel formik={formik} value={value} />
      <StatusesPanel formik={formik} value={value} />
      <MarketingPermissionsPanel formik={formik} value={value} />
      <AttractionInfoPanel formik={formik} value={value} />
    </>
  );
};

export default TabsMenu;
