import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { listProducts } from "../../clients/services/sams/merlin";
import {
  createPromotion,
  getPromotion,
  updatePromotion
} from "../../clients/services/sams/promotion";
import { OfferRedemptionMechanic } from "../../clients/services/sams/promotion/types";
import useFormData from "../../hooks/useFormData";
import { setNotification } from "../../state/reducers/notification";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import formDataTemplate from "./formDataTemplate";
import { useOfferMutationHandler } from "./hooks";
import Transformer from "./transformer";
import { FormDataInterface } from "./types";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageOffer = () => {
  const transformer = new Transformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(transformer, getPromotion, createPromotion, updatePromotion);
  const history = useHistory();
  const dispatch = useDispatch();
  const onError = useOfferMutationHandler();

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation(mutationFunction, {
    onError
  });

  const submitHandler = async (inputValues: FormDataInterface) => {
    if (
      inputValues.redemptionMechanic === OfferRedemptionMechanic.PRODUCT &&
      isUpdateOperation
    ) {
      const res = await listProducts(inputValues.code);
      const hasProductOfTypeOffer = res.some(
        (product) =>
          product.productType === "OFFER" && product.published === true
      );
      mutate(
        transformer.formDataToModel(
          inputValues,
          isUpdateOperation,
          hasProductOfTypeOffer
        ) as any,
        {
          onSuccess: async () => {
            dispatch(
              setNotification({
                message: `${
                  hasProductOfTypeOffer
                    ? "Offer updated successfully"
                    : "Offer saved as Draft. You must link a product to this offer to publish it!"
                }`,
                severity: `${hasProductOfTypeOffer ? "success" : "warning"}`
              })
            );
            history.push("/offers");
          }
        }
      );
    } else if (
      inputValues.redemptionMechanic === OfferRedemptionMechanic.PRODUCT &&
      isUpdateOperation === false
    ) {
      mutate(
        transformer.formDataToModel(inputValues, isUpdateOperation) as any,
        {
          onSuccess: async () => {
            dispatch(
              setNotification({
                message:
                  "Offer saved as Draft. You must link a product to this offer to publish it!",
                severity: "warning"
              })
            );
            history.push("/offers");
          }
        }
      );
    } else {
      mutate(
        transformer.formDataToModel(inputValues, isUpdateOperation) as any,
        {
          onSuccess: async () => {
            dispatch(
              setNotification({
                message: `Offer ${
                  isUpdateOperation ? "updated" : "created"
                } successfully!`,
                severity: "success"
              })
            );
            history.push("/offers");
          }
        }
      );
    }
  };

  const formProperties = isUpdateOperation
    ? {
        title: "Update Offer",
        actionLabel: "Update",
        actionTestId: "updateOfferTestID",
        formId: "updateOffer"
      }
    : {
        title: "Create Offer",
        actionLabel: "Publish",
        actionTestId: "createOfferTestID",
        formId: "createOffer"
      };

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage simpleErrors={["Cannot get offer!"]} />
      </StyledError>
    );
  }

  if (isLoading) {
    return <CircularProgress size={24} data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        isUpdateOperation={isUpdateOperation}
        {...formProperties}
      />
    </>
  );
};

export default ManageOffer;
