import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { setMockMode } from "../../state/reducers/mock";
import { setSecurity } from "../../state/reducers/security";
import { setUser } from "../../state/reducers/user";
import { isPreProdEnvironment } from "../../util/environment";
import Login from "../Login";
import Logout from "../Logout";

const StyledAppBar = styled(AppBar)`
  background-color: #0c2133;
  box-shadow: none;
  z-index: 1201;
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const ToolbarLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ToolbarRight = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  padding: 10px;
`;

const Title = styled(Typography)`
  display: none;
  @media (min-width: 420px) {
    display: block;
  }
`;

const SamsAppBar = ({ handleDrawerToggle }: any) => {
  // TO DO: change enabledNavigation to enabled when mock mode is fixed
  const isMockMode = useAppSelector((state) => state.mock.enabledNavigation);
  const environment = useAppSelector((state) => state.config?.environment);
  const dispatch = useAppDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      dispatch(setSecurity({ token: authState.accessToken?.accessToken }));
      oktaAuth.token.getUserInfo().then((info) => {
        dispatch(setUser({ email: info.email }));
      });
    }
  }, [authState, oktaAuth]);

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar>
        <ToolbarLeft>
          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={handleDrawerToggle}
            sx={{
              marginRight: 3
            }}
            style={{
              marginLeft: -6
            }}
          >
            <MenuIcon />
          </IconButton>
          <Title variant="h6" noWrap>
            SAMS
          </Title>
        </ToolbarLeft>
        <ToolbarRight>
          {isPreProdEnvironment(environment!) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isMockMode}
                    value={isMockMode}
                    onChange={() =>
                      dispatch(
                        setMockMode({
                          enabledNavigation: !isMockMode,
                          // TO DO: clean when mock mode is done
                          enabled: false
                        })
                      )
                    }
                    data-testid="mock-mode-switch"
                  />
                }
                label="Mock"
              />
            </FormGroup>
          )}
          <Divider>|</Divider>
          {authState && authState.isAuthenticated ? <Logout /> : <Login />}
        </ToolbarRight>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default SamsAppBar;
