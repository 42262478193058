export enum Status {
  DRAFT = "DRAFT",
  PREVIEW = "PREVIEW",
  PUBLISHED = "PUBLISHED",
  DELETED = "DELETED"
}

export enum SunProduct {
  SUN_MOBILE = "SUN_MOBILE",
  SUN_SAVERS = "SUN_SAVERS",
  MEMBERS_ENCLOSURE = "MEMBERS_ENCLOSURE",
  SUN_CLUB = "SUN_CLUB"
}

export enum ArticleType {
  STANDARD = "STANDARD",
  FAVOURITES = "FAVOURITES"
}

export enum PostType {
  ARTICLE = "article",
  BANNER = "banner",
  NEW_VERSION = "new-version",
  PARTNERSHIP = "partnership",
  PRODUCT = "product",
  RAFFLE_WINNER = "raffle-winner",
  SLOT = "slot",
  PROMOTION = "promotion"
}

export enum OfferStatus {
  FUTURE = "Future",
  ACTIVE = "Active",
  EXPIRED = "Expired"
}
