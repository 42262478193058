export enum Platform {
  ALL = "all",
  IOS = "ios",
  ANDROID = "android",
  WEB = "web"
}

export const mapPlatform = new Map<Platform, string>([
  [Platform.ALL, "All"],
  [Platform.IOS, "iOS Only"],
  [Platform.ANDROID, "Android Only"],
  [Platform.WEB, "Web Only"]
]);
