import styled from "@emotion/styled";
import {
  MenuItem,
  TextField,
  Grid,
  Typography,
  Tabs,
  Tab,
  FormLabel,
  FormGroup
} from "@mui/material";
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikProvider,
  useFormik
} from "formik";
import { FC, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { ProductType } from "../../../clients/services/sams/merlin/types";
import {
  OfferStatus,
  Promotion
} from "../../../clients/services/sams/promotion/types";
import { imageResizeMessages } from "../../../constants/imageResizeMessages";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import ImageSelection from "../../ImageSelection";
import RichContentEditor from "../../RichContentEditor";
import SelectField from "../../SelectField";
import StatusSelector from "../../StatusSelector";
import TabPanel from "../../TabPanel";
import { FormDataInterface } from "../types";

import VariantTable from "./VariantTable";
import { setImageFieldsValues } from "./utils";

const CreateProductContainer = styled.div`
  padding-top: 15px;
`;
const CustomInputField = styled(TextField)`
  margin-top: 24px;
`;
const StyledFormGroup = styled(FormGroup)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const StyledLabel = styled.p`
  color: rgba(0, 0, 0, 0.6);
`;

interface FormInterface {
  values: any;
  validationSchema: any;
  onSubmit: (values: FormDataInterface) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
  offersData: Promotion[];
}

const Form: FC<FormInterface> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  offersData,
  isSubmitting
}) => {
  const [value, setValue] = useState(0);
  const handleTabChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <CreateProductContainer data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <FormikProvider value={formik}>
        <form
          id={formId}
          onSubmit={formik.handleSubmit}
          onBlur={formik.handleBlur}
        >
          {formik.submitCount !== 0 && (
            <ErrorMessage formValidationErrors={formik.errors} />
          )}
          <Grid container>
            <Grid container item xs={8}>
              <SelectField
                form={formik}
                label={"Offer"}
                labelID={"xcode"}
                defaultValue={
                  offersData?.find(
                    (offer: Promotion) => offer.code === formik.values.xcode
                  )?.name
                }
                name={"xcode"}
              >
                {offersData
                  ?.filter((o) => o.status !== OfferStatus.EXPIRED)
                  .map((offer) => (
                    <MenuItem key={offer.code} value={offer.code}>
                      {offer.name}
                    </MenuItem>
                  ))}
              </SelectField>
              <CustomInputField
                fullWidth
                id="merlinAttractionId"
                name="merlinAttractionId"
                label="Merlin Attraction ID"
                disabled
                variant="standard"
                value={formik.values.merlinAttractionId}
                onChange={formik.handleChange}
                error={
                  formik.touched.merlinAttractionId &&
                  Boolean(formik.errors.merlinAttractionId)
                }
                helperText={
                  formik.touched.merlinAttractionId &&
                  formik.errors.merlinAttractionId
                }
              />
              <CustomInputField
                fullWidth
                id="merlinProductCode"
                name="merlinProductCode"
                label="Product Code"
                disabled
                variant="standard"
                value={formik.values.merlinProductCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.merlinProductCode &&
                  Boolean(formik.errors.merlinProductCode)
                }
                helperText={
                  formik.touched.merlinProductCode &&
                  formik.errors.merlinProductCode
                }
              />
              <StyledLabel>Main Image</StyledLabel>
              <ImageSelection
                id="images.image.original"
                value={formik.values.images.image.original}
                imageResizeMessage={imageResizeMessages.product.mainImage}
                error={
                  formik.touched.images?.image?.original &&
                  Boolean(formik.errors.images?.image?.original)
                }
                helperText={
                  formik.touched.images?.image?.original &&
                  formik.errors.images?.image?.original
                }
                btnText={
                  formik.values.images.image.original.length !== 0
                    ? "Select another image"
                    : "Select an image"
                }
                setFieldValue={formik.setFieldValue}
                isImageSelectionDisabled={!formik.values.title}
                isImageSelected={
                  formik.values.images.image.original.length !== 0
                }
                postType="product"
                imageUploadId="images.image.original"
                imageToUpload={formik.values.images.image.original}
                postHeadline={formik.values.title}
                setImageFieldsValues={setImageFieldsValues(formik)}
                imageType="main"
              />
              {formik.values.productType === ProductType.UPSELL && (
                <>
                  <StyledLabel>Thumbnail Image</StyledLabel>
                  <ImageSelection
                    id="images.thumbnail_image.original"
                    value={formik.values.images?.thumbnail_image?.original}
                    imageResizeMessage={
                      imageResizeMessages.product.thumbnailImage
                    }
                    error={
                      formik.touched.images?.thumbnail_image?.original &&
                      Boolean(formik.errors.images?.thumbnail_image?.original)
                    }
                    helperText={
                      formik.touched.images?.thumbnail_image?.original &&
                      formik.errors.images?.thumbnail_image?.original
                    }
                    btnText={
                      formik.values.images?.thumbnail_image?.original.length !==
                      0
                        ? "Select another image"
                        : "Select an image"
                    }
                    setFieldValue={formik.setFieldValue}
                    isImageSelectionDisabled={!formik.values.title}
                    isImageSelected={
                      formik.values.images?.thumbnail_image?.original.length !==
                      0
                    }
                    postType="product"
                    imageUploadId="images.thumbnail_image.original"
                    imageToUpload={
                      formik.values.images?.thumbnail_image?.original
                    }
                    postHeadline={formik.values.title}
                    setImageFieldsValues={setImageFieldsValues(formik)}
                    imageType="thumbnail"
                  />
                </>
              )}
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="Product Sections"
              >
                <Tab label="Info" data-testid="infoTab" />
                <Tab label="Copy" data-testid="copyTab" />
                <Tab label="Variants" data-testid="variantsTab" />
                <Tab label="Payment" data-testid="paymentTab" />
              </Tabs>
              <TabPanel value={value} index={0}>
                <SelectField
                  form={formik}
                  label={"Product Type"}
                  labelID={"productType"}
                  defaultValue={formik.values.productType}
                  name={"productType"}
                >
                  {Object.values(ProductType).map((productType) => (
                    <MenuItem key={productType} value={productType}>
                      {productType}
                    </MenuItem>
                  ))}
                </SelectField>
                <CustomInputField
                  fullWidth
                  id="title"
                  name="title"
                  label="Headline"
                  variant="standard"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
                {formik.values.productType === ProductType.UPSELL && (
                  <>
                    <CustomInputField
                      fullWidth
                      id="shortTitle"
                      name="shortTitle"
                      label="Short Headline"
                      variant="standard"
                      value={formik.values.shortTitle}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.shortTitle &&
                        Boolean(formik.errors.shortTitle)
                      }
                      helperText={
                        formik.touched.shortTitle && formik.errors.shortTitle
                      }
                    />
                    <CustomInputField
                      fullWidth
                      id="sell"
                      name="sell"
                      label="Sell"
                      variant="standard"
                      value={formik.values.sell}
                      onChange={formik.handleChange}
                      error={formik.touched.sell && Boolean(formik.errors.sell)}
                      helperText={formik.touched.sell && formik.errors.sell}
                    />
                  </>
                )}
                <CustomInputField
                  fullWidth
                  id="slug"
                  name="slug"
                  label="Product Slug"
                  variant="standard"
                  value={formik.values.slug}
                  onChange={formik.handleChange}
                  error={formik.touched.slug && Boolean(formik.errors.slug)}
                  helperText={formik.touched.slug && formik.errors.slug}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {formik.values.productType === ProductType.UPSELL && (
                  <>
                    <RichContentEditor
                      id="description"
                      label="Description"
                      value={formik.values.description || ""}
                      onChange={formik.setFieldValue}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                    <RichContentEditor
                      id="termsAndConditions"
                      label="Terms and Conditions"
                      value={formik.values.termsAndConditions || ""}
                      onChange={formik.setFieldValue}
                      error={
                        formik.touched.termsAndConditions &&
                        Boolean(formik.errors.termsAndConditions)
                      }
                      helperText={
                        formik.touched.termsAndConditions &&
                        formik.errors.termsAndConditions
                      }
                    />
                  </>
                )}
                <RichContentEditor
                  id="extraInformation"
                  label="How to Use This Offer"
                  value={formik.values.extraInformation}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.extraInformation &&
                    Boolean(formik.errors.extraInformation)
                  }
                  helperText={
                    formik.touched.extraInformation &&
                    formik.errors.extraInformation
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <StyledFormGroup>
                  <FormLabel component="legend">Variants</FormLabel>
                  <FieldArray
                    name="variants"
                    render={(arrayHelpers: FieldArrayRenderProps) => (
                      <VariantTable arrayHelpers={arrayHelpers} />
                    )}
                  />
                </StyledFormGroup>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <CustomInputField
                  fullWidth
                  id="bookingFee"
                  name="bookingFee"
                  label="Booking Fee"
                  variant="standard"
                  value={formik.values.bookingFee}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.bookingFee &&
                    Boolean(formik.errors.bookingFee)
                  }
                  helperText={
                    formik.touched.bookingFee && formik.errors.bookingFee
                  }
                />
              </TabPanel>
            </Grid>
            <Grid container item xs={4}>
              <StatusSelector
                isSubmitting={isSubmitting}
                form={formik}
                fieldName="status"
                actionLabel={actionLabel}
                testId={actionTestId}
              />
              {values.id ? (
                <HistoryTable postType={"product"} postId={values.id} />
              ) : null}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </CreateProductContainer>
  );
};

export default Form;
