import styled from "@emotion/styled";
import {
  FormControlLabel,
  FormLabel,
  Switch,
  TextareaAutosize
} from "@mui/material";
import { FormikProps } from "formik";

import TabPanel from "../../../TabPanel";
import { FormDataInterface } from "../../types";
import {
  InputField,
  StyledErrorMessage,
  StyledFormGroup
} from "../commonStyledComponents";

interface Props {
  formik: FormikProps<FormDataInterface>;
  value: number;
}

const TextArea = styled(InputField)`
  margin-top: 20px;
`;

const MarketingPermissionsPanel: React.FC<Props> = ({ formik, value }) => {
  return (
    <TabPanel value={value} index={4}>
      <StyledFormGroup>
        <FormLabel component="legend">Capture Marketing Permissions</FormLabel>
        <FormControlLabel
          style={{ pointerEvents: "none" }}
          control={
            <Switch
              name="showMarketingPermissions"
              style={{ pointerEvents: "auto" }}
              checked={formik.values.showMarketingPermissions}
              onChange={formik.handleChange}
            />
          }
          label="Capture Marketing Permissions"
        />
      </StyledFormGroup>
      <StyledErrorMessage
        name="showMarketingPermissions"
        component="div"
        className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
      />
      <InputField
        fullWidth
        id="partnerName"
        name="partnerName"
        label="Partner Name"
        variant="standard"
        value={formik.values.partnerName}
        onChange={formik.handleChange}
        error={formik.touched.partnerName && Boolean(formik.errors.partnerName)}
        helperText={formik.touched.partnerName && formik.errors.partnerName}
        disabled={!formik.values.showMarketingPermissions}
      />
      <InputField
        fullWidth
        id="privacyPolicyUrl"
        name="privacyPolicyUrl"
        label="Privacy Policy Url"
        variant="standard"
        value={formik.values.privacyPolicyUrl}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.privacyPolicyUrl &&
          Boolean(formik.errors.privacyPolicyUrl)
        }
        helperText={
          formik.touched.privacyPolicyUrl && formik.errors.privacyPolicyUrl
        }
        disabled={!formik.values.showMarketingPermissions}
      />
      <TextArea
        fullWidth
        multiline
        id="privacyPolicy"
        label="Privacy Policy Copy"
        value={formik.values.privacyPolicy ?? ""}
        onChange={formik.handleChange}
        error={
          formik.touched.privacyPolicy && Boolean(formik.errors.privacyPolicy)
        }
        helperText={formik.touched.privacyPolicy && formik.errors.privacyPolicy}
        disabled={!formik.values.showMarketingPermissions}
        InputProps={{
          inputComponent: TextareaAutosize,
          rows: 3
        }}
      />
    </TabPanel>
  );
};

export default MarketingPermissionsPanel;
