import styled from "@emotion/styled";
import { AddCircle } from "@mui/icons-material";
import { CircularProgress, Button, Chip } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { AxiosError } from "axios";

import { Status } from "../../clients/services/sams/types";
import {
  DataContainer,
  CreateButton
} from "../../constants/dataGridTableStyles";
import { ErrorMessage } from "../ErrorMessage";
import UpdateDeleteTableBtn from "../UpdateDeleteTableBtn";

import { TableComponentProps } from "./types";

const StyledDataGrid = styled(DataGrid)`
  border: none;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 6%);
`;

const StyledButton = styled(Button)`
  border-radius: 16px;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 6%);
  font-size: 0.75rem;
  font-weight: 600;
  transition: all 0.3s ease-in;
  &:hover {
    transform: scale(1.025);
  }
`;

const StyledCell = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const TableComponent = <T,>({
  isLoading,
  initialState,
  error,
  refetch,
  rows,
  columns,
  testId,
  tableProperties,
  deleteFunction,
  createButton
}: TableComponentProps<T>) => {
  const tableColumns = [
    ...columns,
    {
      field: "status",
      headerName: "Status",
      maxWidth: 130,
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, Status>) => {
        switch (params.value) {
          case Status.PUBLISHED:
            return (
              <Chip color="success" label="Published" variant="outlined" />
            );
          case Status.PREVIEW:
            return (
              <Chip color="secondary" label="Preview" variant="outlined" />
            );
          case Status.DRAFT:
            return <Chip color="primary" label="Draft" variant="outlined" />;
          case Status.DELETED:
            return <Chip color="error" label="Deleted" variant="outlined" />;
          default:
            return "";
        }
      }
    },
    {
      field: "Edit and Delete",
      headerName: "",
      minWidth: 140,
      renderCell: (params: GridRenderCellParams) => (
        <StyledCell>
          <UpdateDeleteTableBtn
            id={params.id}
            deleteFunction={deleteFunction}
            refetch={refetch}
            showMarketingPermission={params.row.showMarketingPermission}
            bookingCodeButtons={params.row.bookingCodeButtons}
            {...tableProperties}
          />
        </StyledCell>
      )
    }
  ];

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  return (
    <DataContainer data-testid={testId}>
      {!!createButton && (
        <CreateButton to={createButton.link} className={"classes.link"}>
          <StyledButton
            data-testid={`${testId}CreateBtn`}
            startIcon={<AddCircle />}
            variant="contained"
          >
            {createButton.text}
          </StyledButton>
        </CreateButton>
      )}
      <StyledDataGrid
        hideFooterSelectedRowCount={true}
        initialState={{
          ...initialState,
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        autoHeight={true}
        rows={rows}
        columns={tableColumns}
      />
    </DataContainer>
  );
};

export default TableComponent;
