import styled from "@emotion/styled";
import { Alert, CircularProgress, Link } from "@mui/material";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  deleteImage,
  listImages
} from "../../../clients/services/sams/gallery";
import { ImagesPrefix } from "../../../clients/services/sams/gallery/types";
import useMutationHandler from "../../../hooks/useMutationHandler";
import { ErrorMessage } from "../../ErrorMessage";

const Container = styled.section`
  display: flex;
  width: 100%;
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`;

const ImageWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 5px;
  border: 1px solid white;
  box-shadow: 0 0 0 5px
    ${(props) => (props.selected ? "#1976d2" : "transparent")};
  cursor: pointer;
`;

const Checkbox = styled.div<{ selected: boolean }>`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 35px;
  height: 35px;
  background-color: ${(props) => (props.selected ? "#1976d2" : "transparent")};
  border: 1px solid white;
  box-shadow: 0 0 0 1px #1976d2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
  z-index: 999;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImagePreview = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
`;

const InfoSection = styled.div`
  flex: 1;
  padding-left: 20px;
  min-width: 400px;
  border-left: 1px solid #d7d7d7;
`;

const ImageInfo = styled.div`
  margin-bottom: 10px;
`;

const ImageResizeMessage = styled.p`
  color: #bdbdbd;
  margin: 5px 0;
  font-weight: 600;
`;

const DeleteLink = styled(Link)`
  cursor: pointer;
`;

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 33%;
  left: 33%;
`;

const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const StyledError = styled.div`
  margin: 32px 32px 32px 0;
  max-width: 95%;
`;

interface ImageGalleryProps {
  onImageSelect: (imageUrl: string) => void;
  imageResizeMessage?: string;
  imagesPrefix: ImagesPrefix;
}

const ImageGallery: FC<ImageGalleryProps> = ({
  imageResizeMessage,
  onImageSelect,
  imagesPrefix
}) => {
  const {
    isLoading: isLoadingListImages,
    data,
    error: errorListImages,
    refetch
  } = useQuery(["listImages"], () => listImages(imagesPrefix));

  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

  const [onSuccessDeleteImage, onError] = useMutationHandler(
    `Image deleted successfully!`,
    () => refetch()
  );

  const {
    mutate: mutateDeleteImage,
    isLoading: isLoadingDeleteImage,
    isError: isErrorDeleteImage,
    error: errorDeleteImage
  } = useMutation<any, AxiosError<any>, any>(deleteImage, {
    onSuccess: () => {
      onSuccessDeleteImage();
      setSelectedImageUrl(null);
      onImageSelect("");
    },
    onError
  });

  const handleImageClick = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl);
    onImageSelect(imageUrl);
  };

  const handleImageDeletion = () => {
    mutateDeleteImage({ thumbImagePath: selectedImageUrl });
  };

  const getImageUrl = (galleryImageUrl: string | null) =>
    galleryImageUrl?.replace("/thumbnails", "");

  if (errorListImages) {
    return (
      <StyledError>
        <ErrorMessage axiosErrors={[errorListImages as AxiosError]} />
      </StyledError>
    );
  }

  if (isLoadingListImages) {
    // Wait for listImages before the first render
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <Container>
      <div>
        <Row>
          {data &&
            data.map((imageUrl: string, index: number) => (
              <ImageWrapper
                key={index}
                selected={selectedImageUrl === imageUrl}
                onClick={() => handleImageClick(imageUrl)}
                data-testid={"Image"}
              >
                {selectedImageUrl === imageUrl && isLoadingDeleteImage && (
                  <Loading data-testid={"loading"} />
                )}
                {selectedImageUrl === imageUrl && (
                  <Checkbox selected={true} data-testid={"checkbox"}>
                    ✓
                  </Checkbox>
                )}
                <Image src={imageUrl} alt={`Image ${index}`} />
              </ImageWrapper>
            ))}
        </Row>
      </div>
      <InfoSection>
        <div>
          {selectedImageUrl && (
            <ImageInfo>
              <ImagePreview src={selectedImageUrl} alt={`Image`} />
            </ImageInfo>
          )}
          <ImageInfo>
            <strong>Image URL:</strong> {getImageUrl(selectedImageUrl) || "---"}
          </ImageInfo>
          <ImageInfo>
            <ImageResizeMessage>{imageResizeMessage}</ImageResizeMessage>
          </ImageInfo>
          {selectedImageUrl && (
            <ImageInfo>
              {isErrorDeleteImage && errorDeleteImage && (
                <StyledAlert
                  severity="error"
                  data-testid={"mutation-error-message"}
                >
                  {errorDeleteImage.response?.data.errorMessage ||
                    errorDeleteImage.message}
                </StyledAlert>
              )}
              <DeleteLink
                color={"error"}
                onClick={handleImageDeletion}
                data-testid={`delete-image-button`}
              >
                Delete image permanently
              </DeleteLink>
            </ImageInfo>
          )}
        </div>
      </InfoSection>
    </Container>
  );
};

export default ImageGallery;
