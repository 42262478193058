import { Product, ProductType } from "../../clients/services/sams/merlin/types";
import { Status } from "../../clients/services/sams/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";

import { FormDataInterface } from "./types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: FormDataInterface) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => this.formDataTemplate;

  public formDataToModel = (formData: FormDataInterface) => {
    const {
      status,
      bookingFee,
      description,
      productType,
      sell,
      shortTitle,
      termsAndConditions,
      ...nonTransformedData
    } = formData;

    return {
      ...nonTransformedData,
      productType,
      published: status === Status.PUBLISHED,
      bookingFee: {
        amount: bookingFee
      },
      description: productType === ProductType.UPSELL ? description : null,
      sell: productType === ProductType.UPSELL ? sell : null,
      shortTitle: productType === ProductType.UPSELL ? shortTitle : null,
      termsAndConditions:
        productType === ProductType.UPSELL ? termsAndConditions : null
    };
  };

  public modelToFormData = (modelData: Product) => {
    const { published, attraction, bookingFee, ...nonTransformedData } =
      modelData;

    return {
      ...this.formDataTemplate,
      ...nonTransformedData,

      status: published ? Status.PUBLISHED : Status.DRAFT,
      merlinAttractionId: attraction.merlinAttractionId || "",
      bookingFee: bookingFee?.amount || 0
    };
  };
}

export default Transformer;
