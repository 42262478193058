import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";

import {
  deleteBanner,
  listBanners
} from "../../clients/services/sams/content/banner";
import { BannerListResponse } from "../../clients/services/sams/content/banner/types";
import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import { PostType } from "../../clients/services/sams/types";
import { dateTime, dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

const prettyPrintPageMap = new Map<string, string>([
  ["all", "All pages"],
  ["/home", "Home page"],
  ["/offers", "Offers page"],
  ["/raffle", "Raffle page"],
  ["/cashout", "Cashout page"]
]);

const BannerTable = () => {
  const { isLoading, data, error, refetch } = useQuery(["listBanners"], () =>
    listBanners()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.BANNER)
  );
  const tableProperties = {
    itemName: "banner",
    actionLabel: "Banner"
  };

  const rows: Rows | undefined = data?.map((banner: BannerListResponse) => {
    return {
      id: banner.id,
      title: banner.title,
      status: banner.status,
      page: prettyPrintPageMap.get(banner.page as string),
      startDate: dateTime(banner.startDate),
      endDate: dateTime(banner.endDate),
      user: findLatestHistory(historyData, banner.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, banner.id)
        ? dateTimeFull(findLatestHistory(historyData, banner.id)?.createdDate)
        : "Created from service"
    };
  });

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "page", headerName: "Page", flex: 1 },
    { field: "startDate", headerName: "Start Date", flex: 1 },
    { field: "endDate", headerName: "End Date", flex: 1 },
    {
      field: "user",
      headerName: "Author",
      minWidth: 90,
      flex: 1
    },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    }
  ];

  return (
    <TableComponent<BannerListResponse>
      isLoading={isLoading || isLoadingHistory}
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="BannerTableTestID"
      createButton={{
        link: "/create-banner",
        text: "Create Banner"
      }}
      tableProperties={tableProperties}
      deleteFunction={deleteBanner}
    />
  );
};

export default BannerTable;
