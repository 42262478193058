const generateSlugField = (headline: string, formikObject: any) => {
  const generatedSlug = `${headline
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")}-${new Date()
    .toLocaleString("default", { month: "long" })
    .slice(0, 3)
    .toLowerCase()}-${new Date().getDate()}`;
  return formikObject.setFieldValue("slug", generatedSlug);
};

export default generateSlugField;
