import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import {
  RaffleWinnerResponse,
  RaffleWinner,
  RaffleWinnerListResponse
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createRaffleWinner = async (raffleWinner: RaffleWinner) => {
  const url = `${baseUrl}/content/raffle-winners`;

  const response: AxiosResponse<RaffleWinnerResponse> = await client.post(
    url,
    raffleWinner
  );
  return response.data;
};

export const listRaffleWinners = async () => {
  const url = `${baseUrl}/content/raffle-winners`;

  const response: AxiosResponse<RaffleWinnerListResponse[]> = await client.get(
    url
  );
  return response.data;
};

export const deleteRaffleWinner = async (id: string) => {
  const url = `${baseUrl}/content/raffle-winners/${id}`;

  await client.delete(url);
};

export const getRaffleWinner = async (id: string) => {
  const url = `${baseUrl}/content/raffle-winners/${id}`;

  const response: AxiosResponse<RaffleWinnerListResponse> = await client.get(
    url
  );
  return response.data;
};

export const updateRaffleWinner = async (
  raffleWinner: RaffleWinnerListResponse
) => {
  const url = `${baseUrl}/content/raffle-winners/${raffleWinner.id}`;

  const response: AxiosResponse<RaffleWinnerListResponse> = await client.put(
    url,
    raffleWinner
  );
  return response.data;
};
