import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";

import { useListMorePages } from "./hooks/useListMorePages";

const MorePageTable = () => {
  const { isLoading, data, error, refetch } = useListMorePages();

  const rows = data?.map((morePage) => {
    return {
      id: morePage.id,
      headline: morePage.headline,
      status: morePage.status
    };
  });

  const columns = [{ field: "headline", headerName: "Tile Headline", flex: 1 }];

  return (
    <TableComponent
      isLoading={isLoading}
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="MorePageTestID"
      createButton={{
        link: "/create-more-page",
        text: "Create More Page"
      }}
      tableProperties={{
        itemName: "more-page",
        actionLabel: "More Page"
      }}
    />
  );
};

export default MorePageTable;
