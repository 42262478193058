import { FormControlLabel, FormLabel, Switch } from "@mui/material";
import { FormikProps } from "formik";
import { ReactChild } from "react";

import {
  Paragraph,
  StyledFormGroup
} from "../TabPanels/commonStyledComponents";
import { FormDataInterface } from "../types";

interface Props {
  formik: FormikProps<FormDataInterface>;
  description: string;
  name: keyof FormDataInterface;
  label: string;
  sectionTitle: string;
  toggleInnerComponent?: boolean;
  innerComponent?: ReactChild;
}

const SwitchSection: React.FC<Props> = ({
  formik,
  description,
  name,
  label,
  sectionTitle,
  toggleInnerComponent,
  innerComponent
}) => {
  return (
    <StyledFormGroup>
      <FormLabel component="legend">{sectionTitle}</FormLabel>
      <Paragraph>{description}</Paragraph>
      <FormControlLabel
        style={{ pointerEvents: "none" }}
        control={
          <Switch
            name={name}
            style={{ pointerEvents: "auto" }}
            checked={formik.values[name] as boolean}
            onChange={formik.handleChange}
          />
        }
        label={label}
      />
      {toggleInnerComponent && innerComponent}
    </StyledFormGroup>
  );
};

export default SwitchSection;
