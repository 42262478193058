import { MenuItem } from "@mui/material";
import { FormikProps } from "formik";

import { mapSunProduct } from "../../../clients/services/sams/promotion/types";
import { SunProduct } from "../../../clients/services/sams/types";
import { partnership, promotion } from "../../../util/constants";
import SelectField from "../../SelectField";
import { SelectDesriptionParagraph } from "../TabPanels/commonStyledComponents";
import { FormDataInterface } from "../types";

interface Props {
  formik: FormikProps<FormDataInterface>;
  sunProductSelect?: {
    description: string;
  };
  showSunMobile?: boolean;
}

const SunProductSelect: React.FC<Props> = ({
  formik,
  sunProductSelect,
  showSunMobile = true
}) => (
  <SelectField
    form={formik}
    label={"Sun Product"}
    labelID={"sunProduct"}
    defaultValue={formik.values.sunProduct}
    name={"sunProduct"}
    customValueHandler={() => null}
  >
    <SelectDesriptionParagraph>
      {sunProductSelect?.description}
    </SelectDesriptionParagraph>
    {Object.values(SunProduct)
      .filter(
        (sunProduct) =>
          !(!showSunMobile && sunProduct === SunProduct.SUN_MOBILE)
      )
      .filter(
        (sunProduct) =>
          !(
            sunProduct === SunProduct.SUN_CLUB &&
            !["COMPETITION", "NONCODE"].includes(formik.values.promotionType)
          )
      )
      .map((sunProduct) => (
        <MenuItem
          key={sunProduct}
          value={sunProduct}
          data-testid={"sun-product-option"}
        >
          {mapSunProduct.get(sunProduct)}
        </MenuItem>
      ))}
  </SelectField>
);

export default SunProductSelect;
