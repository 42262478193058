import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import {
  NewVersionResponse,
  NewVersion,
  NewVersionListResponse
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createNewVersion = async (newVersion: NewVersion) => {
  const url = `${baseUrl}/content/new-versions`;

  const response: AxiosResponse<NewVersionResponse> = await client.post(
    url,
    newVersion
  );
  return response.data;
};

export const listNewVersions = async () => {
  const url = `${baseUrl}/content/new-versions`;

  const response: AxiosResponse<NewVersionListResponse[]> = await client.get(
    url
  );
  return response.data;
};

export const deleteNewVersion = async (id: string) => {
  const url = `${baseUrl}/content/new-versions/${id}`;

  await client.delete(url);
};

export const getNewVersion = async (id: string) => {
  const url = `${baseUrl}/content/new-versions/${id}`;

  const response: AxiosResponse<NewVersionListResponse> = await client.get(url);
  return response.data;
};

export const updateNewVersion = async (newVersion: NewVersionListResponse) => {
  const url = `${baseUrl}/content/new-versions/${newVersion.id}`;

  const response: AxiosResponse<NewVersionListResponse> = await client.put(
    url,
    newVersion
  );
  return response.data;
};
