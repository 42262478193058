import styled from "@emotion/styled";
import { TextField, Grid, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { FC } from "react";

import { ImageResponse } from "../../../clients/services/sams/gallery/types";
import { imageResizeMessages } from "../../../constants/imageResizeMessages";
import { partnership } from "../../../util/constants";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import ImageSelection from "../../ImageSelection";
import SunProductSelect from "../../Offer/SunProductSelect";
import StatusSelector from "../../StatusSelector";

const Container = styled.div`
  padding-top: 15px;
`;

const InputField = styled(TextField)`
  margin-top: 8px;
`;

const DateInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const DateInput = styled(TextField)`
  margin-top: 16px;
  width: 47%;
`;

interface FormProps {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form: FC<FormProps> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting
}) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <Container data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <FormikProvider value={formik}>
        <form id={formId} onSubmit={formik.handleSubmit}>
          {formik.submitCount !== 0 && (
            <ErrorMessage formValidationErrors={formik.errors} />
          )}
          <Grid container>
            <Grid container item xs={8}>
              <InputField
                fullWidth
                id="headline"
                name="headline"
                label="Headline"
                variant="standard"
                value={formik.values.headline}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.headline && Boolean(formik.errors.headline)
                }
                helperText={formik.touched.headline && formik.errors.headline}
              />
              <InputField
                fullWidth
                id="partnershipText"
                name="partnershipText"
                label="Partnership Text"
                variant="standard"
                multiline
                value={formik.values.partnershipText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.partnershipText &&
                  Boolean(formik.errors.partnershipText)
                }
                helperText={
                  formik.touched.partnershipText &&
                  formik.errors.partnershipText
                }
              />
              <InputField
                fullWidth
                id="badgeText"
                name="badgeText"
                label="Badge Text"
                variant="standard"
                value={formik.values.badgeText}
                onChange={formik.handleChange}
                error={
                  formik.touched.badgeText && Boolean(formik.errors.badgeText)
                }
                helperText={formik.touched.badgeText && formik.errors.badgeText}
              />
              <ImageSelection
                id="image"
                value={formik.values.image}
                imageResizeMessage={imageResizeMessages.partnership}
                error={formik.touched.image && Boolean(formik.errors.image)}
                helperText={formik.touched.image && formik.errors.image}
                btnText={
                  formik.values.image.length !== 0
                    ? "Select another image"
                    : "Select an image"
                }
                setFieldValue={formik.setFieldValue}
                isImageSelectionDisabled={!formik.values.headline}
                isImageSelected={formik.values.image.length !== 0}
                postType="partnership"
                postHeadline={formik.values.headline}
                imageToUpload={formik.values.image}
                setImageFieldsValues={(data: ImageResponse) => {
                  if ("image" in data) {
                    formik.setFieldValue("image", data.image);
                  }
                }}
              />
              <InputField
                fullWidth
                id="url"
                name="url"
                label="Partnership URL"
                variant="standard"
                value={formik.values.url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
              <SunProductSelect
                formik={formik}
                sunProductSelect={partnership.sunProductSelect}
                showSunMobile={false}
              />
              <DateInputContainer>
                <DateInput
                  id="liveDate"
                  name="liveDate"
                  label="Live Date"
                  variant="standard"
                  type="datetime-local"
                  value={formik.values.liveDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.liveDate && Boolean(formik.errors.liveDate)
                  }
                  helperText={formik.touched.liveDate && formik.errors.liveDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <DateInput
                  id="endDate"
                  name="endDate"
                  label="End Date"
                  variant="standard"
                  type="datetime-local"
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </DateInputContainer>
            </Grid>
            <Grid container item xs={4}>
              <StatusSelector
                isSubmitting={isSubmitting}
                form={formik}
                fieldName="status"
                actionLabel={actionLabel}
                testId={actionTestId}
              />
              {values.id ? (
                <HistoryTable postType={"partnership"} postId={values.id} />
              ) : null}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
};

export default Form;
