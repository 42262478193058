import { useQuery } from "react-query";

import { listMorePages } from "../../../clients/services/sams/content/morePage";

export const useListMorePages = () => {
  const { isLoading, data, error, refetch } = useQuery(["listMorePages"], () =>
    listMorePages()
  );

  return { isLoading, data, error, refetch };
};
