import Papa from "papaparse";

export const parseBookingCodesCsvFile = async (bookingCodesCsvFile: File) => {
  return new Promise<string[]>((resolve, reject) => {
    Papa.parse(bookingCodesCsvFile, {
      complete: (result) => {
        const bookingCodes = result.data.flat() as string[];

        const filteredBookingCodes = bookingCodes.filter(
          (bookingCode) => bookingCode !== ""
        );

        resolve(filteredBookingCodes);
      },
      error: (error) => {
        reject(error);
      }
    });
  });
};
