import { NewVersion } from "../../clients/services/sams/content/newVersion/types";
import { Status } from "../../clients/services/sams/types";

const formDataTemplate: NewVersion = {
  status: Status.DRAFT,
  title: "",
  features: [
    {
      title: "",
      description: ""
    }
  ],
  releaseDate: "",
  platform: "",
  version: ""
};

export default formDataTemplate;
