import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { changeArticlePosition } from "../../../clients/services/sams/content/article";
import { ArticleListResponse } from "../../../clients/services/sams/content/article/types";
import { setNotification } from "../../../state/reducers/notification";

interface MutationContext {
  previousArticles: ArticleListResponse[];
}

export function useChangeArticlePosition() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(changeArticlePosition, {
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries("listArticles");

      // Capture the current state before the mutation
      const previousArticles =
        queryClient.getQueryData<ArticleListResponse[]>("listArticles");
      if (!previousArticles) return { previousArticles: [] };

      // Create a map for quick lookup
      const positionMap = new Map(
        newOrder.map((item) => [item.id, item.positionNumber])
      );

      // Optimistically update to the new value by adjusting positionNumbers
      const optimisticArticles = previousArticles.map((article) => ({
        ...article,
        positionNumber: positionMap.get(article.id) ?? article.positionNumber
      }));

      queryClient.setQueryData("listArticles", optimisticArticles);

      return { previousArticles };
    },
    onSuccess: () => {
      dispatch(
        setNotification({
          message: `Articles order has been changed successfully!`,
          severity: "success"
        })
      );
      queryClient.invalidateQueries("listArticles");
    },
    onError: (error: AxiosError, _, context: unknown) => {
      const typedContext = context as MutationContext;
      // Rollback to the previous value
      queryClient.setQueryData("listArticles", typedContext.previousArticles);
      dispatch(
        setNotification({
          message: `${error.response?.data.errorMessage}`,
          severity: "error"
        })
      );
    }
  });
}
