import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";

import {
  createProduct,
  getProduct,
  updateProduct
} from "../../clients/services/sams/merlin";
import { getPromotions } from "../../clients/services/sams/promotion";
import useFormData from "../../hooks/useFormData";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import formDataTemplate from "./formDataTemplate";
import Transformer from "./transformer";
import { FormDataInterface } from "./types";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageProduct = () => {
  const transformer = new Transformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(transformer, getProduct, createProduct, updateProduct);
  const history = useHistory();

  const [onSuccess, onError] = useMutationHandler(
    `Product ${isUpdateOperation ? "updated" : "created"} successfully!`,
    () => history.push("/products")
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation(mutationFunction, {
    onSuccess,
    onError
  });

  const submitHandler = async (inputValues: FormDataInterface) => {
    await mutate(transformer.formDataToModel(inputValues) as any);
  };

  const {
    isLoading: isLoadingPromotion,
    data: promotionData,
    error: promotionError
  } = useQuery(["getPromotions"], () => getPromotions({ filterExpired: true }));

  const formProperties = isUpdateOperation
    ? {
        title: "Update Product",
        actionLabel: "Update",
        actionTestId: "updateProductTestID",
        formId: "updateProduct"
      }
    : {
        title: "Create Product",
        actionLabel: "Create",
        actionTestId: "createProductTestID",
        formId: "createProduct"
      };

  if (promotionError) {
    return <ErrorMessage axiosErrors={[promotionError as AxiosError]} />;
  }

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage simpleErrors={["Cannot get product!"]} />
      </StyledError>
    );
  }

  if (isLoading || isLoadingPromotion) {
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        offersData={promotionData || []}
        {...formProperties}
      />
    </>
  );
};

export default ManageProduct;
