export enum AdmissionType {
  OPEN = "OPEN",
  DATED = "DATED",
  TIMED = "TIMED"
}

export interface Variant {
  description: string;
  id: number;
  mandatoryQuantity: number;
  maxOrderLimit: number;
  merlinVariantId: string;
  minOrderLimit: number;
  price: number;
  grossAmount?: number;
  netAmount?: number;
}

export enum ProductType {
  OFFER = "OFFER",
  UPSELL = "UPSELL"
}

export interface Product {
  admissionType: AdmissionType;
  attraction: Attraction;
  bookingFee: {
    amount: number;
  };
  description: string | null;
  extraInformation: string | null;
  images: {
    [key: string]: {
      [key: string]: string;
    };
  };
  label: string;
  productType: ProductType;
  published: boolean;
  sell: string | null;
  shortTitle: string | null;
  slug: string;
  termsAndConditions: string | null;
  title: string;
  variants: Variant[];
  xcode: string;
  eventId: string;
  merlinProductCode: string;
}

export interface Attraction {
  merlinAttractionId: string;
  description: string;
  id: number;
}

export interface ProductResponse {
  id: number;
}

export interface ProductListResponse extends Product {
  id: number;
}

export interface ProductsListByAttractionResponse {
  pagination: {
    currnetpage: number;
    pagezise: string;
    totalrecords: number;
    pagelinks: {
      href: string;
      rel: string;
    }[];
  };
  products: {
    admissiontype: string;
    attractionid: string;
    eventdescription: string;
    eventid: string;
    itemtype: string;
    productcode: string;
    productname: string;
  }[];
}

export interface ProductVariant {
  variantid: string;
  variantname: string;
  grossamount: string;
  netamount: string;
}

export interface FullMerlinProductData {
  productcode: string;
  productname: string;
  itemtype: string;
  productvariants?: ProductVariant[];
  additionalinformation?: any;
  event: {
    eventid: string;
    eventdescription: string;
    admissiontype: string;
    eventcalendar?: any;
  };
}

export enum Status {
  DRAFT = "DRAFT",
  PREVIEW = "PREVIEW",
  PUBLISHED = "PUBLISHED",
  DELETED = "DELETED"
}

export interface MerlinProduct {
  attractionid: string;
  eventdescription: string;
  eventid: any;
  productcode: string;
  admissiontype: string;
  productname: string;
}

export interface BookingData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  bookingDate: string;
  cpn: string;
}
