import * as yup from "yup";

import { Status, SunProduct } from "../../clients/services/sams/types";

const validationSchema = yup.object({
  headline: yup
    .string()
    .required("Headline of the partnership is required")
    .min(3, "Headline of the partnership must not be less than 3 characters")
    .max(45, "Headline of the partnership must not be more than 45 characters"),
  liveDate: yup
    .date()
    .when("isUpdateOperation", {
      is: false,
      then: yup.date().min(new Date(), "Live date can't be in the past")
    })
    .required("Live date is required"),
  endDate: yup
    .date()
    .min(yup.ref("liveDate"), "End date can't be before live date")
    .required("End date is required"),
  url: yup
    .string()
    .url("Please specify a valid url")
    .required("URL is required"),
  partnershipText: yup.string().required("Partnership text is required"),
  badgeText: yup.string(),
  image: yup.string().required("Partnership image is required"),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Publication status is required"),
  sunProduct: yup.mixed().oneOf(Object.values(SunProduct))
});

export default validationSchema;
