import styled from "@emotion/styled";
import { TextField, Grid, Typography, TextFieldProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import moment from "moment";

import { ImageResponse } from "../../../clients/services/sams/gallery/types";
import { imageResizeMessages } from "../../../constants/imageResizeMessages";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import ImageSelection from "../../ImageSelection";
import StatusSelector from "../../StatusSelector";

const CreateBannerContainer = styled.div`
  padding-top: 15px;
`;
const CustomInputField = styled(TextField)`
  margin-top: 24px;
`;

const DatePickerContainer = styled.div`
  margin-top: 36px;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const ImagePickerContainer = styled.div`
  margin-top: 24px;
`;

const StyledLabel = styled.p`
  color: rgba(0, 0, 0, 0.6);
`;

interface FormInterface {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting
}: FormInterface) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <CreateBannerContainer data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        <Grid container item xs={8}>
          <StyledForm
            id={formId}
            onSubmit={formik.handleSubmit}
            data-testid={"createRaffleWinner"}
          >
            {formik.submitCount !== 0 && (
              <ErrorMessage formValidationErrors={formik.errors} />
            )}
            <CustomInputField
              fullWidth
              id="title"
              name="title"
              variant="standard"
              label="Title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <CustomInputField
              fullWidth
              id="description"
              name="description"
              label="Description"
              multiline
              variant="standard"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />

            <ImagePickerContainer>
              <StyledLabel>Main Image</StyledLabel>
              <ImageSelection
                id="mainImage"
                value={formik.values.mainImage}
                imageResizeMessage={imageResizeMessages.raffleWinner.mainImage}
                error={
                  formik.touched.mainImage && Boolean(formik.errors.mainImage)
                }
                helperText={formik.touched.mainImage && formik.errors.mainImage}
                btnText={
                  formik.values.mainImage.length !== 0
                    ? "Select another image"
                    : "Select an image"
                }
                setFieldValue={formik.setFieldValue}
                isImageSelectionDisabled={!formik.values.title}
                isImageSelected={formik.values.mainImage.length !== 0}
                setImageFieldsValues={(data: ImageResponse) => {
                  if ("image" in data) {
                    formik.setFieldValue("mainImage", data.image);
                  }
                }}
                postType="raffleWinner"
                imageUploadId="mainImage"
                imageToUpload={formik.values.mainImage}
                postHeadline={formik.values.title}
                imageType="main"
              />
              <StyledLabel>Thumbnail Image</StyledLabel>
              <ImageSelection
                id="thumbnailImage"
                value={formik.values.thumbnailImage}
                imageResizeMessage={
                  imageResizeMessages.raffleWinner.thumbnailImage
                }
                error={
                  formik.touched.thumbnailImage &&
                  Boolean(formik.errors.thumbnailImage)
                }
                helperText={
                  formik.touched.mainImage && formik.errors.thumbnailImage
                }
                btnText={
                  formik.values.thumbnailImage.length !== 0
                    ? "Select another image"
                    : "Select an image"
                }
                setFieldValue={formik.setFieldValue}
                isImageSelectionDisabled={!formik.values.title}
                isImageSelected={formik.values.thumbnailImage.length !== 0}
                setImageFieldsValues={(data: ImageResponse) => {
                  if ("image" in data) {
                    formik.setFieldValue("thumbnailImage", data.image);
                  }
                }}
                postType="raffleWinner"
                imageUploadId="thumbnailImage"
                imageToUpload={formik.values.thumbnailImage}
                postHeadline={formik.values.title}
                imageType="thumbnail"
              />
            </ImagePickerContainer>

            <DatePickerContainer>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year", "month"]}
                  label="Raffle Date"
                  value={moment(formik.values.raffleDate).toDate()}
                  onChange={(newValue: Date | string | null) => {
                    formik.setFieldValue("raffleDate", newValue);
                  }}
                />
              </LocalizationProvider>
            </DatePickerContainer>

            <CustomInputField
              fullWidth
              id="ticketNumber"
              name="ticketNumber"
              label="Ticket Number"
              variant="standard"
              value={formik.values.ticketNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.ticketNumber &&
                Boolean(formik.errors.ticketNumber)
              }
              helperText={
                formik.touched.ticketNumber && formik.errors.ticketNumber
              }
            />
          </StyledForm>
        </Grid>
        <Grid container item xs={4}>
          <StatusSelector
            isSubmitting={isSubmitting}
            form={formik}
            actionLabel={actionLabel}
            testId={actionTestId}
          />
          {values.id ? (
            <HistoryTable postType={"raffle-winner"} postId={values.id} />
          ) : null}
        </Grid>
      </Grid>
    </CreateBannerContainer>
  );
};

export default Form;
