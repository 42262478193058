import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import {
  createArticle,
  getArticle,
  updateArticle
} from "../../clients/services/sams/content/article";
import { Article } from "../../clients/services/sams/content/article/types";
import useFormData from "../../hooks/useFormData";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import formDataTemplate from "./formDataTemplate";
import Transformer from "./transformer";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageArticle = () => {
  const transformer = new Transformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(transformer, getArticle, createArticle, updateArticle);
  const history = useHistory();

  const [onSuccess, onError] = useMutationHandler(
    `Article ${isUpdateOperation ? "updated" : "created"} successfully!`,
    () => history.push("/articles")
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation<any, AxiosError<any>, Article>(mutationFunction, {
    onSuccess,
    onError
  });

  const submitHandler = async (inputValues: Article) => {
    await mutate(transformer.formDataToModel(inputValues));
  };

  const formProperties = isUpdateOperation
    ? {
        title: "Update Article",
        actionLabel: "Update",
        actionTestId: "updateArticleTestID",
        formId: "updateArticle"
      }
    : {
        title: "Create Article",
        actionLabel: "Create",
        actionTestId: "createArticleTestID",
        formId: "createArticle"
      };

  if (isError) {
    return (
      <StyledError>
        <ErrorMessage
          simpleErrors={["Cannot get article!"]}
          data-testid={"error-message"}
        />
      </StyledError>
    );
  }

  if (isLoading) {
    // Wait for getArticle before the first Form render
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        {...formProperties}
      />
    </>
  );
};

export default ManageArticle;
