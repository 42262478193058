import { useQuery } from "react-query";

import { listArticles } from "../../../clients/services/sams/content/article";
import { Status } from "../../../clients/services/sams/types";

export function useGetArticles() {
  const {
    isLoading: isLoadingArticles,
    data,
    error: articlesError
  } = useQuery(["listArticles"], () => listArticles());
  const todayDate = new Date();

  const articlesData = data
    ?.filter(
      (article) =>
        // Exclude draft articles
        article.status !== Status.DRAFT &&
        new Date(article.publishEndDate as string) > todayDate
    )
    .sort((a, b) => (a.positionNumber > b.positionNumber ? 1 : -1));

  return {
    isLoadingArticles,
    articlesData: articlesData ?? [],
    articlesError
  };
}
