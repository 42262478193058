import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Notification {
  message?: string;
  severity?: any;
  showUploadCodesLargeRequestMessage?: boolean;
}

export interface UploadCodesLargeRequestMessage {
  message: string;
}

type NotificationState = Notification;

const initialState = {} as NotificationState;

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<Notification>) =>
      action.payload,
    setUploadCodesLargeRequestMessage: (state) => ({
      ...state,
      showUploadCodesLargeRequestMessage: true
    })
  }
});

export const { setNotification, setUploadCodesLargeRequestMessage } =
  notificationSlice.actions;

export default notificationSlice.reducer;
