import { Switch, FormLabel } from "@mui/material";
import { FormikProps } from "formik";

import TabPanel from "../../../TabPanel";
import { FormDataInterface } from "../../types";
import { StyledFormGroup } from "../commonStyledComponents";

interface Props {
  formik: FormikProps<FormDataInterface>;
  value: number;
}

const StatusesPanel: React.FC<Props> = ({ formik, value }) => {
  return (
    <TabPanel value={value} index={3}>
      <StyledFormGroup>
        <FormLabel component="legend">Sold out</FormLabel>
        <Switch
          name="soldOut"
          checked={formik.values.soldOut}
          onChange={formik.handleChange}
        />
      </StyledFormGroup>
    </TabPanel>
  );
};

export default StatusesPanel;
