import styled from "@emotion/styled";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useState } from "react";

const StyledDialog = styled(Dialog)`
  & .MuiDialogTitle-root {
    background: #e0edf7;
    font-size: 1rem;
    font-weight: 800;
    color: #003b74;
  }
  & .MuiDialogContent-root {
    padding-top: 25px;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 800;
  font-size: 0.9em;
  letter-spacing: 0;
  color: #1f7cbf;
`;

const DialogItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 0 10px 0 10px;
`;

const Buttons = styled.div`
  align-self: flex-end;
`;

interface Props {
  title: string;
  description?: string;
  actionLabel?: string;
  action?: () => any;
  isLoading?: boolean;
  testIdPrefix?: string;
  children: (handleDialogOpen: () => void) => React.ReactNode;
  extraContent?: React.ReactNode;
}

const ConfirmationDialog = ({
  title,
  description,
  actionLabel,
  action,
  isLoading,
  testIdPrefix = "confirm",
  children,
  extraContent
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAction = () => {
    if (action) action();
    handleDialogClose();
  };

  return (
    <>
      {children(handleDialogOpen)}
      <StyledDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid={`${testIdPrefix}-dialog`}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <DialogItems>
            {extraContent}

            <Buttons>
              {action && actionLabel && (
                <StyledButton
                  onClick={handleAction}
                  color="primary"
                  disabled={isLoading}
                  data-testid={`${testIdPrefix}-action-dialog-button`}
                >
                  {actionLabel}
                </StyledButton>
              )}
              <StyledButton
                onClick={handleDialogClose}
                color="primary"
                data-testid={`${testIdPrefix}-cancel-dialog-button`}
                autoFocus
              >
                Cancel
              </StyledButton>
            </Buttons>
          </DialogItems>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ConfirmationDialog;
