import moment from "moment";

import { Banner } from "../../clients/services/sams/content/banner/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: Banner) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (formData: Banner) => {
    return {
      ...formData,
      startDate:
        formData.startDate !== "" && typeof formData.startDate === "string"
          ? new Date(formData.startDate)
          : "",
      endDate:
        formData.endDate !== "" && typeof formData.endDate === "string"
          ? new Date(formData.endDate)
          : ""
    };
  };

  public modelToFormData = (modelData: Banner) => {
    return {
      ...modelData,
      startDate:
        modelData.startDate !== ""
          ? moment(modelData.startDate).format("YYYY-MM-DDTHH:mm")
          : "",
      endDate:
        modelData.endDate !== ""
          ? moment(modelData.endDate).format("YYYY-MM-DDTHH:mm")
          : ""
    };
  };
}

export default Transformer;
