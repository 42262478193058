import {
  AdmissionType,
  ProductType
} from "../../clients/services/sams/merlin/types";
import { Status } from "../../clients/services/sams/types";

import { FormDataInterface } from "./types";

const formDataTemplate: FormDataInterface = {
  merlinProductCode: "",
  merlinAttractionId: "",
  admissionType: AdmissionType.OPEN,
  bookingFee: 0,
  description: "",
  extraInformation: "",
  label: "",
  productType: ProductType.UPSELL,
  status: Status.DRAFT,
  sell: "",
  shortTitle: "",
  slug: "",
  termsAndConditions: "",
  title: "",
  variants: [],
  xcode: "",
  eventId: "",
  images: {
    image: {
      original: ""
    },
    thumbnail_image: {
      original: ""
    }
  }
};

export default formDataTemplate;
