import { Snackbar, Alert } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { setNotification } from "../../state/reducers/notification";

export const Notification = () => {
  const { message, severity } = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();
  return (
    <Snackbar
      open={!!message}
      autoHideDuration={5000}
      onClose={() => dispatch(setNotification({}))}
      data-testid="notification-snackbar"
    >
      <Alert severity={severity} data-testid="notification-alert">
        {message}
      </Alert>
    </Snackbar>
  );
};
