import { AxiosError } from "axios";
import { useDispatch } from "react-redux";

import { setNotification } from "../../state/reducers/notification";

type UseMutationHandler = (
  successMessage: string,
  sideEffect?: any
) => [() => void, (responseError: AxiosError) => void];

const useMutationHandler: UseMutationHandler = (
  successMessage: string,
  sideEffect?: any
) => {
  const dispatch = useDispatch();

  const onSuccess = async () => {
    dispatch(
      setNotification({
        message: successMessage,
        severity: "success"
      })
    );

    if (sideEffect) {
      await sideEffect();
    }
  };

  const onError = (responseError: AxiosError) => {
    dispatch(
      setNotification({
        message: [400, 401, 500].find(
          (status) => status === responseError.response?.status
        )
          ? responseError.response!.data.errorMessage
          : responseError.message,
        severity: "error"
      })
    );
  };

  return [onSuccess, onError];
};

export default useMutationHandler;
