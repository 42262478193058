import styled from "@emotion/styled";
import { MenuItem, Button, CircularProgress } from "@mui/material";
import { FC } from "react";

import { Status } from "../../clients/services/sams/types";
import SelectField from "../SelectField";

const StatusContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 85px;
`;

interface StatusSelectorProps {
  form: any;
  fieldName?: string;
  actionLabel: string;
  testId: string;
  showPreviewStatus?: boolean;
  isSubmitting: boolean;
}

const StatusSelector: FC<StatusSelectorProps> = ({
  form,
  fieldName = "status",
  actionLabel,
  testId,
  showPreviewStatus = false,
  isSubmitting
}) => (
  <StatusContainer>
    <StickyWrapper>
      <SelectField
        form={form}
        label={"Status"}
        labelID={"labelStatusID"}
        defaultValue={Status.DRAFT}
        name={fieldName}
      >
        <MenuItem value={Status.DRAFT}>Draft</MenuItem>
        <MenuItem value={Status.PUBLISHED}>Published</MenuItem>
        {showPreviewStatus && (
          <MenuItem value={Status.PREVIEW}>Preview</MenuItem>
        )}
      </SelectField>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        disabled={isSubmitting}
        startIcon={
          isSubmitting ? (
            <CircularProgress
              color="primary"
              size={24}
              data-testid={"submitting"}
            />
          ) : null
        }
        onClick={() => form.handleSubmit()}
        data-testid={`${testId}Button`}
      >
        {!isSubmitting ? actionLabel : ""}
      </Button>
    </StickyWrapper>
  </StatusContainer>
);

export default StatusSelector;
