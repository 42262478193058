import { DefaultConfig } from "../types";

const packageJson = require("../../../../package.json");

const defaultConfig: DefaultConfig = {
  version: packageJson.version,
  servicesUrls: {
    samsService: "/api"
  },
  siteUrl: "https://savers.thesun.co.uk"
};

export default defaultConfig;
