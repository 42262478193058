import { DateTime } from "luxon";
import { useQuery } from "react-query";

import { getPromotions } from "../../../clients/services/sams/promotion";
import { OfferRedemptionMechanic } from "../../../clients/services/sams/promotion/types";
import { OfferStatus, Status } from "../../../clients/services/sams/types";

const isFuture = (date?: string) => {
  if (!date) return false;

  const dateTime = DateTime.fromISO(date, { zone: "Europe/London" });
  const now = DateTime.local().setZone("Europe/London");

  return dateTime > now;
};

export function useGetPromotions() {
  const {
    isLoading: isLoadingPromotion,
    data,
    error: promotionError
  } = useQuery(["getPromotions"], () => getPromotions());

  const promotionData = data
    ?.filter(
      (offer) =>
        // Exclude draft offers
        offer.publicationStatus !== Status.DRAFT &&
        // Include offers if they are not expired
        (offer.status !== OfferStatus.EXPIRED ||
          // or if they are expired but have a booking code redemption mechanic with a future booking code end date
          (offer.redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE &&
            isFuture(offer.bookingCodeEndDate)))
    )
    .sort((a, b) => (a.positionNumber > b.positionNumber ? 1 : -1));

  return {
    isLoadingPromotion,
    promotionData: promotionData ?? [],
    promotionError
  };
}
