import styled from "@emotion/styled";
import { Alert, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";

import { listArticles } from "../../clients/services/sams/content/article";
import { listPartnerships } from "../../clients/services/sams/content/partnership";
import {
  createSlot,
  getSlot,
  updateSlot
} from "../../clients/services/sams/content/slot";
import { Slot } from "../../clients/services/sams/content/slot/types";
import { getPromotions } from "../../clients/services/sams/promotion";
import useFormData from "../../hooks/useFormData";
import useMutationHandler from "../../hooks/useMutationHandler";
import { ErrorMessage } from "../ErrorMessage";

import Form from "./Form";
import formDataTemplate from "./formDataTemplate";
import Transformer from "./transformer";
import validationSchema from "./validationSchema";

const StyledError = styled.div`
  margin: 32px;
  max-width: 95%;
`;
const StyledAlert = styled(Alert)`
  margin: 10px 10px auto auto;
`;

const ManageSlot = () => {
  const transformer = new Transformer(formDataTemplate);
  const [values, mutationFunction, isLoading, isError, isUpdateOperation] =
    useFormData(transformer, getSlot, createSlot, updateSlot);
  const history = useHistory();

  const [onSuccess, onError] = useMutationHandler(
    `Slot ${isUpdateOperation ? "updated" : "created"} successfully!`,
    () => history.push("/slots")
  );

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isMutationError,
    error
  } = useMutation<any, AxiosError<any>, Slot>(mutationFunction, {
    onSuccess,
    onError
  });

  const submitHandler = async (inputValues: Slot) => {
    await mutate(transformer.formDataToModel(inputValues));
  };

  const {
    isLoading: isLoadingPromotion,
    data: promotionData,
    error: promotionError
  } = useQuery(["getPromotions"], () => getPromotions({ filterExpired: true }));
  const {
    isLoading: isLoadingArticle,
    data: articleData,
    error: articleError
  } = useQuery(["listArticles"], () => listArticles({ filterExpired: true }));
  const {
    isLoading: isLoadingPartnership,
    data: partnershipData,
    error: partnershipError
  } = useQuery(["getPartnerships"], () =>
    listPartnerships({ filterExpired: true })
  );

  const formProperties = isUpdateOperation
    ? {
        title: "Update Slot",
        actionLabel: "Update",
        actionTestId: "updateSlotTestID",
        formId: "updateSlot"
      }
    : {
        title: "Create Slot",
        actionLabel: "Create",
        actionTestId: "createSlotTestID",
        formId: "createSlot"
      };

  if (promotionError) {
    return <ErrorMessage axiosErrors={[promotionError as AxiosError]} />;
  }
  if (partnershipError) {
    return <ErrorMessage axiosErrors={[partnershipError as AxiosError]} />;
  }
  if (articleError) {
    return <ErrorMessage axiosErrors={[articleError as AxiosError]} />;
  }
  if (isError) {
    return (
      <StyledError>
        <ErrorMessage simpleErrors={["Cannot get slot!"]} />
      </StyledError>
    );
  }

  if (
    isLoading ||
    isLoadingPromotion ||
    isLoadingArticle ||
    isLoadingPartnership
  ) {
    // Wait for getSlot before the first Form render
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <>
      {isMutationError && error && (
        <StyledAlert severity="error" data-testid={"mutation-error-message"}>
          {error.response?.data.errorMessage || error.message}
        </StyledAlert>
      )}
      <Form
        values={values}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        isSubmitting={isSubmitting}
        promotionData={promotionData}
        articleData={articleData}
        partnershipData={partnershipData}
        {...formProperties}
      />
    </>
  );
};

export default ManageSlot;
