import moment from "moment";

export const dateSortComparator = (a: any, b: any) => {
  const DATE_FORMAT = "MMMM D, YYYY h:mm A";
  const stringToUnix = (str: string): number => moment(str, DATE_FORMAT).unix();

  const unixDate1 = stringToUnix(a as string);
  const unixDate2 = stringToUnix(b as string);

  if (Number.isNaN(unixDate1) && !Number.isNaN(unixDate2)) {
    return -1;
  }
  if (!Number.isNaN(unixDate1) && Number.isNaN(unixDate2)) {
    return 1;
  }
  return unixDate1 - unixDate2;
};
