import { MenuItem } from "@mui/material";
import { FormikProps } from "formik";

import {
  MobilePromotionType,
  OfferRedemptionMechanic,
  PromotionType
} from "../../../clients/services/sams/promotion/types";
import { SunProduct } from "../../../clients/services/sams/types";
import SelectField from "../../SelectField";
import { FormDataInterface } from "../types";

interface Props {
  formik: FormikProps<FormDataInterface>;
}

const PromotionTypeSelect: React.FC<Props> = ({ formik }) => {
  const handlePromotionTypeChange = (promotionType: PromotionType) => {
    if (promotionType !== PromotionType.NONCODE && formik.values.alwaysOn) {
      formik.setFieldValue("alwaysOn", false);
    }

    if (promotionType === PromotionType.NONCODE) {
      formik.setFieldValue(
        "redemptionMechanic",
        OfferRedemptionMechanic.EXTERNAL
      );
      formik.setFieldValue("resendButtonText", "");
    }
  };

  return (
    <SelectField
      form={formik}
      label={"Promotion Type"}
      labelID={"promotionType"}
      defaultValue={formik.values.promotionType}
      name={"promotionType"}
      customValueHandler={handlePromotionTypeChange}
    >
      {Object.values(
        formik.values.sunProduct === SunProduct.SUN_MOBILE
          ? MobilePromotionType
          : PromotionType
      ).map((promotionType) => (
        <MenuItem
          key={promotionType}
          value={promotionType}
          data-testid={"promotion-type-option"}
        >
          {promotionType}
        </MenuItem>
      ))}
    </SelectField>
  );
};

export default PromotionTypeSelect;
