import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import {
  Attraction,
  Product,
  ProductListResponse,
  ProductResponse,
  FullMerlinProductData,
  ProductsListByAttractionResponse,
  BookingData
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const createProduct = async (product: Product) => {
  const url = `${baseUrl}/merlin/products`;

  const response: AxiosResponse<ProductResponse> = await client.post(
    url,
    product
  );
  return response.data;
};

export const listProducts = async (xcode?: string | null) => {
  const url = `${
    xcode === undefined
      ? `${baseUrl}/merlin/products`
      : `${baseUrl}/merlin/products?xcode=${xcode}`
  }`;
  const response: AxiosResponse<ProductListResponse[]> = await client.get(url);

  return response.data;
};

export const deleteProduct = async (id: string) => {
  const url = `${baseUrl}/merlin/products/${id}`;

  await client.delete(url);
};

export const getProduct = async (id: string) => {
  const url = `${baseUrl}/merlin/products/${id}`;

  const response: AxiosResponse<ProductListResponse> = await client.get(url);
  return response.data;
};

export const updateProduct = async (product: ProductListResponse) => {
  const url = `${baseUrl}/merlin/products/${product.id}`;

  const response: AxiosResponse<ProductListResponse> = await client.put(
    url,
    product
  );
  return response.data;
};

export const getAttractions = async () => {
  const url = `${baseUrl}/merlin/attractions`;

  const response: AxiosResponse<Attraction[]> = await client.get(url);
  return response.data;
};

export const getProductsByAttraction = async (merlinAttractionId: string) => {
  const url = `${baseUrl}/merlin/products-by-attractionId/${merlinAttractionId}`;

  const response: AxiosResponse<ProductsListByAttractionResponse> =
    await client.get(url);
  return response.data;
};

export const getProductByCodeAndAttraction = async (
  productCode: string,
  merlinAttractionId: string
) => {
  const url = `${baseUrl}/merlin/product-by-code-and-attraction/productCode/${productCode}/attractionId/${merlinAttractionId}/`;

  const response: AxiosResponse<FullMerlinProductData> = await client.get(url);
  return response.data;
};

export const getBookingDataByProductCode = async (
  productCode: string,
  startDate: string,
  endDate: string
) => {
  const url = `${baseUrl}/get-booking-data/${productCode}?endDate=${
    endDate || ""
  }&startDate=${startDate || ""}`;

  const response = await client.get(url);
  const csvHeader = ["First name", "Last name", "email", "Date of visit"];
  const csvRows: BookingData[] = [];

  response.data.forEach((bookingData: BookingData) => {
    const row: any = [];
    row.push(bookingData.firstName);
    row.push(bookingData.lastName);
    row.push(bookingData.email);
    row.push(bookingData.bookingDate);
    csvRows.push(row.join(","));
  });

  const csvData = `${csvHeader.join(",")}\n${csvRows.join("\n")}`;

  const blob = new Blob([csvData], { type: "text/csv" });
  const dataUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUrl;
  downloadLink.download = "output.csv";

  document.body.appendChild(downloadLink);

  downloadLink.click();

  URL.revokeObjectURL(dataUrl);
};
