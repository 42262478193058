import { CreateMorePageRequestBody } from "../../clients/services/sams/content/morePage";
import { Status } from "../../clients/services/sams/types";

export const formDataTemplate: CreateMorePageRequestBody = {
  headline: "",
  shortDescription: "",
  image: "",
  pageTitle: "",
  content: "",
  slug: "",
  status: Status.DRAFT
};
