import styled from "@emotion/styled";
import { Delete } from "@mui/icons-material";
import { TextareaAutosize, FormLabel, Button } from "@mui/material";
import { FieldArray, FormikProps } from "formik";

import { PromotionType } from "../../../../clients/services/sams/promotion/types";
import RichContentEditor from "../../../RichContentEditor";
import TabPanel from "../../../TabPanel";
import { FormDataInterface } from "../../types";
import { StyledFormGroup } from "../commonStyledComponents";

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledActionTextArea = styled(TextareaAutosize)`
  width: 98%;
  min-height: 100px;
  margin-bottom: 8px;
`;

const DeleteIcon = styled(Delete)`
  cursor: pointer;
  margin-left: 10px;
`;

interface Props {
  formik: FormikProps<FormDataInterface>;
  value: number;
}

const ContentPanel: React.FC<Props> = ({ formik, value }) => {
  return (
    <TabPanel value={value} index={2}>
      <RichContentEditor
        id="offerContent"
        label="Offer Content"
        value={formik.values.offerContent}
        onChange={formik.setFieldValue}
        error={
          formik.touched.offerContent && Boolean(formik.errors.offerContent)
        }
        helperText={formik.touched.offerContent && formik.errors.offerContent}
      />
      {formik.values.promotionType !== PromotionType.DONATION && (
        <StyledFormGroup>
          <FormLabel component="legend">Actions</FormLabel>
          <FieldArray
            name="actions"
            render={(arrayHelpers) => (
              <>
                {formik.values.actions.map((action: string, index: number) => (
                  <Action key={index}>
                    <StyledActionTextArea
                      id={`action_${index}`}
                      name={`actions.${index}`}
                      value={action}
                      onChange={formik.handleChange}
                      maxRows={4}
                    />
                    <DeleteIcon onClick={() => arrayHelpers.remove(index)} />
                  </Action>
                ))}
                <Button color="primary" onClick={() => arrayHelpers.push("")}>
                  Add Action
                </Button>
              </>
            )}
          />
        </StyledFormGroup>
      )}
      <RichContentEditor
        id="termsAndConditions"
        label="Terms & Conditions"
        value={formik.values.termsAndConditions}
        onChange={formik.setFieldValue}
        error={
          formik.touched.termsAndConditions &&
          Boolean(formik.errors.termsAndConditions)
        }
        helperText={
          formik.touched.termsAndConditions && formik.errors.termsAndConditions
        }
      />
      {(formik.values.promotionType === PromotionType.REGULAR ||
        formik.values.promotionType === PromotionType.COMPETITION) && (
        <StyledFormGroup>
          <FormLabel component="legend">DNA - How to get this Offer</FormLabel>
          <FieldArray
            name="dnaActions"
            render={(arrayHelpers) => (
              <>
                {formik.values.dnaActions.map(
                  (dnaAction: string, index: number) => (
                    <Action key={index}>
                      <StyledActionTextArea
                        id={`dnaAction_${index}`}
                        name={`dnaActions.${index}`}
                        value={dnaAction}
                        onChange={formik.handleChange}
                        maxRows={4}
                      />
                      <DeleteIcon onClick={() => arrayHelpers.remove(index)} />
                    </Action>
                  )
                )}
                <Button color="primary" onClick={() => arrayHelpers.push("")}>
                  Add DNA Action
                </Button>
              </>
            )}
          />
        </StyledFormGroup>
      )}
    </TabPanel>
  );
};

export default ContentPanel;
