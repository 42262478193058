import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";

import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import {
  deleteProduct,
  listProducts
} from "../../clients/services/sams/merlin";
import { ProductListResponse } from "../../clients/services/sams/merlin/types";
import { PostType, Status } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import TableComponent from "../TableComponent";
import { dateSortComparator } from "../utils/dateSortComparator";

const ProductTable = () => {
  const { isLoading, data, error, refetch } = useQuery(["listProducts"], () =>
    listProducts()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.PRODUCT)
  );

  const rows: any = data?.map((product: ProductListResponse) => {
    return {
      id: product.id,
      title: product.title,
      user: findLatestHistory(historyData, product.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, product.id)
        ? dateTimeFull(findLatestHistory(historyData, product.id)?.createdDate)
        : "Created from service",
      status: product.published ? Status.PUBLISHED : Status.DRAFT
    };
  });

  const tableProperties = {
    itemName: "product",
    actionLabel: "Product"
  };

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", minWidth: 170, flex: 1 },
    { field: "user", headerName: "Author", minWidth: 130, flex: 1 },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    }
  ];

  return (
    <TableComponent<ProductListResponse>
      isLoading={isLoading || isLoadingHistory}
      error={error}
      refetch={refetch}
      rows={rows}
      columns={columns}
      testId="ProductTableTestID"
      createButton={{
        link: "/attraction-products",
        text: "Create Product"
      }}
      tableProperties={tableProperties}
      deleteFunction={deleteProduct}
    />
  );
};

export default ProductTable;
