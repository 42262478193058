import moment from "moment";
import * as yup from "yup";

import { Status } from "../../clients/services/sams/types";

const validationSchema = yup.object({
  objectId: yup.string().required("Card is required"),
  objectType: yup.string(),
  slot: yup.number().required("Slot is required"),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date can't be before start date")
    .required("End Date is required")
    .test({
      name: "same",
      exclusive: false,
      params: {},
      message: "End date must be grater than start date",
      test(value) {
        const startDate = moment(this.parent.startDate).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        );
        const endDate = moment(value).format("YYYY-MM-DDTHH:mm:ssZ");
        return moment(startDate).isBefore(moment(endDate));
      }
    }),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Publication status is required")
});

export default validationSchema;
