import styled from "@emotion/styled";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { FC } from "react";

import { RaffleConfig } from "../../../clients/services/sams/content/raffleConfig/types";
import { raffleConfig } from "../../../constants/tooltipMessages";
import { ErrorMessage } from "../../ErrorMessage";
import RichContentEditor from "../../RichContentEditor";

const Container = styled.div`
  padding-top: 15px;
`;

const StatusContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 85px;
`;

interface FormProps {
  values: RaffleConfig;
  validationSchema: any;
  onSubmit: (values: RaffleConfig) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form: FC<FormProps> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId
}) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  return (
    <Container data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <FormikProvider value={formik}>
        <Grid container>
          <Grid container item xs={8}>
            <form id={formId} onSubmit={formik.handleSubmit}>
              {formik.submitCount !== 0 && (
                <ErrorMessage formValidationErrors={formik.errors} />
              )}
              <Grid container>
                <RichContentEditor
                  id="howDoesItWork"
                  label="How Does It Work"
                  value={formik.values.howDoesItWork}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.howDoesItWork &&
                    Boolean(formik.errors.howDoesItWork)
                  }
                  helperText={
                    formik.touched.howDoesItWork && formik.errors.howDoesItWork
                  }
                />
                <RichContentEditor
                  id="howToRedeem"
                  label="How To Redeem"
                  toolTipMessage={raffleConfig.howToRedeem}
                  value={formik.values.howToRedeem}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.howToRedeem &&
                    Boolean(formik.errors.howToRedeem)
                  }
                  helperText={
                    formik.touched.howToRedeem && formik.errors.howToRedeem
                  }
                />
                <RichContentEditor
                  id="tandcs"
                  label="Terms and Conditions"
                  value={formik.values.tandcs}
                  onChange={formik.setFieldValue}
                  error={formik.touched.tandcs && Boolean(formik.errors.tandcs)}
                  helperText={formik.touched.tandcs && formik.errors.tandcs}
                />
              </Grid>
            </form>
          </Grid>
          <Grid container item xs={4}>
            <StatusContainer>
              <StickyWrapper>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={formik.isSubmitting}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress
                        color="primary"
                        size={24}
                        data-testid={"submitting"}
                      />
                    ) : null
                  }
                  onClick={() => formik.handleSubmit()}
                  data-testid={actionTestId}
                >
                  {!formik.isSubmitting ? actionLabel : ""}
                </Button>
              </StickyWrapper>
            </StatusContainer>
          </Grid>
        </Grid>
      </FormikProvider>
    </Container>
  );
};

export default Form;
