import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: "#eeeeee";
  border-style: dashed;
  background-color: "#bdbdbd";
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const SelectedFileContainer = styled.div`
  margin: 10px 0;
  padding: 0 10px;
  border-width: 2px;
  border-radius: 2px;
  border: 2px solid #eaeaea;
  background: #f5f5f5;
  color: "#bdbdbd";
  transition: border 0.24s ease-in-out;
`;

const RejectedFileErrorContainer = styled.div`
  color: #d32f2f;
  margin: 10px 0;
  font-weight: 700;
`;

interface BookingCodesUploadProps {
  id: string;
  bookingCodesCsvFile: File | null;
  setBookingCodesCsvFile: (file: File) => void;
}

const BookingCodesUpload = ({
  id,
  bookingCodesCsvFile,
  setBookingCodesCsvFile
}: BookingCodesUploadProps) => {
  const [rejectedFileError, setRejectedFileError] = useState<string | null>(
    null
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".csv, application/vnd.ms-excel, text/csv",
    maxFiles: 1,
    noClick: true,
    onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const acceptedFile = acceptedFiles[0];
      const rejectedFile = rejectedFiles[0];

      if (rejectedFile) {
        setRejectedFileError(rejectedFile.errors[0].message);
      } else {
        setRejectedFileError(null);
      }

      setBookingCodesCsvFile(acceptedFile);
    }
  });

  return (
    <DropZone {...getRootProps()} data-testid={id}>
      <input id={id} {...getInputProps()} />
      <p>
        Drag 'n' drop the CSV file with the booking codes you would like to
        upload or click to select a file.
      </p>
      {bookingCodesCsvFile && (
        <SelectedFileContainer>
          <p>{bookingCodesCsvFile.name}</p>
        </SelectedFileContainer>
      )}
      {rejectedFileError && (
        <RejectedFileErrorContainer>
          {rejectedFileError}
        </RejectedFileErrorContainer>
      )}
      <Button
        variant="contained"
        onClick={open}
        data-testid={`select-csv-file-${id}-button`}
      >
        Select a {bookingCodesCsvFile && "different"} file
      </Button>
    </DropZone>
  );
};

export default BookingCodesUpload;
