import styled from "@emotion/styled";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import {
  getBookingCodeSummaries,
  deleteBookingCodes
} from "../../clients/services/sams/promotion";
import { BookingCodeSummary } from "../../clients/services/sams/promotion/types";
import { Status } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { setNotification } from "../../state/reducers/notification";
import ConfirmationDialog from "../ConfirmationDialog";
import { Rows } from "../TableComponent/types";

interface Props {
  promotionCode: string;
  refetchPromotions: () => void;
}

interface BookingCodesGridProps {
  rows: Rows;
  columns: GridColDef[];
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 450px;
`;

const StyledDataGrid = styled(DataGrid)`
  border: none;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 6%);
`;

const StyledCell = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  min-width: auto;
  span {
    margin: 0 auto;
  }
  border-radius: 50%;
  &:hover {
    background-color: #c4d9f5;
  }
`;

const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
`;

const BookingCodesGrid = ({ rows, columns }: BookingCodesGridProps) => {
  return (
    <StyledDataGrid
      hideFooterSelectedRowCount={true}
      initialState={
        {
          sorting: {
            sortModel: [
              {
                field: "dateUploaded",
                sort: "asc"
              }
            ]
          },
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        } || undefined
      }
      autoHeight={true}
      rows={rows as Rows}
      columns={columns}
    />
  );
};

const DeleteBookingCodesTable = ({
  promotionCode,
  refetchPromotions
}: Props) => {
  const [currentIdBeingDeleted, setCurrentIdBeingDeleted] = useState<
    number | null
  >(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const dispatch = useDispatch();
  const { isLoading, data, error, refetch } = useQuery(
    ["getBookingCodeSummaries"],
    () => getBookingCodeSummaries(promotionCode)
  );

  const rows: Rows | undefined = error
    ? []
    : data?.bookingCodeSummaries?.map(
        (offer: BookingCodeSummary, index: number) => {
          const dateUploaded = dateTimeFull(offer.uploadDate);
          const dateUploadedWithoutTime = dateUploaded.substring(
            0,
            dateUploaded.length - 9
          );
          return {
            id: index,
            // Parsed date to show in UI
            dateUploaded: dateUploadedWithoutTime,
            // Original date to use for delete
            uploadDate: offer.uploadDate,
            codeCount: offer.totalCodeCount,
            codesRemaining: offer.availableCodeCount,
            status: Status.PUBLISHED
          };
        }
      );

  const handleSelectRow = (id: number) => {
    if (!rows) {
      return;
    }

    const row = rows[id];

    setSelectedRow(row);
  };

  const handleDeleteBookingCodes = async (id: number, uploadDate: string) => {
    setCurrentIdBeingDeleted(id);

    const response = await deleteBookingCodes(promotionCode, uploadDate);

    if (response) {
      const { deletedBookingCodesCount } = response;
      await refetch();
      await refetchPromotions();
      dispatch(
        setNotification({
          message: `${deletedBookingCodesCount} booking ${
            deletedBookingCodesCount > 1 ? "codes" : "code"
          } deleted.`,
          severity: "success"
        })
      );
    }

    setCurrentIdBeingDeleted(null);
  };

  const getColumns = (showDeleteButton: boolean): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: "codeCount",
        headerName: "Code count",
        minWidth: 90,
        flex: 1,
        align: "center"
      },
      {
        field: "codesRemaining",
        headerName: "Codes remaining",
        minWidth: 130,
        flex: 1,
        align: "center"
      },
      {
        field: "dateUploaded",
        headerName: "Date uploaded",
        minWidth: 150,
        flex: 1
      }
    ];
    const deleteButtonColumn = {
      field: "Delete",
      headerName: "",
      width: 10,
      renderCell: (params: GridRenderCellParams) => {
        const { codesRemaining, id, uploadDate } = params.row;
        return (
          <StyledCell>
            <Tooltip title="Delete booking codes">
              {currentIdBeingDeleted === id ? (
                <LoadingIndicatorWrapper>
                  <CircularProgress size={24} />
                </LoadingIndicatorWrapper>
              ) : (
                <ConfirmationDialog
                  title={`Are you sure you want to delete ${codesRemaining} booking codes?`}
                  extraContent={
                    <Wrapper>
                      <BookingCodesGrid
                        rows={[selectedRow]}
                        columns={getColumns(false)}
                      />
                    </Wrapper>
                  }
                  actionLabel={"Yes, delete"}
                  action={() => handleDeleteBookingCodes(id, uploadDate)}
                  testIdPrefix={`delete-method-${id}`}
                >
                  {(triggerAction) => (
                    <Tooltip title="Delete booking codes">
                      <StyledButton
                        startIcon={<DeleteIcon />}
                        size="medium"
                        variant="text"
                        onClick={() => {
                          handleSelectRow(id);
                          triggerAction();
                        }}
                        data-testid={`delete-method-${id}-trigger`}
                        disabled={codesRemaining === 0}
                      ></StyledButton>
                    </Tooltip>
                  )}
                </ConfirmationDialog>
              )}
            </Tooltip>
          </StyledCell>
        );
      }
    };

    if (!showDeleteButton) {
      return columns;
    }

    return [...columns, deleteButtonColumn];
  };

  return (
    <Wrapper>
      {isLoading ? (
        <CircularProgress data-testid="loading-indicator" />
      ) : (
        <BookingCodesGrid rows={rows ?? []} columns={getColumns(true)} />
      )}
    </Wrapper>
  );
};

export default DeleteBookingCodesTable;
