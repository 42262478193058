import moment from "moment";

import { Partnership } from "../../clients/services/sams/content/partnership/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";

import { FormDataInterface } from "./types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: FormDataInterface) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (formData: FormDataInterface) => {
    return {
      ...formData,
      startDate:
        formData.liveDate !== "" && typeof formData.liveDate === "string"
          ? new Date(formData.liveDate)
          : "",
      endDate:
        formData.endDate !== "" && typeof formData.endDate === "string"
          ? new Date(formData.endDate)
          : ""
    };
  };

  public modelToFormData = (modelData: Partnership) => {
    return {
      ...modelData,
      liveDate: modelData.liveDate
        ? moment(modelData.liveDate).format("YYYY-MM-DDTHH:mm")
        : "",
      endDate: modelData.endDate
        ? moment(modelData.endDate).format("YYYY-MM-DDTHH:mm")
        : "",
      isUpdateOperation: true
    };
  };
}

export default Transformer;
