import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const DataContainer = styled.div`
  margin: 32px auto;
  margin-right: 32px;
`;
export const ButtonLink = styled(Link)`
  text-decoration: none;
`;
export const CreateButton = styled(ButtonLink)`
  button {
    margin-bottom: 24px;
  }
`;
