import { RaffleWinner } from "../../clients/services/sams/content/raffleWinners/types";
import { Status } from "../../clients/services/sams/types";

const modelTemplate: RaffleWinner = {
  title: "",
  description: "",
  mainImage: "",
  status: Status.DRAFT,
  thumbnailImage: "",
  ticketNumber: "",
  raffleDate: new Date()
};

export default modelTemplate;
